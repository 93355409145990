<template>
  <div>
    <div
      class="info-popup bg-white px-4 py-4 rounded-10 fixed top-4 right-4 flex gap-4 items-start justify-between z-40"
    >
      <div>
        <img v-if="popupInfoStatus === 'info'" width="24" height="24" src="@/assets/images/ui/infopopup/info.svg" />
        <img
          v-else-if="popupInfoStatus === 'warn'"
          width="24"
          height="24"
          src="@/assets/images/ui/infopopup/warn.svg"
        />
        <img
          v-else-if="popupInfoStatus === 'error'"
          width="24"
          height="24"
          src="@/assets/images/ui/infopopup/error.svg"
        />
      </div>
      <div class="info-popup__text">
        <p class="text-gray font-medium">
          <span v-if="popupInfoStatus === 'info'">Информация</span>
          <span v-if="popupInfoStatus === 'warn'">Внимание!</span>
          <span v-if="popupInfoStatus === 'error'">Ошибка!</span>
        </p>
        <p class="mt-1 text-gray-6 text-sm leading-5" v-html="popupInfo"></p>
      </div>
      <div @click="closePopup" class="cursor-pointer">
        <img width="16" height="16" src="@/assets/images/ui/infopopup/cross.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoPopup',

  data() {
    return {
      closeTimeout: null,
    };
  },

  computed: {
    popupDisplay() {
      return this.$store.state.system.popupDisplay;
    },

    popupInfo() {
      return this.$store.state.system.popupInfo;
    },

    popupInfoStatus() {
      return this.$store.state.system.popupInfoStatus;
    },
  },

  watch: {
    popupDisplay() {
      if (this.closeTimeout) clearTimeout(this.closeTimeout);
      this.closeTimeout = setTimeout(() => {
        this.$store.dispatch('setPopupDisplay', { display: false });
      }, 10000);
    },
  },

  methods: {
    closePopup() {
      clearTimeout(this.closeTimeout);
      this.$store.dispatch('setPopupDisplay', { display: false });
    },
  },
};
</script>

<style lang="scss">
.info-popup {
  min-width: 384px;
  min-height: 86px;
  box-shadow: 0px 2px 8px 0px #00000026;

  &__text {
    width: 272px;
  }
}
</style>
