<template>
  <div class="flex-1 bg-gray-4">
    <div class="bg-gray-4 pb-10 h-full main-container">
      <div class="flex justify-between items-center pt-8">
        <div class="flex gap-6 items-center">
          <p style="font-size: 38px" class="leading-12 font-semibold">Рекламных кампаний: {{ campaignsTotal }}</p>
          <UiDropdown
            v-if="false"
            v-model="currentVariant"
            dropdownHeight="sm"
            :variantsList="variants"
            class="px-4 w-28"
          />
        </div>
        <router-link v-if="isAdvertiser" to="/create-campaign">
          <UiButton class="px-4">Создать рекламную кампанию</UiButton>
        </router-link>
      </div>
      <div class="overflow-hidden relative">
        <transition name="campaigns-plug">
          <div
            v-show="loading"
            style="background: rgba(80, 80, 80, 0.26); z-index: 5; top: 78px"
            class="absolute left-0 w-full bottom-4"
          >
            <div class="w-full h-full flex justify-center items-center">
              <LoadingSpinner />
            </div>
          </div>
        </transition>

        <div class="border-b border-gray-2 flex items-center justify-between">
          <div class="mt-4 flex">
            <div
              v-for="category in isAdvertiser ? adCategories : operatorCategories"
              :key="category.key"
              style="height: 44px"
              class="flex px-4 justify-center items-center text-sm leading-5 text-gray relative cursor-pointer"
              @click="handleChangeCategory(category)"
            >
              <p>{{ category.title }}</p>
              <div
                v-show="category.key === currentCategory.key"
                style="bottom: -1px"
                class="absolute left-0 w-full h-0.5 bg-blue"
              ></div>
            </div>
          </div>
          <UiInput v-model="searchValue" placeholder="Поиск по РК" inputHeight="sm" class="w-64">
            <template v-slot:left>
              <img src="@/assets/images/views/advertising-campaigns/search-icon.svg" />
            </template>
          </UiInput>
        </div>
        <div
          v-if="filteredCampaigns.length === 0"
          class="campaigns__empty bg-white mt-4 flex items-center justify-center flex-col"
        >
          <img
            width="184"
            height="117"
            src="@/assets/images/views/advertising-campaigns/no-campaigns.svg"
            class="mx-auto"
          />
          <p class="mt-5 text-2xl leading-8 text-gray-6">Здесь пока ничего нет.</p>
        </div>
        <div
          v-else
          ref="gridContainer"
          class="overflow-x-auto overflow-y-hidden campaigns__container bg-white mx-auto relative mt-4 relative"
        >
          <CampaignsGrid
            :filtered-campaigns="filteredCampaigns"
            :currentSort="currentSort"
            :current-category="gridCurrentCategory"
            @filter-change="handleFilterChange"
            @update-campaigns="getCampaigns"
          />
        </div>
      </div>
      <UiPagination :pagesAmount="pagesAmount" :currentPage="currentPage" class="mt-4" @changePage="handleChangePage" />
    </div>
  </div>
</template>

<script>
import CampaignsGrid from '@/components/views/AdvertisingCampaigns/CampaignsGrid.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import UiButton from '@/components/ui/UiButton.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiPagination from '@/components/ui/UiPagination';

export default {
  name: 'AdvertisingCampaigns',

  components: { CampaignsGrid, UiButton, UiInput, UiDropdown, UiPagination, LoadingSpinner },

  data() {
    return {
      users: [],

      currentCategory: {
        key: null,
        title: 'Все кампании',
      },

      gridCurrentCategory: {
        key: null,
        title: 'Все кампании',
      },

      currentVariant: { value: 'asd', title: 'Записи(6)' },

      variants: [
        {
          value: '2',
          title: 'Записи(2)',
        },
        {
          value: '4',
          title: 'Записи(4)',
        },
        {
          value: '6',
          title: 'Записи(6)',
        },
      ],

      currentPage: 1,

      searchValue: '',
      prevSearchValue: '',

      campaignsPerPage: 15,

      currentSort: 'none',

      currentOrder: 'none',

      searchTimeout: null,

      adCategories: [
        {
          key: null,
          title: 'Все кампании',
          value: '',
          contextual_value: '',
        },
        {
          key: 'active',
          title: 'Текущие',
          value: 'running',
          contextual_value: '',
        },
        {
          key: 'pending',
          title: 'Будущие',
          value: 'pending',
          contextual_value: '',
        },
        {
          key: 'finished',
          title: 'Завершенные',
          value: 'finished',
          contextual_value: '',
        },
        {
          key: 'deleted',
          title: 'Архив',
          value: 'deleted',
          contextual_value: '',
        },
      ],

      operatorCategories: [
        {
          key: null,
          title: 'Все кампании',
        },
        {
          key: 'pending',
          contextual_value: 'pending',
          title: 'Новые',
        },
        {
          key: 'running',
          contextual_value: 'running',
          title: 'Активные',
        },
        {
          key: 'partial_running',
          contextual_value: 'partial_running',
          title: 'Частично активные',
        },
        {
          key: 'declined',
          contextual_value: 'declined',
          title: 'Отклоненные',
        },
        {
          key: 'paused',
          contextual_value: 'paused',
          title: 'На паузе',
        },
        {
          key: 'stopped',
          contextual_value: 'stopped',
          title: 'Остановленные',
        },
      ],
    };
  },

  computed: {
    pagesAmount() {
      if (!this.$store.state.auth.campaignsInfo || this.$store.state.auth.campaignsInfo.total === 0) return 1;
      return Math.ceil(this.$store.state.auth.campaignsInfo.total / this.campaignsPerPage);
    },

    filteredCampaigns() {
      if (!this.$store.state.auth.campaigns) return [];
      let campaigns = [...this.$store.state.auth.campaigns];
      this.sortCampaigns(campaigns);
      return campaigns;
    },

    userInfo() {
      return this.$store.state.auth.userInfo;
    },

    campaignsTotal() {
      if (!this.$store.state.auth.campaignsInfo) return 0;
      return this.$store.state.auth.campaignsInfo.total;
    },

    isAdvertiser() {
      return this.$store.state.auth.organizationMode === 'advertiser';
    },

    loading() {
      return this.$store.state.auth.campaignsLoading;
    },
  },

  watch: {
    currentSort() {
      this.currentPage = 1;
    },

    'currentCategory.key'() {
      this.currentSort = 'none';
    },

    searchValue() {
      this.currentPage = 1;
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        if (this.prevSearchValue === this.searchValue) return;
        this.prevSearchValue = this.searchValue;
        this.getCampaigns();
      }, 650);
    },

    filteredCampaigns() {
      if (this.$route.name !== 'advertising-campaigns') {
        this.currentCategory = {
          key: null,
          title: 'Все кампании',
        };
        this.gridCurrentCategory = { ...this.currentCategory };
        this.searchValue = '';
        this.currentPage = 1;
      }
    },
  },

  methods: {
    async handleChangeCategory(category) {
      if (this.loading || this.currentCategory.key === category.key) return;
      this.currentCategory = category;
      this.searchValue = '';
      this.currentPage = 1;
      await this.getCampaigns();
      this.gridCurrentCategory = category;
    },

    sortCampaigns(campaigns) {
      if (this.currentSort === 'start' && this.currentOrder === 'decrease')
        campaigns.sort((a, b) => new Date(b.start) - new Date(a.start));
      if (this.currentSort === 'start' && this.currentOrder === 'increase')
        campaigns.sort((a, b) => new Date(a.start) - new Date(b.start));

      if (this.currentSort === 'name' && this.currentOrder === 'decrease')
        campaigns.sort((a, b) => a.name.localeCompare(b.name));
      if (this.currentSort === 'name' && this.currentOrder === 'increase')
        campaigns.sort((a, b) => b.name.localeCompare(a.name));

      if (this.currentSort === 'ots' && this.currentOrder === 'decrease') campaigns.sort((a, b) => b.ots - a.ots);
      if (this.currentSort === 'ots' && this.currentOrder === 'increase') campaigns.sort((a, b) => a.ots - b.ots);

      if (this.currentSort === 'players' && this.currentOrder === 'decrease')
        campaigns.sort((a, b) => b.whitelist_player_ids.length - a.whitelist_player_ids.length);
      if (this.currentSort === 'players' && this.currentOrder === 'increase')
        campaigns.sort((a, b) => a.players_qty - b.players_qty);

      if (this.currentSort === 'views' && this.currentOrder === 'decrease')
        campaigns.sort((a, b) => b.analytics.views - a.analytics.views);
      if (this.currentSort === 'views' && this.currentOrder === 'increase')
        campaigns.sort((a, b) => a.analytics.views - b.analytics.views);

      if (this.currentSort === 'budget' && this.currentOrder === 'decrease')
        campaigns.sort((a, b) => b.budget - a.budget);
      if (this.currentSort === 'budget' && this.currentOrder === 'increase')
        campaigns.sort((a, b) => a.budget - b.budget);
    },

    handleFilterChange(value) {
      this.currentSort = value.sort ? value.sort : 'none';
      this.currentOrder = value.order ? value.order : 'none';
    },

    async getCampaigns() {
      const params = this.createGetCampaignsParams();
      await this.$store.dispatch('getCampaigns', params);
    },

    createGetCampaignsParams() {
      const params = new URLSearchParams();
      params.append('limit', this.campaignsPerPage);
      params.append('offset', this.campaignsPerPage * (this.currentPage - 1));
      if (this.searchValue) params.append('name', this.searchValue);

      if (this.currentCategory.value)
        if (Array.isArray(this.currentCategory.value))
          this.currentCategory.value.forEach((item) => {
            params.append('state', item);
          });
        else params.append('state', this.currentCategory.value);

      if (this.currentCategory.contextual_value)
        if (Array.isArray(this.currentCategory.contextual_value))
          this.currentCategory.contextual_value.forEach((item) => {
            params.append('state', item);
          });
        else params.append('contextual_state', this.currentCategory.contextual_value);

      return params;
    },

    handleChangePage(value) {
      if (this.loading) return;
      this.currentPage = value;
      this.getCampaigns();
    },
  },
};
</script>

<style lang="scss">
.campaigns {
  &__image-wrapper {
    height: 178px;
  }
  &__container {
    scrollbar-width: thin;
    scrollbar-color: #d9d9d9 #fff;

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
  }
  &__navbar {
    box-shadow: 0px 2px 8px 0px #00000026;
    position: sticky;
    right: 0;
    top: 0;
  }

  &__empty {
    height: 745px;
  }
}

.campaigns-plug {
  &-leave-active,
  &-enter-active {
    transition: all 0.5s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
