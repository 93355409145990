import axios from 'axios';
import axiosRetry from 'axios-retry';

const httpClient = axios.create();

if (process.env.NODE_ENV === 'development') httpClient.defaults.baseURL = process.env.VUE_APP_API_URL;

// const httpContentClient = axios.create({
//   baseURL: process.env.VUE_APP_API_URL,
// });
//
// httpContentClient.defaults.headers.common['Content-Type'] = 'multipart/form-data';
//
// httpContentClient.defaults.headers.common['Content-Length'] = '500000';

const httpVenueClient = axios.create({
  baseURL: process.env.VUE_APP_VENUE_URL,
});

const httpCoordsClient = axios.create({
  baseURL: process.env.VUE_APP_COORDS_URL,
});

axiosRetry(httpCoordsClient, { retries: 3 });

function login(data) {
  return httpClient.post('/v1/authenticate', {
    email: data.email,
    password: data.password,
  });
}

function recoverPassword(data) {
  return httpClient.post('/v1/password/restore', {
    email: data.email,
  });
}

function getUserInfo() {
  return httpClient.get('v1/me');
}

function getBalance() {
  return httpClient.get('/v1/balance');
}

function getCampaigns(params = { limit: 15 }) {
  return httpClient.get('/v1/od/campaigns', { params });
}

function getCampaignInfo(data) {
  return httpClient.get(`/v1/od/campaigns/${data.id}`);
}

function getCampaignAnalytics(data) {
  return httpClient.get(`/v1/od/campaigns/${data.id}/analytics`);
}

function getPlayers(reqParams = {}) {
  const params = new URLSearchParams();
  params.append('limit', reqParams.limit);
  params.append('bbox', reqParams.bbox);

  reqParams.venue_category.forEach((category) => {
    params.append('venue_category', category);
  });

  reqParams.venue_category_outdoor.forEach((category) => {
    params.append('venue_category_outdoor', category);
  });

  reqParams.dooh_type.forEach((type) => {
    params.append('dooh_type', type);
  });

  return httpClient.get('/v1/dmp/players/search/', { params });
}

function getVenueCategories() {
  return httpVenueClient.get('/v1/dmp/references/venue_categories/');
}

function getVenueCategoriesOutdoor() {
  return httpVenueClient.get('/v1/dmp/references/venue_categories_outdoor');
}

function getPlayerDimensions(data) {
  return httpClient.post('/v1/dmp/players/resolutions', data);
}

function createContentFile(file) {
  const data = new FormData();
  data.append('file', file);
  return httpClient.post('/v1/content/files', data);
}

function createContentItem(data) {
  return httpClient.post('/v1/content/items', {
    mechanism: 'six',
    type: 'video',
    name: data.name,
    description: data.description,
    tags: [],
    content_file_id: data.contentFiles,
  });
}

function getContentItem(itemId) {
  return httpClient.get(`/v1/content/items/${itemId}`);
}

function addFileToContentItem(itemId, fileId) {
  return httpClient.post(`/v1/content/items/${itemId}/files`, {
    content_file_id: fileId,
  });
}

function replaceFileInContentItem(itemId, fileId) {
  return httpClient.post(`/v1/content/items/${itemId}/files`, {
    content_file_id: fileId,
    replace: true,
  });
}

function deleteFileFromContentItem(fileId) {
  return httpClient.delete(`/v1/content/items/_/files/${fileId}`);
}

function createCampaign(form) {
  return httpClient.post('/v1/od/campaigns', { ...form });
}

function loadMediaPlan(form) {
  return httpClient.post('/v1/od/campaigns/xlsx', { ...form });
}

function updateCampaign(id, form) {
  return httpClient.put(`/v1/od/campaigns/${id}`, { ...form });
}

function getOrganization() {
  return httpClient.get('v1/organizations/4');
}

function getUsers(params = { limit: 10, offset: 0 }) {
  return httpClient.get('/v1/users', { params });
}

function createUser(data) {
  return httpClient.post('/v1/users', data);
}

function updatePassword(data) {
  return httpClient.post('/v1/password/set', data);
}

function updateProfile(data) {
  return httpClient.put('/v1/me', data);
}

function updatePasswordOne() {
  return httpClient.post('/v1/password/set', {
    password: '12121212',
    token: '3sSXsVkt3JqVztnTuex0YlxqKFEO06VwNEAKhUCjLDhCRgCHzJZViv912f1hkFvm',
  });
}

function getOrganizations() {
  return httpClient.get('/v1/organizations/');
}

function moderateContent(id, data) {
  return httpClient.post(`/v1/od/campaigns/${id}/moderation`, data);
}

function delegateCampaign(id, data) {
  return httpClient.patch(`/v1/od/campaigns/${id}`, data);
}

function repeatInvite(id) {
  return httpClient.post(`v1/users/${id}/send_welcome_email`);
}

function blockUser(id) {
  return httpClient.patch(`/v1/users/${id}`, { active: false });
}

function updateUserStatus(id, data) {
  return httpClient.patch(`/v1/users/${id}`, data);
}

function deleteUser(id) {
  return httpClient.delete(`/v1/users/${id}`);
}

function archiveCampaign(id) {
  return httpClient.delete(`/v1/od/campaigns/${id}`);
}

function rerunCampaign(id) {
  return httpClient.patch(`/v1/od/campaigns/${id}`, { paused: false });
}

function pauseCampaign(id) {
  return httpClient.patch(`/v1/od/campaigns/${id}`, { paused: true });
}

function stopCampaign(id) {
  return httpClient.patch(`/v1/od/campaigns/${id}`, { terminated: true });
}

function signUp(data) {
  return httpClient.post('/v1/organizations', data);
}

function changeOrganizationInfo(id, data) {
  return httpClient.patch(`/v1/organizations/${id}`, { profile: data });
}

function getModerationHistory(id) {
  return httpClient.get(`/v1/od/campaigns/${id}/moderation/history`);
}

function createInvoice(data) {
  return httpClient.post('/v1/billing/payments', data);
}

function getPaymentsHistory(reqParams) {
  const params = new URLSearchParams();
  params.append('offset', reqParams.offset);
  reqParams.statuses.forEach((status) => {
    params.append('class', status);
  });
  return httpClient.get('/v1/billing/payments', { params });
}

function getCityCoords(params) {
  return httpCoordsClient.get('/search', { params });
}

function getExcelReport(id) {
  return httpClient({ url: `/v1/od/campaigns/${id}/xlsx`, method: 'GET', responseType: 'blob' });
}

export {
  httpClient,
  login,
  getUserInfo,
  getBalance,
  getCampaigns,
  recoverPassword,
  getCampaignInfo,
  getCampaignAnalytics,
  getPlayers,
  getVenueCategories,
  getVenueCategoriesOutdoor,
  createContentFile,
  createContentItem,
  getContentItem,
  getPlayerDimensions,
  addFileToContentItem,
  replaceFileInContentItem,
  deleteFileFromContentItem,
  createCampaign,
  loadMediaPlan,
  updateCampaign,
  getOrganization,
  getUsers,
  createUser,
  updatePassword,
  updateProfile,
  getOrganizations,
  updatePasswordOne,
  moderateContent,
  delegateCampaign,
  repeatInvite,
  blockUser,
  updateUserStatus,
  deleteUser,
  archiveCampaign,
  rerunCampaign,
  pauseCampaign,
  stopCampaign,
  signUp,
  changeOrganizationInfo,
  getModerationHistory,
  createInvoice,
  getPaymentsHistory,
  getCityCoords,
  getExcelReport,
};
