<template>
  <div class="flex-1 pb-24">
    <div class="mt-3 main-container">
      <div class="bg-white px-6 pt-4 pb-3 grid grid-cols-4 gap-6">
        <div>
          <div class="flex gap-6 items-center">
            <p class="text-sm leading-5 text-gray">Период</p>
            <DatePicker
              v-model="timeRange"
              format="DD.MM.YY"
              valueType="YYYY-MM-DD"
              :disabled-date="disableDate"
              range
              class="flex-1 cursor-pointer"
              :class="{ 'datepicker-error': dateError }"
              @pick="dateError = false"
            />
          </div>
          <UiButton :color-type="timeError ? 'danger' : 'outline'" class="mt-4" @click="openTimeTarget">
            Добавить временной таргет
          </UiButton>
        </div>
        <div>
          <div class="flex gap-3 items-center">
            <p class="text-sm leading-5 text-gray-6 shrink-0 no-wrap">Общий бюджет РК, руб.</p>
            <UiInput :maxLength="7" v-model="budget" style="width: 120px" class="flex-1" bold right number />
          </div>
          <UiRange v-model="budget" max="1000000" min="0" step="1000" class="mt-4" />
          <div class="mt-3 flex items-center gap-3">
            <UiCheckbox v-model="spender" />
            <p class="text-gray text-sm leading-5">Тратить максимально быстро</p>
          </div>
        </div>
        <div>
          <div class="flex gap-6 items-center">
            <p class="text-sm leading-5 text-gray-6 shrink-0 no-wrap">Бюджет, руб./день</p>
            <UiInput :maxLength="6" v-model="cpm" style="width: 120px" class="flex-1" bold right number />
          </div>
          <UiRange v-model="cpm" max="1000000" min="0" step="100" class="mt-4" />
        </div>
        <div>
          <div class="flex gap-6 items-center">
            <p class="text-sm leading-5 text-gray-6 shrink-0 no-wrap">
              Потенциальный охват<br />
              аудитории
            </p>
            <UiInput :maxLength="8" v-model="ots" style="width: 120px" class="flex-1" bold right number />
          </div>
          <UiRange v-model="ots" max="10000000" min="0" step="10000" class="mt-4" />
        </div>
      </div>
    </div>
    <PlayerMap ref="playerMap" :edit-form="editForm" @update-value="$emit('update-value', $event)" />
    <div v-show="isTimeTargetVisible">
      <TimeTarget ref="timeTarget" :edit-form="editForm" @close="closeTimeTarget" />
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import TimeTarget from '@/components/views/CreateCampaign/TimeTarget';
import DatePicker from '@/datepicker';
import UiRange from '@/components/ui/UiRange';
import UiButton from '@/components/ui/UiButton';
import UiInput from '@/components/ui/UiInput';
import UiCheckbox from '@/components/ui/UiCheckbox';
import PlayerMap from '@/components/views/CreateCampaign/PlayerMap';

export default {
  name: 'StepOne',

  components: {
    UiRange,
    UiButton,
    DatePicker,
    UiInput,
    UiCheckbox,
    TimeTarget,
    PlayerMap,
  },

  props: {
    editForm: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      timeRange: [],

      isTimeTargetVisible: false,

      budget: '0',
      cpm: '0',
      ots: '0',
      spender: false,

      dateError: false,
      timeError: false,

      latestDate: '',
      latestDateValue: '',
    };
  },

  watch: {
    timeRange() {
      this.dateError = false;
      this.$emit('update-value', { start: this.timeRange[0], end: this.timeRange[1] });
    },

    budget() {
      this.$emit('update-value', { budget: Number(this.budget) });
    },

    cpm() {
      this.$emit('update-value', { cpm: Number(this.cpm) });
    },

    ots() {
      this.$emit('update-value', { ots: Number(this.ots) });
    },

    spender() {
      this.$emit('update-value', { spender: this.spender });
    },

    editForm: {
      deep: true,
      handler() {
        this.handleEditForm();
      },
    },
  },

  mounted() {
    this.setInitialValues();
    this.setLatestDate();
  },

  methods: {
    setLatestDate() {
      const currentDate = new Date();
      this.latestDate = new Date();
      this.latestDate.setDate(this.latestDate.getDate() - 1);
      this.latestDateValue = `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1 < 10 ? '0' + currentDate.getMonth() + 1 : currentDate.getMonth() + 1
      }-${currentDate.getDate() < 10 ? '0' + currentDate.getDate() : currentDate.getDate()}`;
    },

    setInitialValues() {
      this.budget = '500000';
      this.cpm = '500000';
      this.ots = '5000000';
    },

    disableDate(date) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      return +date - currentDate < 0;
    },

    closeTimeTarget(value) {
      enableBodyScroll(this.$refs.timeTarget);
      this.isTimeTargetVisible = false;
      const weekdays_hours = { ...value };
      Object.keys(weekdays_hours).forEach((key) => {
        weekdays_hours[key] = weekdays_hours[key].map((hour) => Number(hour));
      });
      this.timeError = false;
      this.$emit('update-value', { weekdays_hours });
    },

    openTimeTarget() {
      disableBodyScroll(this.$refs.timeTarget);
      this.isTimeTargetVisible = true;
      this.$refs.timeTarget.savePrevState();
    },

    handleEditForm() {
      const isDateLatest = +this.latestDate - new Date(this.editForm.start) < 0;

      this.timeRange = [isDateLatest ? this.editForm.start : this.latestDateValue, this.editForm.end];
      this.ots = String(this.editForm.ots);
      this.cpm = String(this.editForm.cpm);
      this.budget = String(this.editForm.budget);
      this.spender = this.editForm.spender;
    },

    updateContentLength(value) {
      this.$refs.playerMap.updateContentLength(value);
    },

    createDateError() {
      this.dateError = true;
    },

    createNameError() {
      this.$refs.playerMap.createNameError();
    },

    createPlayerError() {
      this.$refs.playerMap.createPlayerError();
    },

    createTimeError() {
      this.timeError = true;
    },
  },
};
</script>

<style lang="scss">
.datepicker-error {
  .mx-input {
    border-color: #f5222d;
    &:focus {
      box-shadow: 0px 0px 4px 0px red;
    }
  }
}
</style>
