<template>
  <div class="bg-white p-6 pb-12">
    <div class="flex items-center justify-between">
      <p style="font-size: 30px" class="leading-10 font-semibold text-gray">Детали</p>
      <div v-if="isAdvertiser" class="flex items-center gap-6">
        <UiButton v-if="excelButtonVisible" colorType="outline" class="px-4 gap-1" @click="downloadExcelReport">
          <img width="16" height="16" src="@/assets/images/views/campaign-card/download.svg" />
          <p class="">Экспорт отчета в Excel</p>
        </UiButton>
        <UiButton v-if="isAdvertiser && !userInfo?.manager" colorType="outline" class="px-4" @click="openDelegateModal">
          <p class="">Делегировать менеджеру</p>
        </UiButton>
      </div>
    </div>
    <div class="mt-4 flex items-start gap-6 relative">
      <div class="flex-1">
        <LoadingText v-if="loading" class="w-32 h-8 rounded-10" />
        <p v-else class="leading-8 text-2xl font-semibold">{{ adInfo.name }}</p>
        <div v-if="adInfo.created_by || loading" class="grid grid-cols-3 mt-4">
          <div class="text-sm leading-5 text-gray-3">Владелец</div>
          <LoadingText v-if="loading" class="w-32 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">
            <p class="">
              {{ adInfo.created_by.profile?.first_name + ' ' + adInfo.created_by.profile?.last_name }}
            </p>
          </div>
        </div>
        <div v-if="isAdvertiser" class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Менеджеры</div>
          <div class="col-span-2">
            <template v-if="loading">
              <LoadingText v-for="n in 2" :key="n" class="w-44 h-5 rounded-4 mb-2" />
            </template>
            <template v-else-if="campaignManagers.length > 0">
              <div v-for="manager in campaignManagers" :key="manager.id" class="grid mb-2 grid-cols-3">
                <div class="text-sm leading-5 text-gray">
                  {{ manager.profile?.first_name + ' ' + manager.profile?.last_name }}
                </div>
                <div class="text-sm leading-5 text-gray-3" :class="{ 'col-span-2': !isAdvertiser }">
                  {{ manager.access === 'full' ? 'Полный доступ' : 'Только чтение' }}
                </div>
                <div
                  v-if="isAdvertiser && !userInfo?.manager"
                  class="text-sm leading-5 text-red-1 cursor-pointer"
                  @click="openDeleteModal(manager)"
                >
                  Удалить
                </div>
              </div>
            </template>
            <div v-else class="text-sm leading-5 text-gray">Данной рекламной кампании не назначены менеджеры</div>
            <div
              v-if="isAdvertiser && !userInfo?.manager && !loading"
              class="text-blue text-sm leading-5 cursor-pointer mt-4"
              @click="openDelegateModal"
            >
              {{ adInfo.managers?.length > 0 ? 'Добавить' : 'Делегировать' }}
            </div>
          </div>
        </div>
        <div class="h-px bg-gray-2 my-4"></div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Название РК</div>
          <LoadingText v-if="loading" class="w-32 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">{{ adInfo.name }}</div>
        </div>
        <div v-if="adInfo.description || loading" class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Описание РК</div>
          <LoadingText v-if="loading" class="w-32 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">{{ adInfo.description }}</div>
        </div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Даты показа</div>
          <LoadingText v-if="loading" class="w-40 h-4 rounded-4" />
          <div v-else class="col-span-2 flex gap-3">
            <p class="text-sm leading-5 text-gray">{{ showingDates }}</p>
            <div class="relative" tabindex="0" @blur="isTimeTargetVisible = false">
              <img
                width="20"
                height="20"
                src="@/assets/images/views/campaign-card/calendar.svg"
                class="cursor-pointer"
                @click="isTimeTargetVisible = !isTimeTargetVisible"
              />
              <UiTimeTarget
                v-if="adInfo.weekdays_hours"
                v-show="isTimeTargetVisible"
                :days-prop="adInfo.weekdays_hours"
                class="absolute top-8 -left-96"
              />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Статус</div>
          <LoadingText v-if="loading" class="w-32 h-4 rounded-4" />
          <div v-else-if="isAdvertiser" class="col-span-2 flex gap-2 items-center text-gray-6 text-sm leading-5">
            <div class="w-2 h-2 rounded-full" :class="defineCircleClasses(this.adInfo)"></div>
            <p>{{ defineStatusText(this.adInfo) }}</p>
          </div>
          <div v-else class="col-span-2 flex gap-2 items-center text-gray-6 text-sm leading-5">
            <div
              class="w-2 h-2 rounded-full"
              :class="{
                'bg-green-2': adInfo.contextual_state === 'running',
                'bg-red-1': adInfo.contextual_state === 'declined',
                'bg-blue': adInfo.contextual_state === 'pending',
                'bg-yellow': adInfo.contextual_state === 'partial_running',
                'bg-gray-5': adInfo.contextual_state === 'stopped',
                'bg-blue-4': adInfo.contextual_state === 'paused',
              }"
            ></div>
            <p v-if="adInfo.contextual_state === 'running'">Активна</p>
            <p v-else-if="adInfo.contextual_state === 'partial_running'">Частично активна</p>
            <p v-else-if="adInfo.contextual_state === 'declined'">Отклонена</p>
            <p v-else-if="adInfo.contextual_state === 'pending'">Новая</p>
            <p v-else-if="adInfo.contextual_state === 'stopped'">Остановлена</p>
            <p v-else-if="adInfo.contextual_state === 'paused'">На паузе</p>
            <p v-else>Активна</p>
          </div>
        </div>
        <div class="h-px bg-gray-2 my-4"></div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Бюджет РК, руб.</div>
          <LoadingText v-if="loading" class="w-24 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">
            {{ adIndicators.cost ? adIndicators.cost : 0 }}/{{ adInfo.budget }}
          </div>
        </div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Количество показов</div>
          <LoadingText v-if="loading" class="w-24 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">
            {{ adIndicators.count ? adIndicators.count : 0 }}
          </div>
        </div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Охват аудитории</div>
          <LoadingText v-if="loading" class="w-28 h-4 rounded-4" />
          <div v-else class="col-span-2 flex gap-3 items-start">
            <p class="text-sm leading-5 text-gray mt-1">
              {{ adIndicators.views ? adIndicators.views : 0 }}/{{ adInfo.ots }}
            </p>
            <div class="flex items-end gap-0.5">
              <div class="w-1 h-2 bg-red-2"></div>
              <div class="w-1 h-3" :class="adIndicators.views / adInfo.ots > 0.3 ? 'bg-orange' : 'bg-gray-7'"></div>
              <div class="w-1 h-4" :class="adIndicators.views / adInfo.ots > 0.6 ? 'bg-green' : 'bg-gray-7'"></div>
              <div class="w-1 h-5" :class="adIndicators.views / adInfo.ots > 0.9 ? 'bg-blue' : 'bg-gray-7'"></div>
            </div>
          </div>
        </div>
        <div class="h-px bg-gray-2 my-4"></div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Кол-во экранов</div>
          <LoadingText v-if="loading" class="w-20 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">{{ adInfo.whitelist_player_ids?.length }}</div>
        </div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Город / Города</div>
          <LoadingText v-if="loading" class="w-24 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">Москва</div>
        </div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Формат размещения</div>
          <LoadingText v-if="loading" class="w-36 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">
            <span v-if="adInfo.indoor">Indoor</span><span v-if="adInfo.indoor && adInfo.outdoor">, </span>
            <span v-if="adInfo.outdoor">Outdoor</span>
          </div>
        </div>
        <!-- <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Indoor</div>
          <div class="col-span-2 text-sm leading-5 text-gray">БЦ, HoReCa, Фитнес клубы</div>
        </div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Outdoor</div>
          <div class="col-span-2 text-sm leading-5 text-gray">Вид рекламы 2, Вид рекламы 3, Вид рекламы 5</div>
        </div> -->
        <div class="h-px bg-gray-2 my-4"></div>
        <div v-if="adInfo.content_length" class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Длительность ролика</div>
          <div class="col-span-2 text-sm leading-5 text-gray">{{ adInfo.content_length }} сек</div>
        </div>
        <div class="grid grid-cols-3 mt-3">
          <div class="text-sm leading-5 text-gray-3">Форматы ролика:</div>
          <LoadingText v-if="loading" class="w-40 h-4 rounded-4" />
          <div v-else class="col-span-2 text-sm leading-5 text-gray">
            <span v-for="(dimension, index) in playersDimensions" :key="dimension.key">
              {{ `${dimension.width} x ${dimension.height} PX${index === playersDimensions.length - 1 ? '' : ','}` }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="isAdvertiser" class="flex-1">
        <template v-if="loading">
          <LoadingText class="w-2/6 h-8 rounded-10" />
          <LoadingText class="w-1/2 h-6 mt-4 rounded-10" />
          <LoadingText class="w-full h-8 mt-6 rounded-10" />
        </template>
        <div v-else-if="adAnalytics?.length" class="flex-1">
          <p class="leading-8 text-2xl font-semibold">График</p>
          <CampaignChart :adAnalytics="adAnalytics" class="mt-4" />
        </div>
        <p v-else class="flex-1 leading-8 text-2xl font-semibold">Статистика по рекламной кампании отсутствует</p>
      </div>
    </div>
    <DelegateModal
      :adInfo="adInfo"
      :managers="managers"
      ref="delegateModal"
      v-show="isDelegateModalVisible"
      @close="handleClose"
      @apply="handleApply"
    />
    <DeleteModal ref="deleteModal" v-show="isDeleteModalVisible" @close="closeDeleteModal" @approve="deleteManager" />
  </div>
</template>

<script>
const weekDays = {
  0: 'Вс',
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
};

import { delegateCampaign, getExcelReport } from '@/api';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import _uniqBy from 'lodash/uniqBy';

import UiButton from '@/components/ui/UiButton';
import CampaignChart from '@/components/views/CampaignCard/CampaignChart';
import DelegateModal from '@/components/views/CampaignCard/DelegateModal';
import UiTimeTarget from '@/components/ui/UiTimeTarget.vue';
import DeleteModal from '@/components/ui/DeleteModal.vue';
import LoadingText from '@/components/ui/LoadingText.vue';

export default {
  name: 'CampaignDetails',

  components: { UiButton, CampaignChart, DelegateModal, UiTimeTarget, DeleteModal, LoadingText },

  props: {
    adInfo: {
      type: Object,
      default: () => ({}),
    },

    adAnalytics: {
      type: Array,
      default: () => [],
    },

    adIndicators: {
      type: Object,
      default: () => ({}),
    },

    organizationUsers: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isDelegateModalVisible: false,
      isTimeTargetVisible: false,
      isDeleteModalVisible: false,

      currentManager: null,
    };
  },

  computed: {
    userInfo() {
      return this.$store.state.auth.userInfo;
    },

    excelButtonVisible() {
      return this.adInfo.contextual_state === 'running';
    },

    showingDates() {
      if (!this.adInfo.start) return '';
      return this.formatDate(this.adInfo.start) + ' - ' + this.formatDate(this.adInfo.end);
    },

    managers() {
      if (!this.organizationUsers.length) return [];
      return this.$props.organizationUsers.filter((user) => user.manager);
    },

    campaignManagers() {
      if (!this.adInfo || !this.adInfo.managers || !this.adInfo.managers_readonly) return [];
      return this.adInfo.managers
        .map((manager) => {
          const user = this.organizationUsers.find((user) => user.id === manager);
          return { ...user, access: 'full' };
        })
        .concat(
          this.adInfo.managers_readonly.map((manager) => {
            const user = this.organizationUsers.find((user) => user.id === manager);
            return { ...user, access: 'readonly' };
          })
        );
    },

    admins() {
      if (!this.organizationUsers.length) return [];
      return this.$props.organizationUsers.filter((user) => !user.manager);
    },

    playersDimensions() {
      let dimensions = [];
      if (!this.adInfo?.content_item) return dimensions;

      this.adInfo.content_item.content_files.forEach((file) => {
        dimensions.push({
          width: file.data.width,
          height: file.data.height,
          key: file.data.width + 'x' + file.data.height,
        });
      });

      dimensions.sort((a, b) => a.width - b.width);
      dimensions = _uniqBy(dimensions, 'key');

      return dimensions;
    },

    isAdvertiser() {
      return this.$store.state.auth.organizationMode === 'advertiser';
    },
  },

  methods: {
    async downloadExcelReport() {
      try {
        const response = await getExcelReport(this.adInfo.id);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Otchet_${this.adInfo.name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        
      } catch (err) {
        console.error(err);
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          status: 'error',
          text: 'Ошибка при сохранении отчёта.',
        });
      }
    },

    formatDate(date) {
      const splitDate = date.split('-');
      return splitDate[2] + '.' + splitDate[1] + '.' + splitDate[0].slice(2) + ' ' + weekDays[new Date(date).getDay()];
    },

    openDelegateModal() {
      disableBodyScroll(this.$refs.delegateModal);
      this.isDelegateModalVisible = true;
    },

    handleClose() {
      this.isDelegateModalVisible = false;
      enableBodyScroll(this.$refs.delegateModal);
    },

    handleApply() {
      this.isDelegateModalVisible = false;
      enableBodyScroll(this.$refs.delegateModal);
      this.$emit('update-users');
    },

    handleClickTimeTargetOutside() {
      if (this.isTimeTargetVisible) {
        this.isTimeTargetVisible = false;
      }
    },

    openDeleteModal(manager) {
      this.currentManager = manager;
      disableBodyScroll(this.$refs.deleteModal);
      this.isDeleteModalVisible = true;
    },

    closeDeleteModal() {
      enableBodyScroll(this.$refs.deleteModal);
      this.isDeleteModalVisible = false;
    },

    async deleteManager() {
      this.closeDeleteModal();

      this.$store.commit('setPlugDisplay', true);
      const data = this.createDeleteData();
      try {
        await delegateCampaign(this.adInfo.id, data);
        this.$emit('update-users');
        this.currentManager = null;
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Удалён доступ менеджера к рекламной кампании.',
        });
      } catch (err) {
        console.error('CampaignDetails error');
        console.error(err);
        this.$store.commit('setPlugDisplay', false);
      }
    },

    createDeleteData() {
      if (this.currentManager.access === 'full')
        return { managers: this.adInfo.managers.filter((item) => item !== this.currentManager.id) };
      else
        return { managers_readonly: this.adInfo.managers_readonly.filter((item) => item !== this.currentManager.id) };
    },

    defineCircleClasses(campaign) {
      if (this.isAdvertiser) {
        if (campaign.draft) return 'bg-gray';
        if (campaign.paused) return 'bg-orange';
        if (campaign.state === 'finished') return 'bg-gray-5';
        if (campaign.contextual_state === 'pending') return 'bg-blue';
        if (campaign.contextual_state === 'declined') return 'bg-red-1';
        if (campaign.state === 'deleted') return 'bg-blue-4';
        if (campaign.state === 'pending') return 'bg-yellow';

        return 'bg-green-2';
      }
    },

    defineStatusText(campaign) {
      if (this.isAdvertiser) {
        if (campaign.draft) return 'Черновик';
        if (campaign.paused) return 'На паузе';
        if (campaign.state === 'finished') return 'Завершена';
        if (campaign.contextual_state === 'pending') return 'На модерации';
        if (campaign.contextual_state === 'declined') return 'Отклонена';
        if (campaign.state === 'deleted') return 'В архиве';
        if (campaign.state === 'pending') return 'В ожидании';
        return 'Активна';
      }
    },
  },
};
</script>

<style lang="scss">
.card-plug {
  &-enter-active,
  &-leave-active {
    transition: 0.5s opacity ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
