<template>
  <div ref="stepWrapper" id="stepWrapper" class="flex-1 bg-gray-4 relative flex flex-col">
    <keep-alive>
      <component
        ref="currentComponent"
        :is="currentComponent"
        :player-dimensions="playerDimensions"
        :form="form"
        :edit-form="editForm"
        :content-item="contentItem"
        @update-value="handleUpdateValue"
        @update-content="handleUpdateContent"
      />
    </keep-alive>
    <div style="height: 60px" class="absolute create-campaign__navigation bottom-0 w-full">
      <div class="main-container h-full flex items-center justify-end gap-6">
        <UiButton button-height="sm" style="width: 290px" color-type="yellow" class="px-4" @click="downloadMediaPlan">
          <span>Сформировать и выгрузить медиа-план</span>
        </UiButton>
        <UiButton v-if="currentCreateStep !== 1" button-height="sm" class="px-4" @click="handleBack"> Назад </UiButton>
        <UiButton v-if="currentCreateStep !== 3" button-height="sm" class="px-4" @click="handleContinue">
          Продолжить
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlayerDimensions, getCampaignInfo, loadMediaPlan } from '@/api';

import StepOne from '@/components/views/CreateCampaign/StepOne.vue';
import StepTwo from '@/components/views/CreateCampaign/StepTwo.vue';
import StepThree from '@/components/views/CreateCampaign/StepThree.vue';
import UiButton from '@/components/ui/UiButton.vue';
import DeleteModal from '@/components/ui/DeleteModal.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
  name: 'CreateCampaign',
  components: { StepOne, StepTwo, StepThree, UiButton, DeleteModal, LoadingSpinner },
  data() {
    return {
      form: {
        mechanism: 'six',
        budget: 0,
        content_item_id: null,
        draft: false,
        cpm: 11,
        description: '',
        start: null,
        end: null,
        indoor: true,
        outdoor: true,
        ots: 0,
        name: '',
        polygon_ids: [],
        spender: false,
        weekdays_hours: {},
        whitelist_player_ids: [],
        content_length: 5,
      },

      contentItem: {},

      playerDimensions: [],

      isResolutionsChanged: false,
      isApproveModalVisible: false,
      contentFiles: [],

      editForm: {},

      adInfo: null,
    };
  },

  computed: {
    currentCreateStep() {
      return this.$store.state.system.currentCreateStep;
    },

    currentComponent() {
      if (this.currentCreateStep === 2) return 'StepTwo';
      if (this.currentCreateStep === 3) return 'StepThree';
      return 'StepOne';
    },

    userInfo() {
      return this.$store.state.auth.userInfo;
    },
  },

  watch: {
    userInfo: {
      deep: true,
      handler() {
        if (this.adInfo?.managers_readonly?.includes(this.userInfo?.id)) this.$router.replace('/not-found');
      },
    },
  },

  methods: {
    handleUpdateValue(value) {
      const arr = ['whitelist_player_ids', 'content_length'];
      const changedField = Object.keys(value)[0];
      if (arr.includes(changedField)) this.isResolutionsChanged = true;

      this.form = { ...this.form, ...value };
    },

    handleUpdateContent(value) {
      this.contentItem = { ...value };
      this.form.content_item_id = value.id ? value.id : null;
    },

    changeStep(value) {
      this.$store.commit('setCurrentCreateStep', value);
    },

    async handleContinue() {
      if (this.currentCreateStep === 1) {
        const isFormCorrect = this.checkIsFormCorrect();
        if (!isFormCorrect) {
          this.$store.dispatch('setPopupDisplay', {
            display: true,
            status: 'warn',
            text: 'Пожалуйста, заполните все необходимые поля',
          });
          this.$refs.stepWrapper.scrollIntoView({ behavior: 'smooth', alignToTop: true });
          return;
        }
        await this.getPlayerDimensions();
      }

      if (this.currentCreateStep === 2 && !this.form.content_item_id) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Необходимо загрузить контент для рекламной кампании',
          status: 'warn',
        });
        return;
      }

      this.changeStep(this.currentCreateStep + 1);
    },

    handleBack() {
      this.changeStep(this.currentCreateStep - 1);
    },

    checkIsFormCorrect() {
      if (!this.form.start || !this.form.end) this.$refs.currentComponent.createDateError();
      if (!this.form.name) this.$refs.currentComponent.createNameError();
      if (this.form.whitelist_player_ids.length === 0) this.$refs.currentComponent.createPlayerError();
      let timeError = true;
      Object.keys(this.form.weekdays_hours).forEach((key) => {
        if (this.form.weekdays_hours[key].length > 0) timeError = false;
      });
      if (timeError) this.$refs.currentComponent.createTimeError();

      if (!this.form.start || !this.form.end || !this.form.name || !this.form.whitelist_player_ids.length || timeError)
        return false;

      return true;
    },

    async getCampaignInfo() {
      this.$store.commit('setPlugDisplay', true);
      try {
        const res = await getCampaignInfo({ id: this.$route.params.id });
        this.handleEditForm(res.data);
      } catch (err) {
        console.error('[EditCampaign Error]');
        console.error(err);
      } finally {
        this.$store.commit('setPlugDisplay', false);
      }
    },

    handleEditForm(form) {
      this.adInfo = form;
      if (this.adInfo.managers_readonly.includes(this.userInfo?.id)) this.$router.replace('/not-found');
      this.form.id = form.id;
      this.form.content_item_id = form.content_item_id;
      this.form.draft = form.draft;
      this.contentItem = { ...form.content_item };

      this.editForm = {
        budget: form.budget,
        content_item_id: form.content_item_id,
        content_length: 5,
        cpm: form.cpm,
        description: form.description,
        draft: form.draft,
        end: form.end,
        indoor: form.indoor,
        mechanism: form.mechanism,
        name: form.name,
        ots: form.ots,
        outdoor: form.outdoor,
        polygon_ids: [],
        spender: form.spender,
        start: form.start,
        weekdays_hours: { ...form.weekdays_hours },
        whitelist_player_ids: [...form.whitelist_player_ids],
        savedPlayers: [...form.whitelist_players.map((player) => ({ ...player }))],
      };
    },

    async downloadMediaPlan() {
      const isFormCorrect = this.checkIsFormCorrect();
      if (!isFormCorrect) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          status: 'warn',
          text: 'Пожалуйста, заполните все необходимые поля',
        });
        this.$refs.stepWrapper.scrollIntoView({ behavior: 'smooth', alignToTop: true });
        return;
      }
      try {
        this.$store.commit('setPlugDisplay', true);
        const res = await loadMediaPlan({
          ...this.form,
          id: undefined,
          duration: this.form.content_length,
          content_length: undefined,
          content_item_id: undefined,
        });
        const url = res.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Media_Plan ${this.form.name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.error(err);
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Ошибка при формировании медиа-плана.',
          status: 'error',
        });
      } finally {
        this.$store.commit('setPlugDisplay', false);
      }
    },

    async getPlayerDimensions() {
      if (!this.isResolutionsChanged) return;
      this.$store.commit('setPlugDisplay', true);
      try {
        const response = await getPlayerDimensions({ id: this.form.whitelist_player_ids });
        this.playerDimensions = response.data.map((item) => ({
          width: item.resolution[0],
          height: item.resolution[1],
          amount: item.players.length,
          title: `${item.resolution[0]}x${item.resolution[1]}PX`,
        }));
        this.isResolutionsChanged = false;
      } catch (err) {
        console.error('[GetPlayerDimensions Error]');
        console.error(err);
      } finally {
        this.$store.commit('setPlugDisplay', false);
      }
    },
  },

  async mounted() {
    this.$store.commit('setCurrentCreateStep', 1);
    if (this.$route.name === 'edit-campaign') this.getCampaignInfo();
  },
};
</script>

<style lang="scss">
.create-campaign {
  &__navigation {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
</style>
