<template>
  <div class="sign-up min-h-screen relative">
    <img width="128" src="@/assets/images/logotype.svg" class="mx-auto top-14 absolute left-0 right-0" />
    <div class="relative z-1 pt-32 pb-12">
      <div class="sign-up__form-wrapper pb-6 pt-4 bg-white rounded-16 mx-auto">
        <p class="font-semibold text-gray px-6">Регистрация</p>
        <div class="w-full h-px mt-4 bg-gray-2"></div>
        <div class="px-6 mt-4">
          <div class="flex items-center">
            <div
              v-for="status in statuses"
              :key="status.key"
              style="height: 44px"
              class="flex-1 cursor-pointer flex justify-center items-center relative"
              :class="status.key === form.mode ? 'bg-gray-4' : 'bg-white'"
              @click="form.mode = status.key"
            >
              <p class="text-sm leading-6">{{ status.title }}</p>
              <div
                class="absolute bottom-0 left-0 w-full h-0.5 bg-blue"
                :class="{ hidden: form.mode !== status.key }"
              ></div>
              <div
                class="absolute bottom-0 left-0 w-full h-px bg-gray-2"
                :class="{ hidden: form.mode === status.key }"
              ></div>
            </div>
          </div>
          <div
            class="flex items-center mt-4 rounded-4 border overflow-hidden"
            :class="
              nameError
                ? 'border-red-1 sign-up__complex-input_error'
                : 'border-gray-2 hover:border-blue-hover sign-up__complex-input'
            "
          >
            <UiInput style="width: 40%" placeholder="ИНН" no-border :max-length="10" />
            <UiInput
              v-model="form.name"
              placeholder="Название организации"
              no-border
              color-type="gray"
              class="flex-1"
            />
          </div>
          <p v-if="nameError" class="mt-1 text-red-1 text-sm leading-5">Введите название организации</p>
          <UiInput
            v-if="form.mode === 'operator'"
            v-model="form.remote_organization"
            :max-length="20"
            placeholder="Remote Organization ID"
            class="mt-4"
          />
          <UiInput v-model="form.email" placeholder="Email" class="mt-4" />
          <p v-if="emailError" class="mt-1 text-red-1 text-sm leading-5">Введен некорректный Email</p>
          <UiInput v-model="form.profile.first_name" placeholder="Имя" class="mt-4" />
          <p v-if="firstNameError" class="mt-1 text-red-1 text-sm leading-5">Введите имя пользователя</p>
          <UiInput v-model="form.profile.last_name" placeholder="Фамилия" class="mt-4" />
          <p v-if="lastNameError" class="mt-1 text-red-1 text-sm leading-5">Введите фамилию пользователя</p>

          <UiButton :disabled="isAccountCreated" class="mt-6" @click="signUp">
            <span v-if="!loading">Зарегистрироваться</span>
            <LoadingSpinner v-else />
          </UiButton>
        </div>
        <div class="w-full h-px mt-6 bg-gray-2"></div>
        <div class="px-6">
          <p class="mt-4 text-sm leading-6 text-center">
            Уже есть аккаунт?
            <router-link to="/sign-in" class="text-blue hover:text-blue-hover active:text-blue-active cursor-pointer">
              Войти
            </router-link>
          </p>
          <p class="mt-3 text-gray-3 text-center text-sm leading-5">
            При регистрации вы подтверждаете согласие с
            <span class="border-b border-gray-3 cursor-pointer">условиями использования</span> сервиса и
            <span class="border-b border-gray-3 cursor-pointer">политикой о данных пользователей</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { signUp } from '@/api';
import UiInput from '@/components/ui/UiInput.vue';
import UiButton from '@/components/ui/UiButton';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { signUp } from '@/api';

export default {
  name: 'SignUp',

  components: { UiInput, UiButton, LoadingSpinner },

  data() {
    return {
      statuses: [
        { title: 'Рекламодатель', key: 'advertiser' },
        { title: 'Оператор', key: 'operator' },
      ],

      form: {
        name: '',
        email: '',
        profile: {
          first_name: '',
          last_name: '',
        },
        mode: 'advertiser',
      },

      nameError: false,
      emailError: false,
      firstNameError: false,
      lastNameError: false,

      loading: false,

      isAccountCreated: false,
    };
  },

  watch: {
    'form.email'() {
      this.emailError = false;
      this.isAccountCreated = false;
    },

    'form.profile.last_name'() {
      this.lastNameError = false;
    },

    'form.profile.first_name'() {
      this.firstNameError = false;
    },

    'form.name'() {
      this.nameError = false;
    },
  },

  methods: {
    validate() {
      if (!this.form.email || !/^\S+@\S+\.\S+$/.test(this.form.email)) this.emailError = true;
      if (!this.form.profile.last_name) this.lastNameError = true;
      if (!this.form.profile.first_name) this.firstNameError = true;
      if (!this.form.name) this.nameError = true;
    },

    async signUp() {
      this.validate();
      if (this.nameError || this.emailError || this.firstNameError || this.lastNameError) return;

      this.loading = true;

      try {
        await signUp({
          ...this.form,
          remote_organization: this.form.remote_organization ? Number(this.form.remote_organization) : undefined,
        });
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Аккаунт успешно создан. Проверьте указанную почту.',
        });
        this.isAccountCreated = true;
      } catch (err) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: this.defineErrorText(err),
          status: 'error',
        });
      } finally {
        this.loading = false;
      }
    },

    defineErrorText(err) {
      if (err.response.data.message) return err.response.data.message;
      return 'Ошибка при регистрации, попробуйте ещё раз.';
    },
  },
};
</script>

<style lang="scss">
.sign-up {
  background: url('@/assets/images/views/sign-in/background.svg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &__form-wrapper {
    width: 366px;
    box-shadow: 0px 60px 80px 0px #00000029;
  }
  &__complex-input:focus-within {
    box-shadow: 0px 0px 4px 0px #1890ff80;
  }
  &__complex-input_error:focus-within {
    box-shadow: 0px 0px 4px 0px #f5222d80;
  }
}
</style>
