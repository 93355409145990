<template>
  <div
    class="user-context bg-white rounded-4"
    :class="{
      '-top-12': userInfo.state === 'pending',
      '-top-2': userInfo.state === 'deleted',
      '-top-6': userInfo.state === 'active',
    }"
  >
    <div
      v-for="option in options"
      :key="option.key"
      class="my-1 px-3 text-gray-6 flex items-center text-sm leading-6 hover:text-blue cursor-pointer w-max h-8"
      @click="handleOptionClick(option)"
    >
      {{ option.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserContextMenu',

  props: {
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    options() {
      if (this.$props.userInfo.state === 'deleted') return [{ title: 'Удалить', key: 'delete' }];
      if (this.$props.userInfo.state === 'active' && this.$props.userInfo.manager)
        return [
          { title: 'Сделать админом', key: 'admin' },
          { title: 'Заблокировать', key: 'block' },
        ];
      if (this.$props.userInfo.state === 'active' && !this.$props.userInfo.manager)
        return [
          { title: 'Сделать менеджером', key: 'manager' },
          { title: 'Заблокировать', key: 'block' },
        ];
      if (this.$props.userInfo.state === 'pending' && !this.$props.userInfo.manager)
        return [
          { title: 'Сделать менеджером', key: 'manager' },
          { title: 'Пригласить повторно', key: 'invite' },
          { title: 'Заблокировать', key: 'block' },
        ];

      return [
        { title: 'Сделать админом', key: 'admin' },
        { title: 'Пригласить повторно', key: 'invite' },
        { title: 'Заблокировать', key: 'block' },
      ];
    },
  },

  methods: {
    handleOptionClick(option) {
      if (this.$props.userInfo.id === this.$store.state.auth.userInfo.id) return;

      if (option.key === 'manager') this.$emit('manager');
      if (option.key === 'admin') this.$emit('admin');
      if (option.key === 'invite') this.$emit('invite');
      else if (option.key === 'block') this.$emit('block');

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.user-context {
  box-shadow: 0px 2px 8px 0px #00000026;
}
</style>
