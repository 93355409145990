<template>
  <div class="player-map__card my-3 rounded-4">
    <div
      class="flex bg-white justify-between bg-gray-4 relative overflow-hidden"
      :class="player?.user_data?.photos?.length ? 'h-24' : 'h-10 items-center p-2'"
    >
      <img
        v-if="player?.user_data?.photos?.length"
        :src="player?.user_data?.photos[0]"
        class="object-cover absolute w-full h-full"
      />
      <div class="player-map__tag mt-1 ml-1 flex items-center gap-1.5 rounded-4 h-6 px-2 relative z-5">
        <img width="14" height="2057" src="@/assets/images/views/create-campaign/eye.svg" />
        <p class="text-white text-xs leading-4">2057</p>
      </div>
      <div class="player-map__tag mt-1 mr-1 flex items-center justify-center rounded-4 h-6 px-2 relative z-5">
        <p class="text-white text-xs leading-4">9 900 руб./сут.</p>
      </div>
    </div>
    <div class="p-2 bg-white">
      <p class="text-xs leading-4 text-gray">{{ player.name }}</p>
      <p class="mt-1.5 text-xs leading-4 text-gray-3">{{ player.description }}</p>
      <template>
        <UiButton
          v-if="!isAdded"
          button-height="xs"
          class="mt-1.5 px-2 w-max balloon-layout__add"
          @click="addSpecificPlayer"
        >
          Добавить в заказ
        </UiButton>
        <UiButton
          v-else
          button-height="xs"
          colorType="danger"
          class="mt-1.5 px-2 w-max balloon-layout__add"
          @click="removeSpecificPlayer"
        >
          Убрать из заказа
        </UiButton>
      </template>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton';

export default {
  name: 'PlayerCard',
  components: { UiButton },
  props: {
    player: {
      type: Object,
      default: () => ({}),
    },
    isAdded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    addSpecificPlayer() {
      this.$emit('addPlayer', this.player.id);
    },
    removeSpecificPlayer() {
      this.$emit('removePlayer', this.player.id);
    },
  },
};
</script>
