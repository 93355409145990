<template>
  <div class="content-card">
    <input ref="fileInput" type="file" class="hidden" @input="handleFileChange" />
    <div
      class="content-card__image relative"
      @drop.prevent="handleCardDrop"
      @dragover.prevent="handleCardDragover"
      @dragleave.prevent="handleCardDragleave"
    >
      <transition name="upload-plug">
        <div v-show="isUploadPlugVisible" style="background: rgba(80, 80, 80, 0.24)" class="absolute inset-0 z-9">
          <div class="h-full flex items-center justify-center">
            <img width="80" height="80" src="@/assets/images/views/create-campaign/download.svg" />
          </div>
        </div>
      </transition>
      <div v-if="cardError" class="bg-gray-8 h-full flex items-center justify-center px-4">
        <div class="p-4 rounded-4 flex items-start gap-2 border border-red-5 bg-red-4">
          <div class="flex-1">
            <p class="text-gray">Ошибка при загрузке</p>
            <p class="mt-1 text-sm leading-5 text-gray-6">Разрешение, тип или размер файла не подходят.</p>
          </div>
          <img
            width="16"
            height="16"
            src="@/assets/images/ui/ui-cross.svg"
            class="cursor-pointer"
            @click="closeError"
          />
        </div>
      </div>
      <div v-else-if="contentFile" class="h-full relative bg-black">
        <transition name="upload-plug">
          <div v-show="loading" style="background: rgba(0, 0, 0, 0.36)" class="absolute inset-0 z-5">
            <div class="h-full flex items-center justify-center">
              <LoadingSpinner color="blue" />
            </div>
          </div>
        </transition>
        <img :src="contentFile.preview" class="object-contain w-full absolute h-full" />
        <div class="flex gap-4 absolute left-0 right-0 bottom-4 px-4">
          <UiButton button-height="sm" class="flex-1" @click="handleUploadClick">Заменить</UiButton>
          <UiButton color-type="danger" button-height="sm" class="flex-1" @click="deleteVideo">Удалить</UiButton>
        </div>
      </div>
      <div v-else class="bg-gray-8 h-full relative flex items-center justify-center">
        <p v-if="!loading" style="font-size: 30px" class="text-gray-2 text-center">
          {{ `${dimension.width} x ${dimension.height} PX` }}
        </p>
        <LoadingSpinner color="blue" v-else />
        <div class="absolute left-0 right-0 bottom-4 px-4 justify-center">
          <p class="text-sm leading-5 text-gray-3 text-center">
            Перетащите в область<br />
            или
          </p>
          <UiButton button-height="sm" class="w-full gap-1 mt-2" @click="handleUploadClick">
            <img width="14" height="14" src="@/assets/images/views/create-campaign/download.svg" />
            <span>Загрузите с компьютера</span>
          </UiButton>
        </div>
      </div>
    </div>
    <div class="p-4 bg-white">
      <p class="font-semibold">{{ `${dimension.width} x ${dimension.height} PX` }}</p>
      <div class="grid grid-cols-3 mt-2">
        <p class="text-gray-3 col-span-2 text-sm leading-6">Кол-во экранов</p>
        <p class="text-gray text-sm leading-6">{{ dimension.amount }}</p>
      </div>
      <div class="grid grid-cols-3 mt-1">
        <p class="text-gray-3 col-span-2 text-sm leading-6">Длительность ролика</p>
        <p class="text-gray text-sm leading-6">{{ contentFile ? contentFile.data.duration : form.content_length }} сек.</p>
      </div>
    </div>

    <DeleteModal
      ref="approveReplaceModal"
      v-show="isReplaceModalVisible"
      :text="`Заменить файл кампании с разрешением ${dimension.width}x${dimension.height} PX?`"
      @approve="acceptReplace('confirm')"
      @close="handleReplaceModalClose"
    >
      <template v-slot:decline>Нет</template>
      <template v-slot:button><span>Да</span></template>
    </DeleteModal>

    <DeleteModal
      ref="approveDeleteModal"
      v-show="isDeleteModalVisible"
      :text="`Удалить файл кампании с разрешением ${dimension.width}x${dimension.height} PX?`"
      @approve="acceptDelete('confirm')"
      @close="handleDeleteModalClose"
    />
  </div>
</template>

<script>
import {
  createContentFile,
  createContentItem,
  addFileToContentItem,
  replaceFileInContentItem,
  deleteFileFromContentItem,
  getContentItem,
} from '@/api';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import DeleteModal from '@/components/ui/DeleteModal.vue';
import UiButton from '@/components/ui/UiButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
  name: 'ContentCard',

  components: { UiButton, LoadingSpinner, DeleteModal },

  props: {
    dimension: {
      type: Object,
      default: () => ({}),
    },

    contentItem: {
      type: Object,
      default: () => ({}),
    },

    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      cardError: false,

      currentFile: null,
      isUploadPlugVisible: false,

      acceptReplace: null,
      acceptDelete: null,

      isDeleteModalVisible: false,
      isReplaceModalVisible: false,

      currentReplaceTitle: '',
      loading: false,
    };
  },

  computed: {
    contentFile() {
      if (!this.contentItem.content_files) return null;
      const file = this.contentItem.content_files.find(
        (file) => file.data.width === this.dimension.width && file.data.height === this.dimension.height
      );
      if (file) return file;
      return null;
    },

    isContentItemCreated() {
      return Boolean(this.contentItem.content_files);
    },
  },

  methods: {
    closeError() {
      this.cardError = false;
    },

    handleReplaceModalClose() {
      this.isReplaceModalVisible = false;
      this.acceptReplace('close');
    },

    handleDeleteModalClose() {
      this.isDeleteModalVisible = false;
      this.acceptDelete('close');
    },

    async handleUploadClick() {
      if (this.loading) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          status: 'warn',
          text: `Загрузка файла с разрешением ${this.dimension.width} x ${this.dimension.height} PX осуществляется в данный момент.`,
        });
        return;
      }

      if (this.contentFile) {
        const promise = new Promise((res) => {
          this.acceptReplace = res;
        });
        this.isReplaceModalVisible = true;
        disableBodyScroll(this.$refs.approveReplaceModal);
        const res = await promise;
        enableBodyScroll(this.$refs.approveReplaceModal);
        this.isReplaceModalVisible = false;
        if (res !== 'confirm') return;
      }

      this.$refs.fileInput.click();
    },

    handleFileChange() {
      this.currentFile = this.$refs.fileInput.files[0];
      this.validateVideo();
    },

    validateVideo() {
      if (this.currentFile.type !== 'video/mp4') {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Недопустимое расширение файла.',
          status: 'warn',
        });
        this.cardError = true;
        return;
      }
      const url = URL.createObjectURL(this.currentFile);
      const video = document.createElement('video');
      video.src = url;
      video.addEventListener('loadedmetadata', () => {
        if (video.videoWidth == this.dimension.width && video.videoHeight == this.dimension.height) {
          this.uploadVideo();
        } else {
          this.cardError = true;
          this.$store.dispatch('setPopupDisplay', {
            display: true,
            text: 'Недопустимое разрешение видеофайла.',
            status: 'warn',
          });
        }
      });
    },

    async handleCardDrop(event) {
      this.isUploadPlugVisible = false;
      if (this.loading) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          status: 'warn',
          text: `Загрузка файла с разрешением ${this.dimension.width} x ${this.dimension.height} PX осуществляется в данный момент.`,
        });
        return;
      }
      this.currentFile = event.dataTransfer.items[0].getAsFile();

      if (this.contentFile) {
        const promise = new Promise((res) => {
          this.acceptReplace = res;
        });
        this.isReplaceModalVisible = true;
        disableBodyScroll(this.$refs.approveReplaceModal);
        const res = await promise;
        enableBodyScroll(this.$refs.approveReplaceModal);
        this.isReplaceModalVisible = false;
        if (res !== 'confirm') return;
      }
      this.validateVideo();
    },

    handleCardDragover() {
      this.isUploadPlugVisible = true;
    },

    handleCardDragleave() {
      this.isUploadPlugVisible = false;
    },

    async uploadVideo() {
      this.loading = true;
      try {
        const fileRes = await createContentFile(this.currentFile);
        const file = fileRes.data;
        if (!this.isContentItemCreated) {
          await this.createContentItem(file);
        } else {
          if (this.contentFile) {
            await this.replaceFileInItem(file);
          } else {
            await this.addFileToItem(file);
          }
        }
      } catch (err) {
        console.error(err);
        if (err.response.status === 422) return;
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Недопустимое расширение файла.',
          status: 'error',
        });
      } finally {
        this.loading = false;
        this.$refs.fileInput.value = null;
      }
    },

    async createContentItem(file) {
      const res = await createContentItem({
        name: this.form.name,
        description: this.form.description,
        contentFiles: [file.id],
      });
      this.$emit('update-content', res.data);
    },

    async addFileToItem(file) {
      try {
        const res = await addFileToContentItem(this.contentItem.id, file.id);
        this.$emit('update-content', res.data);
      } catch (err) {
        console.error(err);
        if (err.response.status !== 422) return;
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: err.response.data.message.ru,
          status: 'error',
        });
        throw err;
      }
    },

    async replaceFileInItem(file) {
      try {
        const res = await replaceFileInContentItem(this.contentItem.id, file.id);
        this.$emit('update-content', res.data);
      } catch (err) {
        console.error(err);
        if (err.response.status !== 422) return;
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: err.response.data.message.ru,
          status: 'error',
        });
        throw new Error();
      }
    },

    async deleteVideo() {
      const promise = new Promise((res) => {
        this.acceptDelete = res;
      });
      this.isDeleteModalVisible = true;
      disableBodyScroll(this.$refs.approveDeleteModal);
      const res = await promise;
      enableBodyScroll(this.$refs.approveDeleteModal);
      this.isDeleteModalVisible = false;
      if (res !== 'confirm') return;

      this.loading = true;
      try {
        await deleteFileFromContentItem(this.contentFile.id);
        const res = await getContentItem(this.contentItem.id);
        this.$emit('update-content', res.data);
      } catch (err) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: err.response.data.message.ru,
          status: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.content-card {
  box-shadow: 0px 1px 4px 0px #00000014;

  &__image {
    height: 290px;
  }
}

.upload-plug {
  &-leave-active,
  &-enter-active {
    transition: opacity 0.2s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
