import { httpClient, getUserInfo, getCampaigns, getBalance } from '@/api';
import Cookies from 'js-cookie';

export default {
  state: {
    authToken: '',
    passwordToken: '',

    userInfo: null,
    organizationMode: null,
    userLoading: false,

    balance: 0,
    balanceLoading: false,

    campaigns: null,
    campaignsLoading: false,
    campaignsInfo: null,
  },
  mutations: {
    setAuthToken(state, value) {
      state.authToken = value;
      httpClient.defaults.headers.common['Authorization'] = value ? `OAuth ${value}` : null;
      if (value) Cookies.set('authToken', value);
      else Cookies.remove('authToken');
    },

    setUserInfo(state, value) {
      state.userInfo = value;
    },

    setBalance(state, value) {
      state.balance = value;
    },

    setUserRole(state, value) {
      state.isAdvertiser = value;
    },

    setCampaigns(state, value) {
      state.campaignsInfo = value.meta;
      state.campaigns = value.objects;
    },

    setPasswordToken(state, value) {
      state.passwordToken = value;
    },

    setOrganizationMode(state, value) {
      state.organizationMode = value;
      if (value) Cookies.set('oohvat_mode', value);
      else if (Cookies.get('oohvat_mode')) Cookies.remove('oohvat_mode');
    },
  },

  actions: {
    async getAccountInfo({ dispatch }) {
      dispatch('getUserInfo');
      dispatch('getBalance');
      dispatch('getCampaigns');
    },

    async getCampaigns({ commit, state }, query) {
      state.campaignsLoading = true;
      try {
        const adResponse = await getCampaigns(query);
        commit('setCampaigns', adResponse.data);
      } catch (err) {
        console.error('[Store getCampaigns error]');
        console.error(err);
      } finally {
        state.campaignsLoading = false;
      }
    },

    async getUserInfo({ commit, state }) {
      state.userLoading = true;
      try {
        const userResponse = await getUserInfo();
        commit('setUserInfo', userResponse.data);
        commit('setOrganizationMode', userResponse.data.organization?.mode);
      } catch (err) {
        console.error(err);
      } finally {
        state.userLoading = false;
      }
    },

    async getBalance({ commit, state }) {
      state.balanceLoading = true;
      try {
        const balanceRes = await getBalance();
        commit('setBalance', balanceRes.data.balance / 100);
      } catch (err) {
        console.error(err);
      } finally {
        state.balanceLoading = false;
      }
    },

    logOut({ state, commit }) {
      commit('setUserInfo', null);
      commit('setBalance', 0);
      commit('setOrganizationMode', null);
      commit('setAuthToken', null);
      commit('setPopupDisplay', { display: false });
      state.campaigns = null;
      state.campaignsInfo = null;
    },
  },
};
