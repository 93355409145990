<template>
  <div class="flex gap-2 items-center">
    <div
      class="w-4 h-4 rounded-4 border border-gray-2 hover:border-blue flex justify-center items-center cursor-pointer"
      :class="{ 'bg-blue': value }"
      @click="handleClick"
      @mousedown.prevent
    >
      <img v-show="value" width="9.5" height="7.5" src="@/assets/images/ui/checkbox/checkmark.svg" />
      <input :checked="value" ref="hiddenCheckbox" type="checkbox" @input="handleInput" class="hidden" />
    </div>
    <p v-show="label" class="text-gray-6 text-sm leading-5">{{ label }}</p>
  </div>
</template>

<script>
export default {
  name: 'UiCheckbox',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClick() {
      this.$refs.hiddenCheckbox.dispatchEvent(new Event('input'));
    },
    handleInput(event) {
      this.$emit('input', !event.target.checked);
    },
  },
};
</script>
