<template>
  <div class="sign-in min-h-screen relative pb-5">
    <img width="128" src="@/assets/images/logotype.svg" class="mx-auto top-14 absolute left-0 right-0" />
    <div class="relative z-1 pt-32">
      <div class="sign-in__form-wrapper pb-6 pt-4 bg-white rounded-16 mx-auto">
        <p class="font-semibold text-gray px-6">Вход</p>
        <div class="w-full h-px mt-4 bg-gray-2"></div>
        <form id="loginform" ref="loginForm" class="px-6 mt-4" autocomplete="on" @submit.prevent>
          <UiInput
            v-model="form.email"
            :color-type="emailError ? 'error' : 'primary'"
            placeholder="Электронная почта"
            input-name="username"
            class="mt-4"
            @input="emailError = false"
          />
          <p v-if="emailError" class="mt-1 text-red-1 text-sm leading-5">Введен некорректный Email</p>
          <UiInput
            v-model="form.password"
            :colorType="passwordError ? 'error' : 'primary'"
            :inputType="isPasswordInput ? 'password' : 'text'"
            input-name="password"
            placeholder="Пароль"
            class="mt-4"
            @input="passwordError = false"
          >
            <img
              width="18"
              height="18"
              src="@/assets/images/views/sign-in/password-icon.svg"
              class="shrink-0 cursor-pointer"
              @click="isPasswordInput = !isPasswordInput"
            />
          </UiInput>
          <p v-if="passwordError" class="mt-1 text-red-1 text-sm leading-5">Пожалуйста, введите пароль</p>
          <div class="flex mt-4 justify-between items-center">
            <UiCheckbox v-model="isPasswordSaved" label="Запомнить пароль" />
            <router-link
              to="/recover-password"
              class="text-blue hover:text-blue-hover active:text-blue-active text-sm leading-5 cursor-pointer"
            >
              Забыли пароль?
            </router-link>
          </div>
          <UiButton class="mt-5" @click="signIn">
            <span v-if="!loading">Войти</span> <LoadingSpinner v-else />
          </UiButton>
          <div
            v-if="loginError"
            class="mt-4 rounded-4 border border-blue-3 bg-blue-2 p-4 text-gray-6 text-sm leading-5"
          >
            Некорректный логин или пароль
          </div>
        </form>
        <div class="w-full h-px mt-6 bg-gray-2"></div>
        <div class="px-6">
          <p class="mt-4 text-sm leading-6 text-center">
            Нет аккаунта?
            <router-link to="/sign-up" class="text-blue hover:text-blue-hover active:text-blue-active cursor-pointer">
              Зарегистрируйтесь сейчас
            </router-link>
          </p>
          <p class="mt-3 text-gray-3 text-center text-sm leading-5">
            При входе вы подтверждаете согласие с
            <span class="border-b border-gray-3 cursor-pointer">условиями использования</span> сервиса и
            <span class="border-b border-gray-3 cursor-pointer">политикой о данных пользователей</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api';
import UiInput from '@/components/ui/UiInput.vue';
import UiButton from '@/components/ui/UiButton';
import UiCheckbox from '@/components/ui/UiCheckbox';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

export default {
  name: 'SignIn',
  components: { UiInput, UiButton, UiCheckbox, LoadingSpinner },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      isPasswordSaved: false,

      emailError: false,
      passwordError: false,
      loginError: false,

      loading: false,
      isPasswordInput: true,
    };
  },
  methods: {
    async signIn() {
      this.validate();
      if (this.emailError || this.passwordError) return;

      this.loading = true;
      try {
        const response = await login(this.form);
        if (response.status === 200) {
          this.$store.commit('setAuthToken', response.data.access_token);
          this.$store.dispatch('getAccountInfo');
          if (this.isPasswordSaved) this.saveCredentials();
          else {
            location.reload();
          }
        }
      } catch (error) {
        this.loginError = true;
      } finally {
        this.loading = false;
      }
    },

    validate() {
      if (!this.form.email || !/^\S+@\S+\.\S+$/.test(this.form.email)) this.emailError = true;
      if (!this.form.password) this.passwordError = true;
    },

    saveCredentials() {
      const isFirefox = typeof InstallTrigger !== 'undefined';
      if (isFirefox) this.$refs.loginForm.submit();
      this.$router.push('/advertising-campaigns');
    },
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.loginError = false;
      },
    },
  },
};
</script>

<style lang="scss">
.sign-in {
  background: url('@/assets/images/views/sign-in/background.svg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &__form-wrapper {
    width: 366px;
    box-shadow: 0px 60px 80px 0px #00000029;
  }
}
</style>
