<template>
  <div style="min-width: 1320px" id="app">
    <Navbar v-if="!loginPaths.includes($route.path)" />
    <keep-alive :include="['AdvertisingCampaigns']">
      <router-view class="flex-1 bg-gray-4" />
    </keep-alive>
    <Footer v-if="!loginPaths.includes($route.path)" />
    <transition name="app-plug">
      <UiPlug v-show="isPlugVisible" />
    </transition>
    <transition name="info-popup">
      <InfoPopup v-show="isPopupVisible" />
    </transition>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import UiPlug from '@/components/ui/UiPlug';
import InfoPopup from '@/components/InfoPopup.vue';

export default {
  name: 'App',
  
  components: { Navbar, Footer, UiPlug, InfoPopup },

  computed: {
    loginPaths() {
      return this.$store.state.system.loginPaths;
    },

    isPlugVisible() {
      return this.$store.state.system.isPlugVisible;
    },

    isPopupVisible() {
      return this.$store.state.system.popupDisplay;
    },
  },

  beforeCreate() {
    const mode = Cookies.get('oohvat_mode');
    if (mode) this.$store.commit('setOrganizationMode', mode);
  },

  async created() {
    const token = Cookies.get('authToken');
    if (this.$route.hash.includes('resetpasswordconfirm')) {
      const passwordToken = this.$route.hash.split('/')[1];
      this.$store.commit('setPasswordToken', passwordToken);
      this.$router.push('/confirm-password');
    } else if (token && (this.loginPaths.includes(this.$route.path) || this.$route.path === '/'))
      this.$router.replace('/advertising-campaigns');
    else if (!token && !this.loginPaths.includes(this.$route.path)) {
      this.$router.replace('/sign-in');
    }

    if (token) {
      this.$store.commit('setAuthToken', token);
      this.$nextTick(() => {
        this.$store.dispatch('getAccountInfo');
      });
    }
  },

  methods: {
    // async mess() {
    //   if (this.loginPaths) return;
    //   const res = await getModerationHistory();
    //   console.log(res);
    // },
  },
};
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-plug {
  &-leave-active,
  &-enter-active {
    transition: all 0.5s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.info-popup {
  &-enter-active,
  &-enter-active .info-popup {
    transition: all 300ms cubic-bezier(0.03, 0.56, 0.11, 1.22);
  }

  &-enter .info-popup {
    transform: translateX(450px);
  }
}
</style>
