<template>
  <div class="bg-white p-6 pb-16 mt-4">
    <p style="font-size: 30px" class="leading-10 font-semibold text-gray">Модерация</p>
    <div class="mt-4 flex items-center justify-between">
      <p class="text-xl leading-7 text-gray">Проверьте ролики, и уберите те, которые вам не подходят</p>
      <LoadingText v-if="loading" class="h-6 w-64 rounded-10" />
      <div v-else-if="videos.length > 0" class="flex items-center gap-6">
        <p class="text-gray">Выбрано {{ selectedVideos.length }} из {{ videos.length }} роликов</p>
        <div class="flex gap-3 items-center">
          <UiCheckbox v-model="isAllChecked" />
          <p class="text-sm leading-6 text-gray">Выбрать все</p>
        </div>
      </div>
      <div v-else class="text-gray">Все ролики прошли оценку на соответствие</div>
    </div>
    <div v-if="loading" class="mt-8 grid grid-cols-4 gap-6">
      <LoadingText style="height: 330px" v-for="n in videos.length ? videos.length : 4" :key="n" class="rounded-10" />
    </div>
    <div v-else class="mt-8 grid grid-cols-4 gap-6">
      <div
        v-for="video in uneditableVideos.concat(videos)"
        :key="video.width"
        class="moderation__card relative overflow-hidden"
        :class="{
          'shadow-selected': selectedVideos.includes(video.id),
          'moderation__card_active cursor-pointer': video.moderation === null,
        }"
        @click="handleCardClick(video)"
      >
        <transition name="moderation-check">
          <div v-show="selectedVideos.includes(video.id)" class="absolute top-0 right-0 z-1">
            <img width="54" height="54" src="@/assets/images/views/campaign-card/video-angle.svg" />
            <img
              width="24"
              height="24"
              src="@/assets/images/views/campaign-card/video-check.svg"
              class="absolute top-1 right-1"
            />
          </div>
        </transition>
        <div class="moderation__image relative bg-black">
          <img
            controls
            loop
            :src="video.preview"
            :poster="video.preview"
            class="h-full w-full object-contain object-center"
          />
        </div>
        <div class="p-4 pt-5">
          <p class="font-semibold text-gray">
            {{ `${video.data.width} x ${video.data.height} PX` }}
          </p>
          <div class="grid grid-cols-3 mt-2">
            <p class="text-gray-3 col-span-2 text-sm leading-6">Кол-во экранов</p>
            <p class="text-gray text-sm leading-6">
              {{ 1313 }}
            </p>
          </div>
          <div class="grid grid-cols-3 mt-1">
            <p class="text-gray-3 col-span-2 text-sm leading-6">Длительность ролика</p>
            <p class="text-gray text-sm leading-6">{{ video.data.duration.toFixed(2) }} сек</p>
          </div>
          <div class="grid grid-cols-3 mt-1">
            <p class="text-gray-3 col-span-2 text-sm leading-6">Модерация</p>
            <p class="text-gray text-sm leading-6">
              <span v-if="video.moderation === null" class="text-gray">Не проверен</span>
              <span v-else-if="video.moderation?.approved" class="text-green-2">Одобрен</span>
              <span v-else-if="!video.moderation?.approved" class="text-red-1">Отклонён</span>
            </p>
          </div>
          <div v-if="video.moderation?.approved === false" class="grid grid-cols-3s mt-1">
            <p class="text-gray col-span-2 text-sm leading-6 font-medium">Причина отклонения:</p>
            <p class="text-gray text-sm leading-6">{{ video.moderation.reason }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12 flex gap-6 items-center">
      <UiButton
        :disabled="selectedVideos.length === 0"
        button-height="sm"
        color-type="danger"
        class="ml-auto w-28"
        @click="openModal"
      >
        Отклонить
      </UiButton>
      <UiButton :disabled="selectedVideos.length === 0" button-height="sm" class="w-28" @click="approveVideo">
        <span v-if="!approveLoading">Одобрить</span>
        <LoadingSpinner v-else />
      </UiButton>
    </div>
    <ModerationModal
      v-show="moderationModalDisplay"
      :videos="videos"
      :selected-videos="selectedVideos"
      :ad-info="adInfo"
      @close="closeModal"
      @update-content="handleUpdateContent"
    />
  </div>
</template>

<script>
import { moderateContent } from '@/api';

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import UiButton from '@/components/ui/UiButton.vue';
import LoadingText from '@/components/ui/LoadingText.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import ModerationModal from '@/components/views/CampaignCard/ModerationModal.vue';

export default {
  name: 'ContentModeration',

  components: { LoadingSpinner, LoadingText, UiButton, UiCheckbox, ModerationModal },

  props: {
    adInfo: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      videos: [],
      uneditableVideos: [],

      isAllSelected: false,
      selectedVideos: [],

      moderationModalDisplay: false,

      approveLoading: false,
    };
  },

  computed: {
    isAllChecked: {
      get() {
        let isChecked = true;
        this.videos.forEach((video) => {
          if (isChecked) isChecked = Boolean(this.selectedVideos.find((item) => item === video.id));
        });
        return isChecked;
      },

      set() {
        if (this.isAllChecked) {
          this.selectedVideos.splice(0, this.videos.length);
        } else {
          this.videos.forEach((video) => {
            if (!this.selectedVideos.includes(video.id)) this.selectedVideos.push(video.id);
          });
        }
      },
    },
  },

  watch: {
    'adInfo.content_item.content_files'() {
      this.videos = this.$props.adInfo.content_item.content_files.filter((item) => item.moderation === null);
      this.uneditableVideos = this.$props.adInfo.content_item.content_files.filter((item) => item.moderation !== null);
    },
  },

  mounted() {
    this.videos = this.$props.adInfo.content_item.content_files.filter((item) => item.moderation === null);
    this.uneditableVideos = this.$props.adInfo.content_item.content_files.filter((item) => item.moderation !== null);
  },

  methods: {
    handleCardClick(video) {
      if (video.moderation !== null) return;
      if (!this.selectedVideos.includes(video.id)) this.selectedVideos.push(video.id);
      else this.selectedVideos.splice(this.selectedVideos.indexOf(video.id), 1);
    },

    openModal() {
      this.moderationModalDisplay = true;
    },

    closeModal() {
      this.moderationModalDisplay = false;
    },

    async approveVideo() {
      this.approveLoading = true;
      try {
        await moderateContent(this.adInfo.id, {
          approved: [
            ...this.uneditableVideos.filter((video) => video.moderation?.approved).map((video) => video.id),
            ...this.selectedVideos,
          ],
          declined: {},
        });
        this.handleUpdateContent();
      } catch {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Ошибка при одобрении контента',
          status: 'error',
        });
      } finally {
        this.approveLoading = false;
      }
    },

    handleUpdateContent() {
      this.$emit('update-content');
      this.selectedVideos.splice(0, this.selectedVideos.length);
    },
  },
};
</script>

<style lang="scss">
.moderation {
  &__card {
    transition: box-shadow 100ms, transform 100ms;
    box-shadow: 0px 1px 4px 0px #00000014;

    &_active {
      &:active {
        transform: scale(0.98);
      }
    }
  }

  &__image {
    height: 278px;
  }

  &-check {
    &-leave-active,
    &-enter-active {
      transition: all 100ms;
    }

    &-enter,
    &-leave-to {
      transform: translate(60px, -60px);
    }
  }
}
</style>
