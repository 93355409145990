var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-hidden"},[_c('div',{staticClass:"half-circle-spinner",class:{
      blue: _vm.color === 'blue',
      black: _vm.color === 'black',
    }},[_c('div',{staticClass:"circle circle-1",class:{
        blue: _vm.color === 'blue',
        black: _vm.color === 'black',
      }})])])
}
var staticRenderFns = []

export { render, staticRenderFns }