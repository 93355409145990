<template>
  <div class="create-bill pb-10">
    <div class="create-bill__back w-max flex gap-3 items-center mb-4 cursor-pointer" @click="closeCreateBill">
      <img width="20" height="20" src="@/assets/images/views/profile/bill-arrow.svg" />
      <p class="text-gray text-sm leading-6">К истории операций</p>
    </div>
    <div class="create-bill__form rounded-4 bg-white">
      <div class="py-4 px-6 font-semibold text-gray">Выставить мне счет</div>
      <div class="h-px bg-gray-7"></div>
      <div class="px-6 pb-6 pt-4">
        <div class="px-4 py-2.5 rounded-4 text-gray-6 border border-blue-5 bg-blue-7 text-sm leading-6">
          Денежные средства будут зачислены на счёт в течение 1-3х дней. После пополнения вы получите письмо на
          электронную почту.
        </div>
        <p class="mt-4 text-sm leading-6 text-gray">Сумма пополнения, руб.</p>
        <UiInput v-model="form.amount" input-height="sm" placeholder="Сумма пополнения" class="mt-0.5" />
        <p class="mt-3 text-sm leading-6 text-gray">ИНН</p>
        <UiInput v-model="form.inn" input-height="sm" placeholder="ИНН" class="mt-0.5" />
        <p class="mt-3 text-sm leading-6 text-gray">Название организации</p>
        <UiInput v-model="form.name" disabled input-height="sm" placeholder="Название организации" class="mt-0.5" />
        <p class="mt-3 text-sm leading-6 text-gray">БИК Банка</p>
        <UiInput v-model="form.bik" input-height="sm" placeholder="БИК Банка" class="mt-0.5" />
        <p class="mt-3 text-sm leading-6 text-gray">КПП</p>
        <UiInput v-model="form.kpp" input-height="sm" disabled placeholder="КПП" class="mt-0.5" />
        <p class="mt-3 text-sm leading-6 text-gray">Р/сч</p>
        <UiInput v-model="form.bank_account" input-height="sm" placeholder="Расчётный счёт" class="mt-0.5" />
        <p class="mt-3 text-sm leading-6 text-gray">Телефон для связи</p>
        <UiInput v-model="form.phone" input-height="sm" disabled placeholder="Телефон" class="mt-0.5" />
        <div class="mt-5 flex items-center justify-between">
          <div class="flex gap-3 items-center">
            <UiCheckbox v-model="savePaymentData" />
            <p class="text-gray-6 text-sm leading-6">Сохранить данные для оплаты</p>
          </div>
          <UiButton :loading="loading" :disabled="!isButtonActive" button-height="sm" class="w-44" @click="createBill">
            Сформировать счет
          </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createInvoice } from '@/api';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiButton from '@/components/ui/UiButton.vue';

export default {
  name: 'CreateBill',

  components: { UiInput, UiCheckbox, UiButton },

  data() {
    return {
      form: {
        amount: '',
        inn: '',
        name: '',
        address: '',
        bik: '',
        bank: '',
        kpp: '',
        bank_account: '',
        phone: '',
      },

      savePaymentData: true,
      loading: false,
    };
  },

  computed: {
    organization() {
      if (!this.$store.state.auth.userInfo) return null;
      return this.$store.state.auth.userInfo.organization;
    },

    isButtonActive() {
      return Number(this.form.amount) > 0;
    },
  },

  watch: {
    organization() {
      this.fillForm();
    },
  },

  mounted() {
    this.fillForm();
  },

  methods: {
    fillForm() {
      if (!this.organization) return;
      Object.keys(this.organization.profile).forEach((key) => {
        this.form[key] = this.organization.profile[key] ? this.organization.profile[key] : '';
      });
    },

    closeCreateBill() {
      this.$emit('close-create-bill');
    },

    async createBill() {
      try {
        this.loading = true;
        await createInvoice({
          amount: Number(this.form.amount) * 100,
          ITN: this.form.inn,
          company_rs: this.form.bank_account,
        });
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Счет успешно создан',
        });
        this.$emit('update-records');
        this.closeCreateBill();
      } catch (err) {
        console.error(err);
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: err.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.create-bill {
  &__back {
    &:hover p {
      color: #13c2c2 !important;
    }
  }

  &__form {
    width: 516px;
  }
}
</style>
