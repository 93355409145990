/* eslint-disable */

function generateBalloonLayout(player) {
  const balloonLayout = ymaps.templateLayoutFactory.createClass(
    `<div style="width:224px; box-shadow: 0px 2px 8px 0px #00000026;" class="rounded-4 bg-white">
		<div class="h-10 p-2 flex items-center justify-between bg-gray-4">
			<div style="background: rgba(0, 0, 0, .65)" class="flex items-center gap-1.5 rounded-4 h-6 px-2">
				<img width="14" height="20" src="${require('@/assets/images/views/create-campaign/eye.svg')}" />
				<p class="text-white text-xs leading-4">2057</p>
			</div>
			<div style="background: rgba(0, 0, 0, .65)" class="flex items-center justify-center rounded-4 h-6 px-2">
				<p class="text-white text-xs leading-4">9 900 руб./сут.</p>
			</div>
		</div>
		<div class="p-2 bg-white">
			<p class="text-xs leading-4 text-gray">${player.name}</p>
			<p class="mt-1.5 text-xs leading-4 text-gray-3">${player.description ? player.description : ''}</p>
			<div class="player-map__map-button_add balloon-layout__toggle_${player.id}">Добавить в заказ</div>
		</div>
	</div>`
  );

  return balloonLayout;
}

function generatePlacemarkLayout(player, isAdded = false) {
  const placemarkLayout = ymaps.templateLayoutFactory.createClass(
    `
		<svg style="z-index: 10000; cursor: pointer" width="32" height="40" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="player-map__placemark_${
      player.id
    }">
			<path d="M15.5 7.5C15.5 3.358 12.143 0 8 0C3.857 0 0.5 3.358 0.5 7.5C0.5 10.664 2.462 13.365 5.233 14.467L8 20L10.767 14.467C13.538 13.365 15.5 10.665 15.5 7.5ZM8 4.5C9.657 4.5 11 5.843 11 7.5C11 9.157 9.657 10.5 8 10.5C6.343 10.5 5 9.157 5 7.5C5 5.843 6.343 4.5 8 4.5Z" fill="${
        isAdded ? '#13C2C2' : '#F5222D'
      }"/>
		</svg>
		`
  );

  return placemarkLayout;
}

export { generateBalloonLayout, generatePlacemarkLayout };
