<template>
  <div class="my-account pb-10">
    <p class="text-2xl leading-8 font-semibold text-gray">Мой аккаунт</p>
    <div class="mt-4 my-account__content bg-white rounded-4 p-6 relative">
      <transition name="user-info">
        <div v-show="userInfoLoading" style="background: rgba(80, 80, 80, 0.26)" class="absolute inset-0"></div>
      </transition>
      <p class="font-semibold text-gray">Контактная информация</p>
      <p class="mt-4 text-sm leading-6">Электронная почта</p>
      <div class="my-account__email mt-1 flex items-center gap-3 cursor-pointer" @click="openChangeEmail">
        <p class="font-semibold text-sm leading-6">{{ userInfo.email }}</p>
        <img width="16" height="16" src="@/assets/images/views/profile/edit-mail.svg" class="cursor-pointer" />
      </div>
      <p class="mt-4 text-sm leading-6 text-gray">Имя <span class="text-gray-5">(не обязательно)</span></p>
      <UiInput v-model="form.first_name" input-height="sm" placeholder="Имя" class="mt-0.5" />
      <p class="mt-3 text-sm leading-6 text-gray">Фамилия <span class="text-gray-5">(не обязательно)</span></p>
      <UiInput v-model="form.last_name" input-height="sm" placeholder="Фамилия" class="mt-0.5" />
      <p class="mt-3 text-sm leading-6 text-gray">Номер телефона <span class="text-gray-5">(не обязательно)</span></p>
      <UiInput v-model="form.phone" input-height="sm" placeholder="Номер телефона" class="mt-0.5" />
      <UiButton style="min-width: 170px" button-height="sm" class="mt-4 px-4 w-max" @click="updateProfile">
        <span v-if="!profileLoading">Сохранить изменения</span>
        <LoadingSpinner v-else />
      </UiButton>
      <div class="mt-6 mb-4 h-px bg-gray-7"></div>
      <p class="font-semibold text-gray">Смена пароля</p>
      <p class="mt-2 text-sm leading-6 text-gray">Введите текущий пароль</p>
      <UiInput
        v-model="currentPassword"
        :input-type="currentPasswordVisible ? 'text' : 'password'"
        input-height="sm"
        placeholder="Текущий пароль"
        class="mt-0.5"
      >
        <img
          width="18"
          height="18"
          src="@/assets/images/views/profile/password.svg"
          class="cursor-pointer"
          @click="currentPasswordVisible = !currentPasswordVisible"
        />
      </UiInput>
      <p class="mt-3 text-sm leading-6 text-gray">Введите новый пароль</p>
      <UiInput
        v-model="newPassword"
        :input-type="newPasswordVisible ? 'text' : 'password'"
        input-height="sm"
        placeholder="Новый пароль"
        class="mt-0.5"
      >
        <img
          width="18"
          height="18"
          src="@/assets/images/views/profile/password.svg"
          class="cursor-pointer"
          @click="newPasswordVisible = !newPasswordVisible"
        />
      </UiInput>
      <UiButton style="min-width: 138px" button-height="sm" class="mt-4 px-4 w-max" @click="updatePassword">
        <span v-if="!passwordLoading">Обновить пароль</span>
        <LoadingSpinner v-else />
      </UiButton>
    </div>
    <ChangeEmail v-show="isChangeEmailVisible" @close="handleChangeEmailClose" />
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { updateProfile, updatePassword } from '@/api';

import UiInput from '@/components/ui/UiInput.vue';
import UiButton from '@/components/ui/UiButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import ChangeEmail from '@/components/views/Profile/ChangeEmail.vue';

export default {
  name: 'MyAccount',

  components: { UiInput, UiButton, ChangeEmail, LoadingSpinner },

  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        phone: '',
      },
      profileLoading: false,

      currentPassword: '',
      newPassword: '',
      currentPasswordVisible: false,
      newPasswordVisible: false,
      passwordLoading: false,

      isChangeEmailVisible: false,

      userInfoLoading: false,
    };
  },

  computed: {
    userInfo() {
      return this.$store.state.auth.userInfo;
    },
  },

  watch: {
    userInfo() {
      this.fitFormFields();
    },
  },

  mounted() {
    this.fitFormFields();
  },

  methods: {
    fitFormFields() {
      if (!this.userInfo) return;
      this.form.first_name = this.userInfo.profile.first_name;
      this.form.last_name = this.userInfo.profile.last_name;
      this.form.phone = this.userInfo.profile.phone;
    },

    handleChangeEmailClose() {
      const el = document.querySelector('#changeEmail');
      enableBodyScroll(el);
      this.isChangeEmailVisible = false;
    },

    openChangeEmail() {
      this.isChangeEmailVisible = true;
      const el = document.querySelector('#changeEmail');
      disableBodyScroll(el);
    },

    async updatePassword() {
      const isFormValid = this.checkPasswordDataValid();
      if (!isFormValid) return;

      this.passwordLoading = true;
      try {
        await updatePassword({ current_password: this.currentPassword, password: this.newPassword });
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Пароль успешно обновлён',
        });
      } catch (err) {
        console.error('UpdatePassword Error');
        console.error(err);
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Ошибка при изменении пароля',
          status: 'error',
        });
      } finally {
        this.passwordLoading = false;
      }
    },

    async updateProfile() {
      this.profileLoading = true;
      try {
        await updateProfile({ email: this.$store.state.auth.userInfo.email, profile: this.form });
        this.profileLoading = false;
        this.userInfoLoading = true;
        await this.$store.dispatch('getUserInfo');
        this.userInfoLoading = false;
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Данные успешно обновлены',
        });
      } catch (err) {
        console.error('UpdateProfile Error');
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Введены некорректные данные пользователя',
          status: 'error',
        });
      } finally {
        this.profileLoading = false;
      }
    },

    checkPasswordDataValid() {
      if (!this.currentPassword) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Для изменения пароля необходимо ввести текущий пароль',
          status: 'warn',
        });
        return false;
      }
      if (this.newPassword.length < 6) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Длина нового пароля должна быть не менее 6 символов',
          status: 'warn',
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.my-account {
  &__content {
    width: 471px;
  }

  &__email:hover {
    > * {
      color: #13c2c2;
    }
  }
}

.user-info {
  &-leave-active,
  &-enter-active {
    transition: opacity 0.4s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
