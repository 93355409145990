<template>
  <div class="time-target__wrapper bg-white p-4 px-4 pt-6 pb-4 z-9" @click.stop>
    <p class="text-2xl leading-8 font-semibold text-gray">Временной таргет</p>
    <div class="mt-4">
      <div class="flex items-center">
        <div
          class="time-target__day-wrapper cursor-pointer text-sm h-8 border-t border-l border-gray-7 text-red-1 flex justify-center items-center"
        ></div>
        <div
          v-for="hour in hours"
          :key="hour"
          class="h-8 w-8 border-t border-l border-gray-7 flex justify-center items-center text-gray text-sm cursor-pointer"
          :class="{ 'border-r': hour === 23 }"
        >
          <!-- eslint-disable-next-line -->
          {{ hour < 10 ? '0' + hour : hour }}
        </div>
      </div>
      <div v-for="(day, index) in days" :key="day.title" class="flex items-center">
        <div
          class="time-target__day-wrapper cursor-pointer text-sm h-8 border-t border-l border-gray-7 text-gray flex items-center px-3"
          :class="{ 'border-b': index === 6 }"
        >
          {{ day.title }}
        </div>
        <div
          v-for="hour in hours"
          :key="hour"
          class="h-8 w-8 border-t border-l border-gray-7 flex justify-center items-center text-gray text-sm"
          :class="{ 'border-b': index === 6, 'border-r': hour === 23 }"
        >
          <div
            class="w-5 h-5 rounded-3 cursor-pointer"
            :class="
              daysTime[day.key]?.includes(hour)
                ? 'bg-blue'
                : `${day.key === 'saturday' || day.key === 'sunday' ? 'bg-gray-2' : 'bg-gray-4'}`
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTimeTarget',
  props: {
    daysProp: {
      type: Object,
      default: () => ({
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      }),
    },
  },

  data() {
    return {
      days: [
        { key: 1, title: 'Понедельник' },
        { key: 2, title: 'Вторник' },
        { key: 3, title: 'Среда' },
        { key: 4, title: 'Четверг' },
        { key: 5, title: 'Пятница' },
        { key: 6, title: 'Суббота' },
        { key: 7, title: 'Воскресенье' },
      ],
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],

      daysTime: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
    };
  },

  watch: {
    daysProp: {
      deep: true,
      handler() {
        this.handleProp();
      },
    },
  },

  mounted() {
    this.handleProp();
  },

  methods: {
    handleProp() {
      this.daysTime = Object.keys(this.daysProp).length === 0 ? this.daysTime : { ...this.daysProp };
    },
  },
};
</script>

<style lang="scss">
.time-target {
  background: rgba(0, 0, 0, 0.65);

  &__wrapper {
    box-shadow: 0px 4px 12px 0px #00000026;
  }

  &__day-wrapper {
    width: 120px;
  }
}
</style>
