<template>
  <div class="bg-gray p-6">
    <div class="flex gap-8 items-center">
      <p v-for="link in firstLinks" :key="link.title" class="text-gray-5 text-xl leading-7 cursor-pointer">
        {{ link.title }}
      </p>
    </div>
    <div class="mt-4 flex gap-6 items-center">
      <p v-for="link in secondLinks" :key="link.title" class="text-gray-5 leading-6 text-sm">
        {{ link.title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      firstLinks: [
        {
          title: 'Подробнее о сервисе',
          link: '',
        },
        {
          title: 'Обучение',
          link: '',
        },
        {
          title: 'Помощь',
          link: '',
        },
      ],
      secondLinks: [
        {
          title: 'Идеи для улучшений',
          link: '',
        },
        {
          title: 'Регламент использования',
          link: '',
        },
        {
          title: 'Правила работы сервиса',
          link: '',
        },
        {
          title: 'Пользовательское соглашение',
          link: '',
        },
        {
          title: 'Контактная информация',
          link: '',
        },
      ],
    };
  },
};
</script>
