<template>
  <div id="sitePlug" style="background: rgba(80, 80, 80, 0.26)" class="fixed top-0 left-0 w-full h-full z-30">
    <div class="flex w-full h-full justify-center items-center">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/ui/LoadingSpinner';

export default {
  name: 'UiPlug',
  components: { LoadingSpinner },
};
</script>
