<template>
  <div class="campaigns-grid mx-auto bg-white relative">
    <!-- <transition name="campaigns-plug">
      <div
        v-show="loading"
        style="background: rgba(0, 0, 0, 0.56); z-index: 5; width: 1548px"
        class="absolute top-0 left-0 w-fulls h-full"
      ></div>
    </transition> -->
    <div class="bg-gray-8 campaigns-grid__row grid border-y border-gray-7 text-sm text-gray leading-5">
      <div class="flex justify-center items-center">
        <!-- <UiCheckbox v-model="isAllChecked" @click="checkAll" /> -->
      </div>
      <FilterIcon title="Название РК" sort="name" :currentSort="currentSort" @filter-change="handleFilterChange" />
      <div class="flex items-center justify-between pl-2 relative">
        <div class="flex items-center gap-2">
          <p class="font-semibold">Статус</p>
        </div>
      </div>
      <FilterIcon sort="budget" :currentSort="currentSort" title="Макс. бюджет" @filter-change="handleFilterChange" />
      <FilterIcon sort="start" :currentSort="currentSort" title="Даты показа" @filter-change="handleFilterChange" />
      <FilterIcon
        sort="players"
        :currentSort="currentSort"
        title="Кол-во экранов"
        @filter-change="handleFilterChange"
      />
      <FilterIcon sort="ots" :currentSort="currentSort" title="Охват" @filter-change="handleFilterChange" />
      <FilterIcon sort="views" :currentSort="currentSort" title="Кол-во показов" @filter-change="handleFilterChange" />
      <div class="flex items-center justify-between px-2">
        <div class="flex items-center gap-2">
          <p class="font-semibold">Владелец</p>
        </div>
      </div>
      <div
        class="flex items-center justify-center h-full w-12 bg-gray-8 campaigns-grid__sticky-block border-y box-content border-gray-7"
      >
        <div style="height: 737px" class="absolute w-12 z-1 top-0 left-0 bg-white campaigns__navbar"></div>
        <div class="z-2 w-12 top-0 left-0 h-full absolute bg-gray-8"></div>
      </div>
    </div>
    <div
      v-for="(campaign, index) in campaigns"
      :key="campaign.id"
      class="bg-white campaigns-grid__row grid border-y border-gray-7 text-sm text-gray-6 leading-5"
    >
      <div class="flex justify-center items-center">
        <!-- <UiCheckbox v-model="campaign.isChoosed" /> -->
      </div>
      <router-link
        v-if="campaign.state !== 'deleted'"
        :to="`/advertising-campaigns/${campaign.id}`"
        class="flex items-center px-2 cursor-pointer hover:text-blue active:text-blue-hover"
      >
        <p class="whitespace-nowrap w-full overflow-hidden overflow-ellipsis">
          {{ campaign.name }}
        </p>
      </router-link>
      <div v-else class="flex items-center px-2">
        <p class="whitespace-nowrap w-full overflow-hidden overflow-ellipsis">
          {{ campaign.name }}
        </p>
      </div>
      <div v-if="isAdvertiser" class="flex items-center px-2 gap-2">
        <div class="w-2 h-2 rounded-full" :class="defineCircleClasses(campaign)"></div>
        <p>{{ defineStatusText(campaign) }}</p>
      </div>
      <div v-else class="flex items-center px-2 gap-2">
        <div
          class="w-2 h-2 rounded-full"
          :class="{
            'bg-green-2': campaign.contextual_state === 'running',
            'bg-red-1': campaign.contextual_state === 'declined',
            'bg-blue': campaign.contextual_state === 'pending',
            'bg-yellow': campaign.contextual_state === 'partial_running',
            'bg-gray-5': campaign.contextual_state === 'stopped',
            'bg-blue-4': campaign.contextual_state === 'paused',
          }"
        ></div>
        <p v-if="campaign.contextual_state === 'running'">Активна</p>
        <p v-else-if="campaign.contextual_state === 'partial_running'">Частично активна</p>
        <p v-else-if="campaign.contextual_state === 'declined'">Отклонена</p>
        <p v-else-if="campaign.contextual_state === 'pending'">Новая</p>
        <p v-else-if="campaign.contextual_state === 'stopped'">Остановлена</p>
        <p v-else-if="campaign.contextual_state === 'paused'">На паузе</p>
        <p v-else>Активна</p>
      </div>
      <div class="flex items-center px-2">
        <span v-if="campaign.budget">{{ campaign.analytics.cost }} / {{ campaign.budget }}</span>
      </div>
      <div class="flex items-center px-2 justify-between relative" tabindex="0" @blur="campaign.timeDisplay = false">
        <p>{{ formatDate(campaign.start) }}<span v-if="campaign.end"> - </span>{{ formatDate(campaign.end) }}</p>
        <img
          width="16"
          height="16"
          src="@/assets/images/views/advertising-campaigns/calendar.svg"
          class="cursor-pointer"
          :class="{ 'campaigns-grid__calendar_not-focused': !campaign.timeDisplay }"
          @click="campaign.timeDisplay = true"
        />
        <UiTimeTarget
          style="top: -334px"
          v-if="campaign.weekdays_hours"
          v-show="campaign.timeDisplay"
          :days-prop="campaign.weekdays_hours"
          class="absolute -left-96"
          :class="{ 'top-8': index < 7 }"
        />
      </div>
      <div class="flex items-center px-2">{{ campaign.whitelist_player_ids.length }}</div>
      <div class="flex items-center pl-2 pr-6 justify-between">
        <p>
          <span>{{ campaign.analytics ? campaign.analytics.views : 0 }}</span>
          <span v-if="campaign.ots">/ {{ campaign.ots }}</span>
        </p>
        <div class="flex items-end gap-0.5">
          <div class="w-1 h-2 bg-red-2"></div>
          <div class="w-1 h-3" :class="campaign.analytics.views / campaign.ots > 0.3 ? 'bg-orange' : 'bg-gray-7'"></div>
          <div class="w-1 h-4" :class="campaign.analytics.views / campaign.ots > 0.6 ? 'bg-green' : 'bg-gray-7'"></div>
          <div class="w-1 h-5" :class="campaign.analytics.views / campaign.ots > 0.9 ? 'bg-blue' : 'bg-gray-7'"></div>
        </div>
      </div>
      <div class="flex items-center px-2">{{ campaign.analytics.count }}</div>
      <div class="flex items-center justify-between px-2 relative">
        <p>{{ campaign.created_by.profile.first_name + ' ' + campaign.created_by.profile.last_name }}</p>
        <div class="campaigns-grid__access-wrapper">
          <img
            width="19"
            height="19"
            src="@/assets/images/views/advertising-campaigns/access-icon.svg"
            class="mr-2.5 cursor-pointer campaigns-grid__access-icon"
          />
          <div
            v-if="isUsersReceived"
            class="campaigns-grid__access-modal absolute right-4 p-4 bg-white z-2 rounded-4 items-start gap-2"
            :class="index > 7 ? '-top-16' : 'top-10'"
          >
            <img
              width="14"
              height="14"
              src="@/assets/images/views/advertising-campaigns/warning-icon.svg"
              class="mt-1"
            />
            <div
              style="min-width: 240px"
              v-if="campaign.managers?.length || campaign.managers_readonly?.length"
              class="text-sm w-max pr-10"
            >
              <p v-if="campaign.managers?.length" class="text-gray-6">Полный доступ</p>
              <p v-for="managerId in campaign.managers" :key="managerId" class="text-blue whitespace-nowrap">
                {{
                  users.find((user) => user.id === managerId)
                    ? users.find((user) => user.id === managerId).profile.first_name +
                      ' ' +
                      users.find((user) => user.id === managerId).profile.last_name
                    : ''
                }}
              </p>
              <p v-if="campaign.managers_readonly?.length" class="text-gray-6">Только просмотр</p>
              <p v-for="managerId in campaign.managers_readonly" :key="managerId" class="text-blue whitespace-nowrap">
                {{
                  users.find((user) => user.id === managerId)
                    ? users.find((user) => user.id === managerId).profile.first_name +
                      ' ' +
                      users.find((user) => user.id === managerId).profile.last_name
                    : ''
                }}
              </p>
            </div>
            <div style="min-width: 180px" v-else class="text-gray-6">Кампания не делегирована менеджерам</div>
            <div class="h-3 absolute w-full left-0" :class="index > 8 ? '-bottom-3' : '-top-3'"></div>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center h-full w-12 bg-white m-0 campaigns-grid__sticky-block border-y box-content border-gray-7"
        :class="campaign.isMenuOpen ? 'z-10' : 'z-2'"
        tabindex="0"
        @blur="campaign.isMenuOpen = false"
      >
        <UiButton
          color-type="none"
          button-height="xs"
          class="w-6 bg-white border"
          :class="
            campaign.isMenuOpen
              ? 'ui-button__outline_open border-blue-hover'
              : 'ui-button__outline border-gray-2 hover:border-blue-hover active:border-blue-active text-gray-6'
          "
          @click.native.stop="campaign.isMenuOpen = !campaign.isMenuOpen"
        >
          <img src="@/assets/images/views/advertising-campaigns/dots.svg" />
        </UiButton>
        <ContextMenu
          v-show="campaign.isMenuOpen"
          :campaign="campaign"
          :index="index"
          @close-menu="campaign.isMenuOpen = false"
          @update-campaigns="$emit('update-campaigns')"
        />
      </div>
    </div>
    <div class="campaigns-grid__scroll-divider absolute bottom-0 z-10x bg-gray-4"></div>
  </div>
</template>

<script>
const weekDays = {
  0: 'Вс',
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
};

import { getUsers } from '@/api';

// import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiTimeTarget from '@/components/ui/UiTimeTarget';
import UiButton from '@/components/ui/UiButton.vue';
import ContextMenu from '@/components/views/AdvertisingCampaigns/ContextMenu';
import FilterIcon from '@/components/views/AdvertisingCampaigns/FilterIcon.vue';

export default {
  name: 'CampaignsGrid',

  components: { UiButton, ContextMenu, FilterIcon, UiTimeTarget },

  props: {
    filteredCampaigns: {
      type: Array,
      default: () => [],
    },

    currentSort: {
      type: String,
      default: '',
    },

    currentCategory: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      campaigns: [],

      isAllChecked: false,

      filterStatuses: [],

      isAccessModalVisible: false,

      isDeleteModalVisible: false,

      users: [],
      isUsersReceived: false,
    };
  },

  watch: {
    filteredCampaigns: {
      deep: true,
      handler() {
        this.handleCampaignsChange();
      },
    },
  },

  computed: {
    isAdvertiser() {
      return this.$store.state.auth.organizationMode === 'advertiser';
    },
  },

  mounted() {
    this.getUsers();
    this.handleCampaignsChange();
  },

  methods: {
    checkAll() {
      let allChecked = true;
      this.campaigns.forEach((campaign) => {
        if (!campaign.isChoosed) allChecked = false;
      });
      this.isAllChecked = !allChecked;
      if (allChecked)
        this.campaigns.forEach((campaign) => {
          campaign.isChoosed = false;
        });
      else
        this.campaigns.forEach((campaign) => {
          campaign.isChoosed = true;
        });
    },

    formatDate(date) {
      if (!date) return '';
      const splitDate = date.split('-');
      return splitDate[2] + '.' + splitDate[1] + '.' + splitDate[0].slice(2) + ' ' + weekDays[new Date(date).getDay()];
    },

    handleFilterChange(value) {
      this.$emit('filter-change', value);
    },

    handleCampaignsChange() {
      this.campaigns = [...this.$props.filteredCampaigns].map((item) => ({
        ...item,
        isChoosed: false,
        isMenuOpen: false,
        timeDisplay: false,
      }));
    },

    async getUsers() {
      try {
        const res = await getUsers({ limit: 1000, offset: 0 });
        this.users = res.data.objects;
        this.isUsersReceived = true;
      } catch (err) {
        console.error(err);
      }
    },

    defineCircleClasses(campaign) {
      if (this.isAdvertiser) {
        if (this.currentCategory.key === 'deleted') return 'bg-blue-4';
        if (campaign.draft) return 'bg-gray';
        if (this.currentCategory.key === 'finished') return 'bg-gray-5';
        if (campaign.paused) return 'bg-orange';
        if (campaign.state === 'finished') return 'bg-gray-5';
        if (campaign.contextual_state === 'pending') return 'bg-blue';
        if (campaign.contextual_state === 'declined') return 'bg-red-1';
        if (campaign.state === 'deleted') return 'bg-blue-4';
        if (campaign.state === 'pending') return 'bg-yellow';

        return 'bg-green-2';
      }
    },

    defineStatusText(campaign) {
      if (this.isAdvertiser) {
        if (this.currentCategory.key === 'deleted') return 'В архиве';
        if (campaign.draft) return 'Черновик';
        if (this.currentCategory.key === 'finished') return 'Завершена';
        if (campaign.paused) return 'На паузе';
        if (campaign.state === 'finished') return 'Завершена';
        if (campaign.contextual_state === 'pending') return 'На модерации';
        if (campaign.contextual_state === 'declined') return 'Отклонена';
        if (campaign.state === 'deleted') return 'В архиве';
        if (campaign.state === 'pending') return 'В ожидании';
        return 'Активна';
      }
    },
  },
};
</script>

<style lang="scss">
.campaigns-grid {
  width: 1500px;
  height: 745px;

  &__row {
    height: 46px;
    grid-template-columns: 48px 220px 144px 180px 220px 144px 220px 144px 180px 48px;
    padding-right: 48px;
  }
  &__sticky-block {
    align-self: end;
    position: sticky;
    right: 0;
    top: 0;
    // box-shadow: 0px 2px 8px 0px #00000026;
  }
  &__filter-modal {
    box-shadow: 0px 2px 8px 0px #00000026;
  }
  &__access-modal {
    display: none;
    box-shadow: 0px 2px 8px 0px #00000026;
  }
  &__access-wrapper:hover .campaigns-grid__access-modal {
    display: flex;
  }

  &__scroll-divider {
    width: 1548px;
    height: 9px;
  }

  &__calendar_not-focused {
    filter: invert(87%) sepia(6%) saturate(1%) hue-rotate(314deg) brightness(88%) contrast(91%);
  }
}

.ui-button__outline_open {
  img {
    filter: invert(32%) sepia(16%) saturate(0%) hue-rotate(142deg) brightness(92%) contrast(87%);
  }
}
</style>
