import Vue from 'vue';
import VueRouter from 'vue-router';
import SignIn from '@/views/SignIn.vue';
import SignUp from '@/views/SignUp.vue';
import RecoverPassword from '@/views/RecoverPassword.vue';
import ConfirmPassword from '@/views/ConfirmPassword.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import AdvertisingCampaigns from '@/views/AdvertisingCampaigns';
import CreateCampaign from '@/views/CreateCampaign';
import CampaignCard from '@/views/CampaignCard';
import Profile from '@/views/Profile';
import auth from '@/store/auth';
import Cookies from 'js-cookie';

Vue.use(VueRouter);

const routes = [
  {
    path: '/sign-in',
    name: 'sing-in',
    component: SignIn,
  },
  {
    path: '/sign-up',
    name: 'sing-up',
    component: SignUp,
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: RecoverPassword,
  },
  {
    path: '/confirm-password',
    name: 'confirm-password',
    component: ConfirmPassword,
  },
  {
    path: '/advertising-campaigns',
    name: 'advertising-campaigns',
    component: AdvertisingCampaigns,
    meta: { keepAlive: true },
  },
  {
    path: '/advertising-campaigns/:id',
    name: 'campaign-card',
    component: CampaignCard,
  },
  {
    path: '/create-campaign',
    name: 'create-campaigns',
    component: CreateCampaign,
    beforeEnter: (to, from, next) => {
      if (auth.state.organizationMode === 'operator' || Cookies.get('oohvat_mode') === 'operator')
        next({ path: '/not-found', replace: true });
      else next();
    },
  },
  {
    path: '/edit-campaign/:id',
    name: 'edit-campaign',
    component: CreateCampaign,
    beforeEnter: (to, from, next) => {
      if (auth.state.organizationMode === 'operator' || Cookies.get('oohvat_mode') === 'operator')
        next({ path: '/not-found', replace: true });
      else next();
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
