<template>
  <div class="pt-6">
    <div class="main-container flex gap-6">
      <SideBar :tab-list="sidebarTabs" :current-tab="currentTab" @update-tab="handleUpdateTab" />
      <keep-alive>
        <component :is="currentComponent" class="flex-1" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/views/Profile/SideBar.vue';
import MyOrganization from '@/components/views/Profile/MyOrganization.vue';
import UserList from '@/components/views/Profile/UserList.vue';
import AccountInfo from '@/components/views/Profile/AccountInfo.vue';
import BillingList from '@/components/views/Profile/billing-list/BillingList.vue';

export default {
  name: 'Profile',

  components: { SideBar, MyOrganization, UserList, AccountInfo, BillingList },

  data() {
    return {
      defaultTab: {
        title: 'Моя организация',
        key: 'my-organization',
        component: 'MyOrganization',
      },

      currentTab: {
        title: 'Моя организация',
        key: 'my-organization',
        component: 'MyOrganization',
      },
    };
  },

  computed: {
    currentComponent() {
      return this.currentTab.component;
    },

    userInfo() {
      if (!this.$store.state.auth.userInfo) return {};
      return this.$store.state.auth.userInfo;
    },

    sidebarTabs() {
      if (this.userInfo.manager)
        return [
          {
            title: 'Моя организация',
            key: 'my-organization',
            component: 'MyOrganization',
          },
          {
            title: 'Мой аккаунт',
            key: 'account-info',
            component: 'AccountInfo',
          },
          {
            title: 'Биллинг',
            key: 'billing-list',
            component: 'BillingList',
          },
        ];

      if (this.$store.state.auth.organizationMode === 'operator')
        return [
          {
            title: 'Моя организация',
            key: 'my-organization',
            component: 'MyOrganization',
          },
          {
            title: 'Мой аккаунт',
            key: 'account-info',
            component: 'AccountInfo',
          },
          // {
          //   title: 'Биллинг',
          //   key: 'billing-list',
          //   component: 'BillingList',
          // },
        ];

      return [
        {
          title: 'Моя организация',
          key: 'my-organization',
          component: 'MyOrganization',
        },
        {
          title: 'Пользователи',
          key: 'user-list',
          component: 'UserList',
        },
        {
          title: 'Мой аккаунт',
          key: 'account-info',
          component: 'AccountInfo',
        },
        {
          title: 'Биллинг',
          key: 'billing-list',
          component: 'BillingList',
        },
      ];
    },
  },

  watch: {
    '$route.query.tab'() {
      if (this.$route.query.tab) this.currentTab = this.sidebarTabs.find((tab) => tab.key === this.$route.query.tab);
      if (this.$route.query.tab) this.$router.replace('/profile');
    },
  },

  mounted() {
    if (this.$route.query.tab)
      this.currentTab = this.sidebarTabs.find((tab) => tab.key === this.$route.query.tab)
        ? this.sidebarTabs.find((tab) => tab.key === this.$route.query.tab)
        : this.defaultTab;
    if (this.$route.query.tab) this.$router.replace('/profile');
  },

  methods: {
    handleUpdateTab(tab) {
      this.currentTab = tab;
    },
  },
};
</script>
