<template>
  <div
    class="ui-dropdown text-gray-6 cursor-pointer relative px-3 flex items-center gap-1.5 justify-between bg-white rounded-4 border hover:border-blue-hover"
    :class="[heightClass, { opened: isDropped }, isDropped ? 'border-blue-hover' : 'border-gray-2']"
    @click="isDropped = !isDropped"
    tabindex="0"
    @blur="isDropped = false"
  >
    <p class="max-w-full overflow-hidden whitespace-nowrap overflow-ellipsis">{{ value.title }}</p>
    <img
      :style="{ transform: `rotate(${isDropped ? 180 : 0}deg)` }"
      width="13.5"
      height="9"
      src="@/assets/images/ui/dropdown/arrow-down.svg"
    />
    <div
      v-show="isDropped"
      class="ui-dropdown__list absolute min-w-full z-2 top-10 left-0 text-gray-6 text-sm leading-5 rounded-4 bg-white"
    >
      <div
        v-for="variant in variantsList"
        :key="variant.value"
        class="px-3 py-1.5 flex items-center hover:bg-blue-2"
        :class="variant.value === value.value ? 'font-semibold bg-gray-4' : ''"
        @click="handleSelect(variant)"
      >
        {{ variant.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDropdown',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    variantsList: {
      type: Array,
      default: () => [],
    },

    dropdownHeight: {
      type: String,
      default: 'md',
    },
  },

  data() {
    return {
      isDropped: false,
    };
  },

  methods: {
    handleSelect(selectedVariant) {
      this.$emit('input', selectedVariant);
    },
  },
  computed: {
    heightClass() {
      if (this.dropdownHeight === 'sm') return 'h-8 text-sm leading-5';
      if (this.dropdownHeight === 'xs') return 'h-6 text-sm leading-5';
      return 'h-10 text-base leading-6';
    },
  },
};
</script>

<style lang="scss">
.ui-dropdown {
  &__list {
    box-shadow: 0px 2px 8px 0px #00000026;
  }
  &.opened {
    box-shadow: 0px 0px 4px 0px #1890ff80;
  }
}
</style>
