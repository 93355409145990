/* eslint-disable */
<template>
  <div class="main-container w-full player-map mt-3 flex items-start relative">
    <div class="flex-1 h-full relative">
      <transition name="map-plug">
        <div
          v-show="loading"
          style="background: rgba(80, 80, 80, 0.26); z-index: 11"
          class="absolute top-0 left-0 w-full h-full"
        >
          <div class="w-full h-full flex justify-center items-center">
            <LoadingSpinner />
          </div>
        </div>
      </transition>
      <div class="w-full h-10 absolute top-4 left-0 z-9 flex px-4 gap-3 items-center">
        <UiInput input-id="mapSearch" placeholder="Искать место (улица, район)" class="w-64 player-map__control">
          <img width="14" height="14" src="@/assets/images/views/create-campaign/search.svg" />
        </UiInput>
        <div
          class="bg-white rounded-4 flex items-center gap-1.5 h-10 px-4 player-map__control cursor-pointer"
          @click="!isDrawing ? createPolygon() : removePolygon()"
        >
          <img
            width="13"
            height="11"
            src="@/assets/images/views/create-campaign/lines-icon.svg"
            :class="isDrawing ? 'player-map__lines-icon_drawing' : 'player-map__lines-icon_not-drawing'"
          />
          <p :class="isDrawing ? 'text-blue' : 'text-gray-6'">Нарисовать линии</p>
        </div>
        <div class="player-map__control bg-white rounded-4 flex items-center h-10">
          <UiSelect style="width: 152px" v-model="venueCategoriesOutdoor" :last-checked="false" title="Outdoor" />
          <UiSelect style="width: 152px" v-model="venueCategories" :last-checked="false" title="Indoor" />
        </div>
        <div class="player-map__control player-map__duration bg-white rounded-4 flex items-center h-10 flex-1">
          <div class="flex-1 px-2 text-gray text-sm leading-5 relative">
            <div
              style="width: 240px"
              class="player-map__control rounded-4 player-map__duration-note absolute right-4 -top-16 h-12 px-4 bg-white items-center gap-2.5"
            >
              <img width="14" height="14" src="@/assets/images/views/create-campaign/warning-icon.svg" />
              <p class="text-gray-6 text-sm leading-5 shrink-0">Длительность ролика, сек.</p>
            </div>
            <span>Рол. сек.</span>
          </div>
          <div class="flex items-center h-full">
            <div
              v-for="(duration, index) in durationCategories"
              :key="duration"
              class="h-full w-10 flex justify-center items-center cursor-pointer"
              :class="[
                { 'rounded-l-4': index === 0, 'rounded-r-4': index === 2 },
                duration === currentDuration ? 'bg-blue text-white' : 'bg-white text-gray-6',
              ]"
              @click="handleDurationClick(duration)"
            >
              {{ duration }}
            </div>
          </div>
        </div>
      </div>
      <div class="player-map__control absolute top-20 right-4 w-9 rounded-4 bg-white z-9">
        <div class="w-full h-10 flex items-center justify-center cursor-pointer" @click="increaseZoom">
          <img src="@/assets/images/views/create-campaign/map-plus.svg" />
        </div>
        <div class="h-px mx-1.5 bg-gray-2"></div>
        <div class="w-full h-10 flex items-center justify-center cursor-pointer" @click="decreaseZoom">
          <img src="@/assets/images/views/create-campaign/minus.svg" />
        </div>
      </div>
      <div
        style="width: 176px; background: rgba(0, 0, 0, 0.65)"
        class="absolute rounded-4 z-9 bottom-7 left-0 h-8 right-0 mx-auto flex items-center justify-center text-white"
      >
        {{ `Экранов на карте: ${notIncludedPlayers.length}` }}
      </div>
      <div class="h-full w-full overflow-hidden">
        <div ref="mapWrapper" class="w-full h-full"></div>
      </div>
    </div>
    <div class="bg-white w-64 flex flex-col h-full">
      <div class="px-3 w-full border-b border-gray-7">
        <p class="mt-4 font-semibold text-gray">Название РК</p>
        <UiInput
          v-model="campaignName"
          :colorType="nameError ? 'error' : 'primary'"
          placeholder="Введите название РК"
          class="mt-2"
        />
        <p class="mt-4 font-semibold text-gray">Описание РК</p>
        <UiInput v-model="description" text-area colorType="primary" placeholder="Введите описание РК" class="mt-2" />
        <div class="flex items-center justify-between mt-4">
          <p
            v-for="type in playerTypes"
            :key="type.key"
            class="font-semibold cursor-pointer"
            :class="currentType.key === type.key ? 'text-blue' : 'text-gray'"
            @click="changePlayerType(type)"
          >
            {{ type.title }}
          </p>
        </div>
        <div class="flex items-center justify-between">
          <p :class="playerError ? 'text-red-1' : ' text-gray'">
            {{ currentType.key === 'added' ? savedPlayers.length : notIncludedPlayers.length }}
          </p>
          <div>
            <p
              v-if="currentType.key === 'added'"
              class="text-xs leading-4 text-gray-3 border-b border-red-3 border-dashed cursor-pointer"
              @click="clearAddedPlayers"
            >
              Очистить список
            </p>
            <p
              v-else
              class="text-xs leading-4 text-gray-3 border-b border-red-3 border-dashed cursor-pointer"
              @click="addAllPlayers"
            >
              Добавить все
            </p>
          </div>
        </div>
        <div class="my-2"></div>
        <!-- <UiButton button-height="sm" colorType="primary-text" class="mt-3 gap-1 mb-3">
          <img src="@/assets/images/views/create-campaign/plus.svg" />
          <span>Добавить город</span>
        </UiButton> -->
      </div>
      <div ref="playerList" class="px-4 w-full flex-1 overflow-auto player-map__player-list">
        <PlayerCard
          v-for="player in currentType.key === 'added' ? savedPlayers : notIncludedPlayers"
          :key="player.id"
          :player="player"
          :isAdded="addedPlayers.includes(player.id)"
          @addPlayer="addSpecificPlayer"
          @removePlayer="removeSpecificPlayer"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import { getCityCoords, getPlayers, getVenueCategories, getVenueCategoriesOutdoor } from '@/api';
import { generateBalloonLayout } from '@/ymaps';
import UiButton from '@/components/ui/UiButton';
import UiInput from '@/components/ui/UiInput';
import UiSelect from '@/components/ui/UiSelect';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import PlayerCard from '@/components/views/CreateCampaign/PlayerCard';

export default {
  name: 'PlayerMap',
  components: {
    UiButton,
    UiInput,
    UiSelect,
    PlayerCard,
    LoadingSpinner,
  },

  props: {
    editForm: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      campaignName: '',
      description: '',
      nameError: false,
      loading: false,

      placemarksCollection: null,
      cityCollection: null,

      playerTypes: [
        {
          title: 'Не добавлены',
          key: 'not-added',
        },
        {
          title: 'Добавлены',
          key: 'added',
        },
      ],

      currentType: {
        title: 'Не добавлены',
        key: 'not-added',
      },

      map: null,
      polygon: null,
      suggestView: '',
      searchedPlayers: [],
      objectManager: null,
      clusterer: null,
      placemarkBalloon: null,

      playersTimeout: null,

      adPlayers: [],
      playersInfo: null,
      playerError: false,

      center: [37.609218, 55.753559],

      openedPlayer: null,

      addedPlayers: [],
      savedPlayers: [],

      isDrawing: false,
      isSearching: false,

      venueCategories: [],
      venueCategoriesOutdoor: [],

      durationCategories: [5, 10, 15],
      currentDuration: 5,

      venueLoading: true,
      venueOutdoorLoading: true,
    };
  },

  computed: {
    isAllVenueCategoriesOutdoorChecked() {
      return (
        this.venueCategoriesOutdoor.filter((category) => category.value).length === this.venueCategoriesOutdoor.length
      );
    },

    isAllVenueCategoriesChecked() {
      return this.venueCategories.filter((category) => category.value).length === this.venueCategories.length;
    },

    editCampaignPlayers() {
      return this.adPlayers.filter((player) => this.addedPlayers.find((_player) => _player === player.id));
    },

    notIncludedPlayers() {
      return this.adPlayers
        .filter((player) => !this.addedPlayers.includes(player.id))
        .filter((player) => {
          let cityFilter = false;
          if (!this.cityCollection) {
            cityFilter = true;
          } else {
            this.cityCollection.each((item) => {
              if (item.geometry.contains([player.location[1], player.location[0]])) cityFilter = true;
            });
          }
          return cityFilter;
        });
    },
  },

  watch: {
    addedPlayers() {
      this.updatePlacemarksColors();
      this.savedPlayers = this.addedPlayers.map((id) =>
        this.savedPlayers.find((player) => player.id === id)
          ? this.savedPlayers.find((player) => player.id === id)
          : this.adPlayers.find((player) => player.id === id)
      );
      this.savedPlayers = _uniqBy(this.savedPlayers, (item) => item.id);

      if (this.addedPlayers.length > 0) this.playerError = false;
      this.$emit('update-value', { whitelist_player_ids: this.addedPlayers });
    },

    campaignName() {
      this.nameError = false;
      this.$emit('update-value', { name: this.campaignName });
    },

    description() {
      this.$emit('update-value', { description: this.description });
    },

    currentDuration() {
      this.$emit('update-value', { content_length: this.currentDuration });
    },

    venueCategories: {
      deep: true,
      handler() {
        if (this.venueLoading) {
          this.venueLoading = false;
          return;
        }
        this.addGeoObjects();
      },
    },

    venueCategoriesOutdoor: {
      deep: true,
      handler() {
        if (this.venueOutdoorLoading) {
          if (this.venueCategoriesOutdoor.length > 0 && this.venueCategories.length > 0) {
            this.venueOutdoorLoading = false;
          }
          return;
        }
        this.addGeoObjects();
      },
    },

    editForm: {
      deep: true,
      handler() {
        this.handleEditForm();
      },
    },
  },

  async mounted() {
    if (!window.ymaps) this.addYmapScript();
    else {
      ymaps.ready(this.createMap);
      this.getVenueCategories();
    }
  },

  methods: {
    addYmapScript() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src =
        'https://api-maps.yandex.ru/2.1/?apikey=d80b059f-69b0-414a-a916-d43844bab615&lang=ru_RU&coordorder=longlat';
      script.onload = () => {
        ymaps.ready(this.createMap);
        this.getVenueCategories();
      };
      const head = document.head;
      head.appendChild(script);
    },

    async addGeoObjects(updatePlayers = true) {
      if (this.isDrawing) this.loading = false;
      if (this.isDrawing || this.isSearching) return;
      if (updatePlayers) await this.getPlayers();
      if (this.placemarksCollection) {
        this.placemarksCollection.removeAll();
        this.map.geoObjects.remove(this.placemarksCollection);
      }

      this.clusterer = new ymaps.Clusterer({
        clusterIconLayout: 'default#pieChart',
        clusterDisableClickZoom: true,
        hasBalloon: false,
        maxZoom: 12,
      });
      this.map.geoObjects.add(this.clusterer);
      this.createPlacemarks();
    },

    async createMap() {
      this.map = new ymaps.Map(this.$refs.mapWrapper, {
        center: this.center,
        zoom: 12,
        controls: [],
        behaviors: ['drag', 'scrollZoom'],
      });

      await this.addGeoObjects();

      this.$store.commit('setPlugDisplay', false);

      this.suggestView = new ymaps.SuggestView('mapSearch', { results: 5, offset: [0, 10], width: 256 });
      this.suggestView.events.add('select', this.searchPlace);

      this.map.events.add('boundschange', () => {
        clearTimeout(this.playersTimeout);
        this.playersTimeout = setTimeout(async () => {
          this.addGeoObjects();
        }, 250);
      });
    },

    removeCityCollection() {
      this.cityCollection.removeAll();
      this.map.geoObjects.remove(this.cityCollection);
      this.cityCollection = null;
    },

    async searchPlace(event) {
      this.loading = true;
      this.isSearching = true;
      const place = event.get('item');
      const placeTitle = place.value.split(',')[place.value.split(',').length - 1];
      const boundsRes = await ymaps.geocode(place.value, { results: 1 });
      const bounds = boundsRes.geoObjects.get(0).properties._data.boundedBy;
      this.map.setBounds(bounds);

      const res = await getCityCoords({
        q: placeTitle,
        format: 'json',
        polygon_geojson: 1,
        limit: 1,
      });

      if (
        res.data[0].type !== 'city' &&
        res.data[0].type !== 'administrative' &&
        res.data[0].type !== 'town' &&
        res.data[0].type !== 'village'
      ) {
        this.loading = false;
        return;
      }

      if (this.cityCollection) this.removeCityCollection();

      const geojson = res.data[0].geojson;

      const cityCollection = new ymaps.GeoObjectCollection({}, {});

      if (geojson.type === 'MultiPolygon') {
        geojson.coordinates.forEach((shape) => {
          const polygon = new ymaps.Polygon(
            shape,
            {},
            {
              editorDrawingCursor: 'crosshair',
              editorMaxPoints: 30,
              fillColor: '#096DD9',
              fillOpacity: 0.26,
              strokeColor: '#096DD9',
              strokeWidth: 2,
              menuManager: () => {
                return [];
              },
            }
          );
          cityCollection.add(polygon);
        });
      } else if (geojson.type === 'Polygon') {
        const polygon = new ymaps.Polygon(
          geojson.coordinates,
          {},
          {
            editorDrawingCursor: 'crosshair',
            editorMaxPoints: 30,
            fillColor: '#096DD9',
            fillOpacity: 0.26,
            strokeColor: '#096DD9',
            strokeWidth: 2,
          }
        );
        cityCollection.add(polygon);
      }

      this.isSearching = false;
      this.map.geoObjects.add(cityCollection);
      this.cityCollection = cityCollection;
      await this.addGeoObjects();

      const playersAmount = this.adPlayers.filter((player) => {
        let cityFilter = false;
        if (!this.cityCollection) {
          cityFilter = true;
        } else {
          this.cityCollection.each((item) => {
            if (item.geometry.contains([player.location[1], player.location[0]])) cityFilter = true;
          });
        }
        return cityFilter;
      }).length;

      const balloonLayout = ymaps.templateLayoutFactory.createClass(
        `<div style="width: 243px; box-shadow: 0px 2px 8px 0px #00000026;" class="bg-white rounded-4 p-2">
						<p class="text-xs leading-4 text-gray font-medium">Экранов в области: ${playersAmount}</p>
            <div class="flex items-center justify-between w-full">
						<div class="player-map__city-button flex justify-center items-center rounded-4 cursor-pointer mt-2 px-2 w-max h-6 text-xs leading-4 bg-blue hover:bg-blue-hover active:bg-blue-active text-white">Добавить в заказ</div>
            <div class="player-map__city-close flex justify-center items-center rounded-4 cursor-pointer mt-2 px-2 w-max h-6 text-xs leading-4 bg-gray-4 border border-gray-2 text-red-1 hover:text-white hover:bg-red-2 hover:border-transparent active:text-white active:border-transparent active:bg-red-3">Закрыть область</div>
            </div>
				</div>`
      );

      cityCollection.options.set('balloonLayout', balloonLayout);

      cityCollection.get(0).balloon.open();

      cityCollection.events.add('balloonopen', () => {
        document.querySelector('.player-map__city-button').addEventListener('click', () => {
          this.addAllPlayers();
          this.removeCityCollection();
        });

        document.querySelector('.player-map__city-close').addEventListener('click', () => {
          this.removeCityCollection();
          this.updatePlacemarksColors();
        });
      });
    },

    async getPlayers() {
      this.loading = true;
      const bbox = this.generateBboxParam();

      const venue_category_outdoor = this.isAllVenueCategoriesOutdoorChecked
        ? []
        : this.venueCategoriesOutdoor.filter((category) => category.value).map((category) => category.id);

      const venue_category = this.isAllVenueCategoriesChecked
        ? []
        : this.venueCategories.filter((category) => category.value).map((category) => category.id);

      const params = {
        limit: 100,
        bbox,
        dooh_type: [],
        venue_category,
        venue_category_outdoor,
      };
      try {
        const response = await getPlayers(params);
        this.adPlayers = response.data.objects;
        this.playersInfo = response.data.meta;
      } catch (err) {
        console.error(['PlayerMap Error']);
        console.error(err);
      }
      this.loading = false;
    },

    updatePlacemarksColors() {
      if (!this.map) return;
      this.map.geoObjects.options.set({ date: new Date() });
      this.addGeoObjects(false);
    },

    changePlayerType(type) {
      this.currentType = type;
    },

    async getVenueCategories() {
      try {
        const [venueRes, venueOutdoorRes] = await Promise.all([getVenueCategories(), getVenueCategoriesOutdoor()]);
        this.venueCategories = venueRes.data.map((category) => ({
          name: category.value,
          value: true,
          id: category.id,
        }));

        this.venueCategoriesOutdoor = venueOutdoorRes.data.map((category) => ({
          name: category.value,
          value: true,
          id: category.id,
        }));
      } catch (err) {
        console.error('[Player Map Error]');
        console.error(err);
      }
    },

    createPlacemarks() {
      const placemarksCollection = new ymaps.GeoObjectCollection({}, {});

      this.adPlayers.forEach((item) => {
        const placemark = new ymaps.Placemark(
          [item.location[1], item.location[0]],
          { id: item.id, type: 'point' },
          {
            iconLayout: 'default#image',
            iconImageHref: `ymaps/${this.addedPlayers.includes(item.id) ? 'blue' : 'red'}-marker.svg`,
            iconColor: this.addedPlayers.includes(item.id) ? '#13C2C2' : '#F5222D',
            hideIconOnBalloonOpen: false,
            iconImageSize: [30, 40],
            balloonOffset: [-110, -165],
            balloonOpenTimeout: 0,
            balloonLayout: generateBalloonLayout(item),
          }
        );
        placemark.events.add('balloonopen', this.bindListener);
        placemark.events.add('optionschange', (event) => {
          const mark = event.get('target');
          const id = mark.properties.get('id');
          mark.options.set({
            iconColor: this.addedPlayers.includes(id) ? '#13C2C2' : '#F5222D',
            iconImageHref: require(`@/assets/images/views/create-campaign/${
              this.addedPlayers.includes(id) ? 'blue' : 'red'
            }-marker.svg`),
          });
        });

        const cityFilter = this.checkCityFilter(item);

        if (cityFilter) {
          placemarksCollection.add(placemark);
          this.clusterer.add(placemark);
        }
      });
      placemarksCollection.add(this.clusterer);
      this.map.geoObjects.add(placemarksCollection);
      this.placemarksCollection = placemarksCollection;
    },

    generateBboxParam() {
      const coords = this.map.getBounds();
      return coords[0][1] + ',' + coords[0][0] + ',' + coords[1][1] + ',' + coords[1][0];
    },

    checkCityFilter(player) {
      let check = false;
      if (!this.cityCollection) {
        check = true;
      } else {
        this.cityCollection.each((item) => {
          if (item.geometry.contains([player.location[1], player.location[0]])) check = true;
        });
      }
      return check;
    },

    increaseZoom() {
      this.map.setZoom(this.map.getZoom() + 1);
    },

    decreaseZoom() {
      this.map.setZoom(this.map.getZoom() - 1);
    },

    addSpecificPlayer(id) {
      this.addedPlayers.push(id);

      const balloon = document.querySelector(`.balloon-layout__toggle_${id}`);
      if (balloon) {
        balloon.innerHTML = 'Убрать из заказа';
        balloon.classList.toggle('player-map__map-button_add');
        balloon.classList.toggle('player-map__map-button_remove');
        balloon.addEventListener('click', this.removePlayer);
      }
    },

    removeSpecificPlayer(id) {
      this.addedPlayers.splice(this.addedPlayers.indexOf(id), 1);

      const balloon = document.querySelector(`.balloon-layout__toggle_${id}`);
      if (balloon) {
        balloon.innerHTML = 'Добавить в заказ';
        balloon.classList.toggle('player-map__map-button_add');
        balloon.classList.toggle('player-map__map-button_remove');
        balloon.addEventListener('click', this.addPlayer);
      }
    },

    createPolygon() {
      if (this.cityCollection) {
        this.removeCityCollection();
        this.updatePlacemarksColors();
      }
      this.isDrawing = true;
      const balloonLayout = ymaps.templateLayoutFactory.createClass(
        `<div style="width: 137px; box-shadow: 0px 2px 8px 0px #00000026;" class="bg-white rounded-4 p-2">
						<p class="text-xs leading-4 text-gray">Экранов в области: <span class="player-map__searched-players"></span></p>
						<div class="player-map__polygon-button flex justify-center items-center rounded-4 cursor-pointer mt-2 px-2 w-max h-6 text-xs leading-4 bg-blue hover:bg-blue-hover active:bg-blue-active text-white">Добавить в заказ</div>
				</div>`
      );
      this.polygon = new ymaps.Polygon(
        [],
        {},
        {
          editorDrawingCursor: 'crosshair',
          editorMaxPoints: 30,
          fillColor: '#EB423E',
          fillOpacity: 0.26,
          strokeColor: '#EB423E',
          strokeWidth: 5,
          balloonLayout,
          menuManager: () => {
            return [];
          },
        }
      );
      this.map.geoObjects.add(this.polygon);
      this.polygon.editor.startDrawing();
      this.polygon.editor.events.add('vertexadd', (event) => {
        if (event.get('target').geometry.getCoordinates()[0].length > 3) this.searchPlayers();
      });
      this.polygon.editor.events.add('vertexdragend', (event) => {
        if (event.get('target').geometry.getCoordinates()[0].length > 3) this.searchPlayers();
      });
      this.polygon.events.add('click', (event) => {
        event.preventDefault();
        this.polygon.editor.stopDrawing();
      });
      this.polygon.balloon.events.add('open', this.setSearchedPlayersLength);
    },

    searchPlayers() {
      this.searchedPlayers = this.adPlayers.filter((player) => {
        if (!this.polygon) return false;
        return this.polygon.geometry.contains([player.location[1], player.location[0]]);
      });
      this.polygon.balloon.open();
      if (!this.polygon.balloon.isOpen()) return;
      this.setSearchedPlayersLength();
    },

    setSearchedPlayersLength() {
      setTimeout(() => {
        document.querySelector('.player-map__searched-players').innerHTML = this.searchedPlayers.length;
        document.querySelector('.player-map__polygon-button').addEventListener('click', this.addSearchedPlayers);
      }, 0);
    },

    addSearchedPlayers() {
      this.addedPlayers.push(...this.searchedPlayers.map((player) => player.id));
      this.addedPlayers = _uniq(this.addedPlayers);
      this.removePolygon();
    },

    removePolygon() {
      this.map.geoObjects.remove(this.polygon);
      this.polygon = null;
      this.isDrawing = false;
    },

    clearAddedPlayers() {
      this.addedPlayers = [];
    },

    addAllPlayers() {
      this.notIncludedPlayers.forEach((player) => {
        this.addedPlayers.push(player.id);
      });
      this.addedPlayers = _uniq(this.addedPlayers);
    },

    bindListener(event) {
      this.openedPlayer = event.get('target').properties.get('id');
      if (this.addedPlayers.includes(this.openedPlayer)) {
        document.querySelector(`.balloon-layout__toggle_${this.openedPlayer}`).innerHTML = 'Убрать из заказа';
        document
          .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
          .classList.remove('player-map__map-button_add');
        document
          .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
          .classList.add('player-map__map-button_remove');
        document
          .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
          .addEventListener('click', this.removePlayer);
      } else {
        document.querySelector(`.balloon-layout__toggle_${this.openedPlayer}`).innerHTML = 'Добавить в заказ';
        document
          .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
          .classList.add('player-map__map-button_add');
        document
          .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
          .classList.remove('player-map__map-button_remove');
        document
          .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
          .addEventListener('click', this.addPlayer);
      }
    },

    addPlayer() {
      this.addedPlayers.push(this.openedPlayer);
      document
        .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
        .removeEventListener('click', this.addPlayer);
      document.querySelector(`.balloon-layout__toggle_${this.openedPlayer}`).innerHTML = 'Убрать из заказа';
      document
        .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
        .classList.toggle('player-map__map-button_add');
      document
        .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
        .classList.toggle('player-map__map-button_remove');
      document
        .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
        .addEventListener('click', this.removePlayer);
    },

    removePlayer() {
      this.addedPlayers.splice(this.addedPlayers.indexOf(this.openedPlayer), 1);
      document
        .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
        .removeEventListener('click', this.removePlayer);
      document.querySelector(`.balloon-layout__toggle_${this.openedPlayer}`).innerHTML = 'Добавить в заказ';
      document
        .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
        .classList.toggle('player-map__map-button_add');
      document
        .querySelector(`.balloon-layout__toggle_${this.openedPlayer}`)
        .classList.toggle('player-map__map-button_remove');
      document.querySelector(`.balloon-layout__toggle_${this.openedPlayer}`).addEventListener('click', this.addPlayer);
    },

    createPlayerError() {
      this.playerError = true;
    },

    createNameError() {
      this.nameError = true;
    },

    handleDurationClick(duration) {
      this.currentDuration = duration;
    },

    handleEditForm() {
      this.campaignName = this.editForm.name;
      this.description = this.editForm.description;
      this.addedPlayers = [...this.editForm.whitelist_player_ids];
      this.savedPlayers = [...this.editForm.savedPlayers];
    },
  },
};
</script>

<style lang="scss">
.player-map {
  height: 765px;
  &__player-list {
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
  &__control {
    box-shadow: 0px 2px 8px 0px #00000026;
  }
  &__lines-icon {
    &_drawing {
      filter: invert(78%) sepia(38%) saturate(6765%) hue-rotate(135deg) brightness(97%) contrast(85%);
    }
    &_not-drawing {
      filter: invert(38%) sepia(0%) saturate(0%) hue-rotate(48deg) brightness(90%) contrast(93%);
    }
  }
  &__tag {
    background: rgba(0, 0, 0, 0.65);
  }
  &__card {
    box-shadow: 0px 2px 8px 0px #00000026;
  }
  &__duration-note {
    display: none;
  }
  &__duration:hover .player-map__duration-note {
    display: flex;
  }

  &__map-button_add {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 8px;
    padding: 0 8px;
    width: max-content;
    height: 24px;
    font-size: 14px;
    line-height: 16px;
    background: #13c2c2;
    color: white;

    &:hover {
      background: #40a9ff;
    }

    &:active {
      background: #096dd9;
    }
  }

  &__map-button_remove {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    margin-top: 8px;
    padding: 0 8px;
    width: max-content;
    height: 24px;
    font-size: 14px;
    line-height: 16px;
    background: #f5f5f5;
    color: #f5222d;

    &:hover {
      background: #ff4d4f;
      color: white;
      border: 1px solid transparent;
    }

    &:active {
      color: white;
      border: 1px solid transparent;
      background: #cf1322;
    }
  }
}
ymaps[class*='vertex-menu'] {
  display: none;
}

.map-plug {
  &-leave-active,
  &-enter-active {
    transition: all 0.5s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
