<template>
  <div>
    <div class="bg-white">
      <div class="bg-gray-8 campaign-status__row grid border-y border-gray-7 text-sm text-gray leading-5">
        <div class="flex items-center justify-between px-4">
          <div class="flex items-center gap-2">
            <p class="font-semibold">Оператор</p>
          </div>
        </div>
        <div class="flex items-center justify-between pl-4 relative z-6" :class="{ 'bg-gray-7': isFilterVisible }">
          <div class="flex items-center gap-2">
            <p class="font-semibold">Статус</p>
          </div>
          <div
            class="h-full w-6 flex justify-center items-center hover:bg-gray-9"
            :class="{ 'bg-gray-9': isFilterVisible }"
            @click.stop="isFilterVisible = !isFilterVisible"
            tabindex="0"
            @blur="isFilterVisible = false"
          >
            <img src="@/assets/images/views/advertising-campaigns/filter.svg" />
            <div
              v-show="isFilterVisible"
              class="w-full absolute top-12 left-0 campaigns-grid__filter-modal bg-white rounded-4"
              @click.stop
            >
              <div class="p-2 border-b border-gray-7">
                <div v-for="status in filterValues" :key="status.key" class="flex h-8 items-center gap-2">
                  <UiCheckbox v-model="status.value" />
                  <p class="text-gray-6 text-sm font-base">{{ status.title }}</p>
                </div>
              </div>
              <div class="p-2 flex items-center justify-between">
                <p class="text-sm text-blue" @click="isFilterVisible = false">ОК</p>
                <p class="text-sm text-blue" @click="resetFilterValues">Сбросить</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between px-4">
          <div class="flex items-center gap-2">
            <p class="font-semibold">Комментарий</p>
          </div>
        </div>
        <div class="flex items-center justify-between px-4">
          <div class="flex items-center gap-2">
            <p class="font-semibold">Кол. роликов</p>
            <!-- <img src="@/assets/images/views/advertising-campaigns/arrows.svg" /> -->
          </div>
        </div>
        <div class="flex items-center justify-between px-4">
          <p class="font-semibold">Дата</p>
        </div>
      </div>
      <div
        class="campaign-status__records-container relative"
        :class="{ 'min-h-0': filteredHistoryRecords.length === 0 && !loading }"
      >
        <transition name="campaigns-plug">
          <div v-show="loading" style="background: rgba(80, 80, 80, 0.26); z-index: 5" class="absolute inset-0">
            <div class="h-full flex justify-center items-center">
              <LoadingSpinner />
            </div>
          </div>
        </transition>
        <div
          v-if="filteredHistoryRecords.length === 0 && !loading"
          class="leading-10 text-2xl leading-8 font-medium text-gray-3 flex justify-center items-center py-64"
        >
          Отсутствуют записи в истории модерации
        </div>
        <div
          v-for="record in filteredHistoryRecords"
          :key="record.date.toISOString()"
          class="bg-white campaign-status__row grid border-y border-gray-7 text-sm text-gray-6 leading-5"
        >
          <div class="leading-4 px-4 flex items-center h-full">
            {{ record.operator ? record.operator : 'Не назначен' }}
          </div>
          <div class="leading-4 px-2 flex items-center h-full gap-2">
            <div
              class="w-2 h-2 rounded-full"
              :class="{
                'bg-green-2': record.state === 'running',
                'bg-blue-5': record.state === 'approve-video',
                'bg-red-1': record.state === 'decline-video',
                'bg-yellow': record.state === 'paused',
              }"
            ></div>
            <p>{{ record.status }}</p>
          </div>
          <div class="px-4 leading-5 py-3">
            <span v-if="record.state === 'running'">
              {{ `Рекламная кампания запущена.` }}
            </span>
            <span v-else-if="record.state === 'paused'">
              {{ `Рекламная кампания поставлена на паузу оператором ${record.operator}.` }}
            </span>
            <span v-else-if="record.state === 'stopped'">
              {{ `Рекламная кампания остановлена оператором ${record.operator}.` }}
            </span>
            <span v-else-if="record.state === 'approve-video'">
              {{
                `${record.files.length > 1 ? 'Одобрены ролики' : 'Одобрен ролик'} рекламной кампании с ${
                  record.files.length > 1 ? 'разрешениями' : 'разрешением'
                }`
              }}
              <span v-for="(file, index) in record.files" :key="file.id">{{
                `${file.data.width}x${file.data.height} PX${index === record.files.length - 1 ? '' : ','}`
              }}</span>
              {{ `оператором ${record.operator}.` }}
            </span>
            <span v-else-if="record.state === 'decline-video'">
              {{
                `${record.files.length > 1 ? 'Отклонены ролики' : 'Отклонён ролик'} рекламной кампании оператором
               ${record.operator} ${record.files.length > 1 ? 'со следующими разрешениями:' : 'c разрешением:'} `
              }}<br />
              <span v-for="(file, index) in record.files" :key="file.id">
                {{ `${file.data.width}x${file.data.height} PX. Причина - ${file.reason}.` }}
                <br v-if="index !== record.files.length - 1" />
              </span>
            </span>
          </div>
          <div class="px-4 leading-5 flex items-center">
            {{ adInfo.content_item?.content_files?.length }}/{{ adInfo.content_item?.content_files?.length }}
          </div>
          <div class="cursor-pointer px-4 leading-5 flex items-center text-center">
            {{ formatDate(record.date) }}
          </div>
        </div>
      </div>
    </div>
    <div class="hidden flex justify-end mt-6">
      <UiPagination :current-page="currentPage" :pages-amount="5" @changePage="currentPage = $event" />
    </div>
  </div>
</template>

<script>
const weekDays = {
  0: 'Вс',
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
};

import { getModerationHistory } from '@/api';

import UiCheckbox from '@/components/ui/UiCheckbox';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import UiPagination from '@/components/ui/UiPagination';

export default {
  name: 'CampaignStatus',

  components: { UiCheckbox, LoadingSpinner, UiPagination },

  props: {
    adInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      currentPage: 1,
      loading: false,

      weekDays,

      recordsPerPage: 10,

      moderationHistory: [],
      historyRecords: [],

      isFilterVisible: false,
      filterValues: [
        {
          key: 'running',
          value: true,
          title: 'Активна',
        },
        {
          key: 'paused',
          value: true,
          title: 'На паузе',
        },
        {
          key: 'stopped',
          value: true,
          title: 'Остановлена',
        },
        {
          key: 'approve-video',
          value: true,
          title: 'Одобрено',
        },
        {
          key: 'decline-video',
          value: true,
          title: 'Отклонено',
        },
      ],
    };
  },

  computed: {
    contentFiles() {
      if (!this.adInfo.content_item) return [];
      return this.adInfo.content_item.content_files;
    },

    enabledFilterValues() {
      return this.filterValues.filter((value) => value.value).map((value) => value.key);
    },

    filteredHistoryRecords() {
      return [...this.historyRecords].reverse().filter((item) => this.enabledFilterValues.includes(item.state));
    },
  },

  mounted() {
    this.getModerationHistory();
  },

  methods: {
    async getModerationHistory() {
      this.loading = true;
      try {
        const res = await getModerationHistory(this.$route.params.id);
        this.moderationHistory = res.data;
        this.createHistoryRecords(this.moderationHistory);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    createHistoryRecords(historyData) {
      const records = [];
      const approvedVideos = [];
      const declinedVideos = [];

      if (historyData.length === 0) this.historyRecords = [];

      historyData.forEach((item, index) => {
        if (index === 0 && item.approved.length === 0 && Object.keys(item.declined).length === 0) {
          records.push({
            state: 'running',
            status: 'Запущена',
            operator: null,
            date: new Date(item.datetime),
          });
        } else if (
          (item.approved.length > approvedVideos.length || Object.keys(item.declined).length > declinedVideos.length) &&
          approvedVideos.length + declinedVideos.length < this.contentFiles.length
        ) {
          if (item.approved.length > approvedVideos.length) {
            const addedVideoIDs = item.approved.filter((id) => !approvedVideos.includes(id));
            const addedFiles = addedVideoIDs.map((id) => this.contentFiles.find((file) => file.id === id));

            addedVideoIDs.forEach((id) => {
              approvedVideos.push(id);
            });

            records.push({
              state: 'approve-video',
              status: 'Одобрено',
              operator: item.operator ? item.operator[0].name : 'Не указан',
              date: new Date(item.datetime),
              files: addedFiles,
              amount: approvedVideos.length + declinedVideos.length,
            });
          } else if (Object.keys(item.declined).length > declinedVideos.length) {
            const declinedVideoIDs = Object.keys(item.declined)
              .filter((key) => !declinedVideos.includes(Number(key)))
              .map((key) => Number(key));
            const declinedFiles = declinedVideoIDs.map((id) => ({
              ...this.contentFiles.find((file) => file.id === id),
              reason: item.declined[id],
            }));

            declinedVideoIDs.forEach((id) => {
              declinedVideos.push(id);
            });

            records.push({
              state: 'decline-video',
              status: 'Отклонено',
              operator: item.operator ? item.operator[0].name : 'Не указан',
              date: new Date(item.datetime),
              files: declinedFiles,
              amount: approvedVideos.length + declinedVideos.length,
            });
          }
        } else if (
          item.approved.length === approvedVideos.length &&
          Object.keys(item.declined).length === declinedVideos.length
        ) {
          if (records.length && item.state === records[records.length - 1].state) return;
          records.push({
            state: item.state,
            status: this.getRecordStatus(item.state),
            operator: item.operator ? item.operator[0].name : 'Не указан',
            date: new Date(item.datetime),
            amount: approvedVideos.length + declinedVideos.length,
          });
        }
      });

      this.historyRecords = records;
    },

    getRecordStatus(state) {
      if (state === 'stopped') return 'Остановлена';
      if (state === 'paused') return 'На паузе';
      return 'Активна';
    },

    formatDate(date) {
      if (!date) return '';
      return `${
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        '.' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '.' +
        date.getFullYear() +
        ' ' +
        weekDays[date.getDay()]
      }, ${
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':' +
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      }`;
    },

    resetFilterValues() {
      this.filterValues.forEach((item) => {
        item.value = true;
      });
      this.isFilterVisible = false;
    },
  },
};
</script>

<style lang="scss">
.campaign-status {
  &__row {
    min-height: 46px;
    grid-template-columns: 220px 164px 544px 132px 172px;
  }

  &__records-container {
    min-height: 700px;
  }
}
</style>
