<template>
  <div style="background: rgba(0, 0, 0, 0.65)" class="fixed inset-0 text-gray" @click="closeModal">
    <div class="flex h-full items-center justify-center">
      <div id="addUser" class="add-user__content bg-white rounded-4" @click.stop>
        <div class="flex items-center justify-between px-6 py-4">
          <p class="font-semibold">Добавить пользователя</p>
          <img
            width="18"
            height="18"
            src="@/assets/images/views/profile/cross.svg"
            class="cursor-pointer"
            @click="closeModal"
          />
        </div>
        <div class="h-px bg-gray-2"></div>
        <div class="p-6">
          <p class="text-sm leading-6">Имя</p>
          <UiInput
            v-model="form.profile.first_name"
            :colorType="firstNameError ? 'error' : 'primary'"
            input-height="sm"
            placeholder="Введите имя"
            class="mt-0.5"
          />
          <!-- <p v-if="firstNameError" class="mt-1 text-red-1 text-sm leading-5">Пожалуйста, введите имя</p> -->
          <p class="text-sm leading-6 mt-4">Фамилия</p>
          <UiInput
            v-model="form.profile.last_name"
            :colorType="lastNameError ? 'error' : 'primary'"
            input-height="sm"
            placeholder="Введите фамилию"
            class="mt-0.5"
          />
          <!-- <p v-if="lastNameError" class="mt-1 text-red-1 text-sm leading-5">Пожалуйста, введите фамилию</p> -->
          <p class="text-sm leading-6 mt-4">Email</p>
          <UiInput
            v-model="form.email"
            :colorType="emailError ? 'error' : 'primary'"
            input-height="sm"
            placeholder="Введите email"
            class="mt-0.5"
          />
          <!-- <p v-if="emailError" class="mt-1 text-red-1 text-sm leading-5">Пожалуйста, введите корректный email</p> -->
          <div class="mt-4">
            <p class="text-sm leading-6 mb-0.5">Роль</p>
            <div class="flex flex-col gap-1.5">
              <div
                v-for="variant in accessVariants"
                :key="variant.key"
                class="add-user__variant flex items-center gap-4 w-max cursor-pointer h-6"
                @click="currentAccess = variant.key"
              >
                <div
                  class="w-4 h-4 rounded-full border flex items-center justify-center box-content hover:border-blue relative"
                  :class="variant.key === currentAccess ? 'border-blue' : 'border-gray-2'"
                >
                  <img
                    v-show="variant.key === currentAccess"
                    width="10"
                    height="10"
                    src="@/assets/images/views/profile/point.svg"
                    class="rounded-100"
                  />
                </div>
                <p class="text-sm leading-5 text-gray hover:text-blue">{{ variant.title }}</p>
              </div>
            </div>
          </div>
          <div class="mt-7 flex items-center gap-5">
            <UiButton color-type="outline" class="flex-1" @click="closeModal">Отменить</UiButton>
            <UiButton class="flex-1" @click="addUser">
              <span v-if="!loading">Добавить</span>
              <LoadingSpinner v-else />
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createUser } from '@/api';

import UiButton from '@/components/ui/UiButton.vue';
import UiInput from '@/components/ui/UiInput.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
  name: 'AddUserModal',

  components: { UiButton, UiInput, LoadingSpinner },

  data() {
    return {
      accessVariants: [
        {
          title: 'Админ',
          key: 'admin',
        },
        {
          title: 'Менеджер',
          key: 'manager',
        },
      ],

      currentAccess: 'admin',

      form: {
        profile: {
          first_name: '',
          last_name: '',
        },
        email: '',
      },

      firstNameError: false,
      lastNameError: false,
      emailError: false,

      loading: false,
    };
  },

  watch: {
    'form.profile.first_name'() {
      this.firstNameError = false;
    },

    'form.profile.last_name'() {
      this.lastNameError = false;
    },

    'form.email'() {
      this.emailError = false;
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    validate() {
      if (!this.form.profile.first_name) this.firstNameError = true;
      if (!this.form.profile.last_name) this.lastNameError = true;
      if (!this.form.email || !/^\S+@\S+\.\S+$/.test(this.form.email)) this.emailError = true;
      if (this.firstNameError || this.lastNameError || this.emailError)
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          status: 'warn',
          text: 'Пожалуйста, корректно заполните обязательные поля.',
        });
    },

    async addUser() {
      this.validate();
      if (this.firstNameError || this.lastNameError || this.emailError) return;

      this.loading = true;
      try {
        await createUser({ ...this.form, manager: this.currentAccess === 'manager' });
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Пользователь успешно добавлен',
        });
        this.$emit('added');
      } catch (err) {
        console.error('AddUser Error');
        console.error(err);
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: err.response.data.message,
          status: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.add-user {
  &__content {
    width: 366px;
  }

  &__variant:hover {
    & > div {
      border-color: #13c2c2 !important;
    }

    & > p {
      color: #13c2c2 !important;
    }
  }
}
</style>
