<template>
  <div class="profile-sidebar">
    <div
      v-for="tab in tabList"
      :key="tab.key"
      class="h-10 px-6 flex items-center gap-2 cursor-pointer hover:bg-gray-7 hover:font-semibold hover:text-gray"
      @click="handleTabClick(tab)"
    >
      <img
        width="16"
        height="16"
        :src="require(`@/assets/images/views/profile/${tab.key}.svg`)"
        :class="{ 'profile-sidebar__icon_colored': currentTab.key !== tab.key }"
      />
      <p
        class="flex-1 text-sm leading-6f"
        :class="currentTab.key === tab.key ? 'font-semibold text-gray' : 'text-gray-6'"
      >
        {{ tab.title }}
      </p>
    </div>
    <div class="my-2 h-px bg-gray-2"></div>
    <div
      class="profile-sidebar__log-out h-10 px-6 flex items-center gap-2 cursor-pointer hover:bg-gray-7 text-gray-6 hover:font-semibold hover:text-gray"
      @click="logOut"
    >
      <img width="16" height="16" src="@/assets/images/views/profile/log-out.svg" class="profile-sidebar__icon" />
      <p class="flex-1 text-sm leading-6">Выйти</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',

  props: {
    tabList: {
      type: Array,
      default: () => [],
    },

    currentTab: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleTabClick(tab) {
      this.$emit('update-tab', tab);
    },

    logOut() {
      this.$store.dispatch('logOut');
      this.$router.push('/sign-in');
    },
  },
};
</script>

<style lang="scss">
.profile-sidebar {
  width: 290px;

  &__icon {
    filter: invert(37%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(90%) contrast(92%);
  }

  &__log-out {
    &:hover img {
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(224deg) brightness(107%) contrast(107%);
    }
  }

  &__icon_colored {
    filter: invert(37%) sepia(0%) saturate(0%) hue-rotate(61deg) brightness(90%) contrast(92%);
  }
}
</style>
