<template>
  <div class="h-14 flex px-6 bg-white items-center justify-between navbar">
    <div class="flex items-center gap-6 h-full">
      <router-link to="/advertising-campaigns">
        <img width="96" height="28" src="@/assets/images/logotype.svg" />
      </router-link>
      <div
        v-if="$route.path.includes('advertising-campaigns')"
        class="h-full text-sm leading-5 flex items-center relative cursor-pointer"
        :class="$route.path.includes('advertising-campaigns') ? 'text-gray' : 'text-gray-3'"
      >
        <span>Мои рекламные кампании</span>
        <div
          v-show="$route.path.includes('advertising-campaigns')"
          style="height: 3px"
          class="w-full bg-blue absolute bottom-0 left-0"
        ></div>
      </div>
      <router-link
        v-else
        to="/advertising-campaigns"
        class="h-full text-sm leading-5 flex items-center relative cursor-pointer text-gray-3 hover:text-blue cursor-pointer"
      >
        <span>Мои рекламные кампании</span>
      </router-link>
    </div>
    <NavbarSteps v-if="$route.path === '/create-campaign'" :currentStep="currentCreateStep" />
    <div class="h-full flex items-center gap-4">
      <router-link to="/profile?tab=billing-list">
        <LoadingText v-if="balanceLoading" style="width: 190px" class="h-6 rounded-10" />
        <UiButton v-else style="width: 190px" button-height="sm" colorType="outline" class="px-4">
          <template v-slot:right>
            <img src="@/assets/images/views/advertising-campaigns/wallet.svg" />
          </template>
          <span>{{ userBalance | delimiter }} руб.</span>
        </UiButton>
      </router-link>
      <router-link to="/profile" class="flex items-center gap-3 h-full hover:bg-gray-4 cursor-pointer px-3">
        <LoadingText style="width: 190px" v-if="userLoading" class="h-6 rounded-10" />
        <template v-else>
          <div
            class="h-8 w-8 rounded-full bg-blue flex items-center justify-center text-white text-sm leading-5 uppercase"
          >
            {{ nameAlias }}
          </div>
          <div style="min-width: 100px">
            <p v-if="userInfo" class="text-gray-6 font-semibold text-sm leading-5">
              <span class="capitalize"> {{ userInfo.profile.first_name }}</span>
              <span class="capitalize"> {{ userInfo.profile.last_name }}</span>
            </p>
            <p v-if="organizationInfo" class="text-gray-3 text-xs leading-5">{{ organizationInfo.name }}</p>
            <div v-else class="w-28"></div>
          </div>
        </template>
      </router-link>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton.vue';
import LoadingText from '@/components/ui/LoadingText.vue';
import NavbarSteps from '@/components/views/CreateCampaign/NavbarSteps.vue';

export default {
  name: 'Navbar',

  components: { UiButton, LoadingText, NavbarSteps },

  filters: {
    delimiter(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },

  computed: {
    currentCreateStep() {
      return this.$store.state.system.currentCreateStep;
    },

    userInfo() {
      return this.$store.state.auth.userInfo;
    },

    organizationInfo() {
      return this.$store.state.auth.organizationInfo;
    },

    nameAlias() {
      if (!this.userInfo) return '';
      return this.userInfo.profile.first_name[0] + this.userInfo.profile.last_name[0];
    },

    userBalance() {
      return this.$store.state.auth.balance;
    },

    balanceLoading() {
      return this.$store.state.auth.balanceLoading;
    },

    userLoading() {
      return this.$store.state.auth.userLoading;
    },
  },
};
</script>

<style lang="scss">
.navbar {
  box-shadow: 0px 1px 4px 0px #00000014;
}
</style>
