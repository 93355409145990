<template>
  <div class="campaing-card bg-gray-4 pb-10">
    <div class="main-container pt-4">
      <router-link
        v-if="currentCategory !== 'moderation'"
        to="/advertising-campaigns"
        class="flex gap-6 items-center text-gray hover:text-blue w-max"
      >
        <img width="24" height="24" src="@/assets/images/views/campaign-card/arrow-left.svg" />
        <p to="/advertising-campaigns" class="text-2xl leading-8">К списку кампаний</p>
      </router-link>
      <div
        v-else
        class="flex cursor-pointer w-max gap-6 items-center text-gray hover:text-blue"
        @click="changeCategory('details')"
      >
        <img width="24" height="24" src="@/assets/images/views/campaign-card/arrow-left.svg" />
        <p to="/advertising-campaigns" class="text-2xl leading-8">К деталям кампании</p>
      </div>
      <div
        v-if="currentCategory !== 'moderation'"
        class="my-4 flex items-center justify-between border-b border-gray-2"
      >
        <div class="flex">
          <div
            v-for="category in isAdvertiser ? infoCategories : operatorCategories"
            :key="category.key"
            style="height: 44px"
            class="flex px-4 justify-center items-center text-sm leading-5 text-gray relative cursor-pointer"
            @click="changeCategory(category.key)"
          >
            <p>{{ category.title }}</p>
            <div
              v-show="category.key === currentCategory"
              style="bottom: -1px"
              class="absolute left-0 w-full h-0.5 bg-blue"
            ></div>
          </div>
        </div>
        <UiButton
          v-if="!isAdvertiser && adInfo.contextual_state !== 'stopped'"
          button-height="sm"
          class="px-4"
          :disabled="adInfo?.content_item?.content_files.length === 0"
          @click="changeCategory('moderation')"
        >
          Модерация
        </UiButton>
      </div>
      <keep-alive>
        <component
          :is="currentComponent"
          :ad-info="adInfo"
          :ad-analytics="adAnalytics"
          :ad-indicators="adIndicators"
          :organization-users="organizationUsers"
          :loading="loading"
          @update-users="getAdInfo"
          @update-content="getAdInfo"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { getCampaignInfo, getCampaignAnalytics, getUsers } from '@/api';

import CampaignDetails from '@/components/views/CampaignCard/CampaignDetails';
import CampaignContent from '@/components/views/CampaignCard/CampaignContent';
import CampaignStatus from '@/components/views/CampaignCard/CampaignStatus';
import ContentModeration from '@/components/views/CampaignCard/ContentModeration';
import LoadingText from '@/components/ui/LoadingText';
import UiButton from '@/components/ui/UiButton';

export default {
  name: 'CampaignCard',
  components: { CampaignDetails, CampaignContent, CampaignStatus, ContentModeration, UiButton, LoadingText },
  data() {
    return {
      adInfo: {},
      adAnalytics: [],
      adIndicators: {},
      organizationUsers: [],

      loading: true,

      infoCategories: [
        {
          key: 'details',
          title: 'Детали',
        },
        {
          key: 'content',
          title: 'Контент',
        },
        {
          key: 'status',
          title: 'Статус модерации',
        },
      ],

      operatorCategories: [
        {
          key: 'details',
          title: 'Детали',
        },
        {
          key: 'content',
          title: 'Контент',
        },
      ],

      currentCategory: 'details',
      breakChange: false,
    };
  },

  computed: {
    routeParams() {
      return this.$route.params;
    },

    currentComponent() {
      if (this.currentCategory === 'content') return 'CampaignContent';
      if (this.currentCategory === 'status') return 'CampaignStatus';
      if (this.currentCategory === 'moderation') return 'ContentModeration';
      return 'CampaignDetails';
    },

    isAdvertiser() {
      return this.$store.state.auth.organizationMode === 'advertiser';
    },
  },

  methods: {
    async getAdInfo() {
      this.loading = true;
      const requestBody = {
        id: this.$route.params.id,
      };

      const userParams = {
        limit: 100,
        offset: 0,
        state: 'active',
      };

      try {
        if (this.isAdvertiser) {
          const [infoResponse, analyticsResponse, usersResponse] = await Promise.all([
            getCampaignInfo(requestBody),
            getCampaignAnalytics(requestBody),
            getUsers(userParams),
          ]);
          this.adInfo = infoResponse.data;
          this.adAnalytics = analyticsResponse.data.by_hour;
          this.adIndicators = {
            cost: analyticsResponse.data.cost,
            count: analyticsResponse.data.count,
            views: analyticsResponse.data.views,
          };
          this.organizationUsers = usersResponse.data.objects;
        } else {
          const infoResponse = await getCampaignInfo(requestBody);
          this.adInfo = infoResponse.data;
        }
      } catch (err) {
        console.error(err);
        this.$router.replace('/not-found');
      } finally {
        this.loading = false;
      }
    },

    changeCategory(category) {
      if (this.breakChange) return;
      this.breakChange = true;
      this.currentCategory = category;
      setTimeout(() => {
        this.breakChange = false;
      }, 100);
    },
  },

  watch: {
    routeParams() {
      this.getAdInfo();
    },
  },

  async mounted() {
    await this.getAdInfo();
  },
};
</script>

<style lang="scss">
.campaing-card {
  min-height: calc(100vh - 172px);
}
</style>
