<template>
  <div
    class="ui-input flex justify-between items-center placeholder-gray-5 rounded-4"
    :class="[
      {
        border: !noBorder,
        primary: colorType === 'primary',
        error: colorType === 'error',
        'pr-2': $slots.default,
        'pl-2': $slots.left,
        'font-semibold text-gray': bold,
      },
      colorClass,
      heightClass,
    ]"
  >
    <slot name="left" />
    <input
      v-if="!textArea"
      :disabled="disabled"
      style="max-width: 100%"
      :value="formattedValue"
      :id="inputId ? inputId : null"
      :maxlength="maxLength"
      :name="inputName"
      :type="inputType"
      :placeholder="placeholder"
      class="flex-1 h-full bg-transparent outline-none px-2.5"
      :class="{ 'text-right': right }"
      @keypress="isNumber($event)"
      @input="handleInput"
    />
    <textarea
      v-else
      rows="2"
      :disabled="disabled"
      style="max-width: 100%; resize: none"
      :value="formattedValue"
      :id="inputId ? inputId : null"
      :maxlength="maxLength"
      :type="inputType"
      :placeholder="placeholder"
      class="flex-1 h-full bg-transparent outline-none px-2.5 py-2.5"
      :class="{ 'text-right': right }"
      @keypress="isNumber($event)"
      @input="handleInput"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiInput',
  props: {
    inputHeight: {
      type: String,
      default: 'md',
    },

    colorType: {
      type: String,
      default: 'primary',
    },

    placeholder: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    inputType: {
      type: String,
      default: 'text',
    },

    noBorder: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: Number,
      default: Infinity,
    },

    bold: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },

    number: {
      type: Boolean,
      default: false,
    },

    inputId: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    textArea: {
      type: Boolean,
      default: false,
    },

    inputName: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    heightClass() {
      if (this.textArea) return '';
      if (this.inputHeight === 'sm') return 'h-8 text-sm leading-5';
      if (this.inputHeight === 'xs') return 'h-6 text-sm leading-5';
      return 'h-10 text-base leading-6';
    },

    colorClass() {
      if (this.colorType === 'gray') return 'text-gray bg-gray-4';
      if (this.colorType === 'error') return 'border-red-1 text-gray-6';
      return 'bg-white border-gray-2 hover:border-blue-hover focus-within:border-blue-hover text-gray-6';
    },

    formattedValue() {
      if (this.inputType === 'number') {
        return this.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
      return this.value;
    },
  },
  methods: {
    handleInput(event) {
      if (event.target.value.length > this.maxLength) return;
      if (event.target.value === '' && this.number) {
        this.$emit('input', '0');
        return;
      }
      this.$emit('change');
      if (this.number) {
        this.$emit('input', event.target.value.replace(' ', ''));
        return;
      }
      this.$emit('input', event.target.value);
    },

    isNumber: function (evt) {
      if (!this.number) return;
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss">
.ui-input {
  input,
  textarea {
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.ui-input.border {
  &:focus-within.primary {
    box-shadow: 0px 0px 4px 0px #1890ff80;
  }
  &:focus-within.error {
    box-shadow: 0px 0px 4px 0px #f5222d80;
  }
}
</style>
