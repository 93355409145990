<template>
  <div class="rounded-4 h-10 p-1 bg-white flex relative" tabindex="0" @blur="isVisible = false">
    <div
      class="w-full h-full rounded-4 border border-gray-2 flex items-center px-3 gap-1.5 cursor-pointer"
      @click="isVisible = !isVisible"
    >
      <p style="white-space: nowrap" class="flex-1 overflow-hidden text-sm leading-5 text-gray-6 no-wrap">
        {{ `${title}: ${choiceAmount} категорий` }}
      </p>
      <img width="11" height="8" src="@/assets/images/ui/select/arrow-down.svg" />
    </div>
    <div v-show="isVisible" class="absolute top-12 left-1 right-1s rounded-4 bg-white ui-select__dropdown">
      <div class="py-2.5 border-b border-gray-7 flex items-center gap-2">
        <div class="flex gap-2 items-center px-3">
          <div
            class="w-4 h-4 rounded-4 border border-gray-2 hover:border-blue flex justify-center items-center cursor-pointer"
            :class="{ 'bg-blue': isAllChecked }"
            @click="handleAllClick"
          >
            <img v-show="isAllChecked" width="9.5" height="7.5" src="@/assets/images/ui/checkbox/checkmark.svg" />
          </div>
          <p class="text-gray-6 text-sm leading-5">Выбрать все</p>
        </div>
      </div>
      <div v-for="option in childOptions" :key="option.name" class="px-3 flex items-center my-2 gap-2 overflow-auto">
        <UiCheckbox :value="option.value" @input="handleInput(option, $event)" />
        <p class="text-gray-6 text-sm leading-5">{{ option.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import UiCheckbox from '@/components/ui/UiCheckbox';

export default {
  name: 'UiSelect',
  components: { UiCheckbox },

  props: {
    title: {
      type: String,
      default: '',
    },

    value: {
      type: Array,
      default: () => [],
    },

    lastChecked: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      childOptions: this.value,
      isVisible: false,
    };
  },

  computed: {
    isAllChecked() {
      let isChecked = true;
      this.childOptions.forEach((option) => {
        if (!option.value) isChecked = false;
      });
      return isChecked;
    },

    choiceAmount() {
      return this.childOptions.filter((option) => option.value).length;
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.childOptions = this.value;
      },
    },

    childOptions: {
      deep: true,
      handler() {
        this.$emit('input', this.childOptions);
      },
    },
  },

  methods: {
    handleAllClick() {
      if (this.lastChecked) {
        this.childOptions.forEach((option) => {
          option.value = true;
        });
      } else {
        const isAllChecked = this.childOptions.filter((opt) => opt.value).length === this.childOptions.length;
        this.childOptions.forEach((option) => {
          option.value = !isAllChecked;
        });
      }
    },

    handleInput(option, value) {
      if (this.lastChecked && this.childOptions.filter((option) => option.value).length <= 1 && !value) return;
      option.value = value;
    },
  },
};
</script>

<style lang="scss">
.ui-select {
  &__dropdown {
    box-shadow: 0px 2px 8px 0px #00000026;
  }
}
</style>
