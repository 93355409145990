<template>
  <div>
    <div class="step-two__image-wrapper relative pt-6">
      <img
        src="@/assets/images/views/create-campaign/step-two-bg.png"
        class="object-cover absolute top-0 left-0 h-full w-full"
      />
      <div class="relative z-1">
        <h2 style="font-size: 30px" class="text-white leading-10 text-center font-semibold">
          Загрузка контента для рекламной кампании {{ `"${form.name}"` }}
        </h2>
        <p class="text-sm leading-5 text-gray-5 text-center mt-2">
          Если у Вас нет подходящего ролика для выбранного экрана, то можете просто пропустить загрузку.<br />
          Система автоматически перераспределит показы для достижения поставленной цели в рамках указанного бюджета на
          другие выбранные Вами экраны
        </p>
        <UiButton class="mt-4 px-4 w-max mx-auto">Скачать требования к видеоматериалу</UiButton>
      </div>
    </div>
    <div class="main-container pt-8">
      <p class="text-gray font-semibold text-2xl leading-8 mb-6">Загрузка контента</p>
      <div class="grid grid-cols-4 gap-6 pb-24">
        <ContentCard
          v-for="dimension in playerDimensions"
          :key="dimension.width + ' ' + dimension.height"
          :dimension="dimension"
          :form="form"
          :content-item="contentItem"
          @update-content="handleUpdateContent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton';
import ContentCard from '@/components/views/CreateCampaign/ContentCard.vue';

export default {
  name: 'StepTwo',

  components: { UiButton, ContentCard },

  props: {
    playerDimensions: {
      type: Array,
      default: () => [],
    },

    form: {
      type: Object,
      default: () => ({}),
    },

    contentItem: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    handleUpdateContent(value) {
      this.$emit('update-content', value);
    },
  },
};
</script>

<style lang="scss">
.step-two {
  &__image-wrapper {
    height: 194px;
  }
}
</style>
