import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
  state: {
    loginPaths: ['/sign-in', '/sign-up', '/recover-password', '/confirm-password'],
    currentCreateStep: 1,
    isPlugVisible: false,

    popupDisplay: false,
    popupInfo: '',
    popupInfoStatus: 'info', // info | warn | error
    popupTimeout: null,
  },

  getters: {},

  mutations: {
    setCurrentCreateStep(state, value) {
      state.currentCreateStep = value;
    },

    setPlugDisplay(state, value) {
      const el = document.querySelector('#sitePlug');
      if (value) disableBodyScroll(el);
      else enableBodyScroll(el);
      state.isPlugVisible = value;
    },

    setPopupDisplay(state, value) {
      state.popupInfo = value.text;
      state.popupInfoStatus = value.status ? value.status : 'info';
      state.popupDisplay = value.display;
    },
  },

  actions: {
    setPopupDisplay({ state, commit }, value) {
      if (value.display === false) {
        state.popupDisplay = false;
        return;
      }

      if (value.display === true && state.popupDisplay === true) {
        state.popupDisplay = false;
        if (state.popupTimeout) clearTimeout(state.popupTimeout);
        state.popupTimeout = setTimeout(() => {
          commit('setPopupDisplay', value);
        }, 100);
        return;
      }

      commit('setPopupDisplay', value);
    },
  },
};
