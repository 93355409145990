<template>
  <div class="pb-24">
    <div class="step-three__image-wrapper relative pt-6">
      <img
        src="@/assets/images/views/create-campaign/step-three-bg.png"
        class="absolute top-0 left-0 w-full h-full object-cover"
      />
      <div class="relative z-1">
        <h2 style="font-size: 30px" class="leading-10 text-white text-center">Оформление рекламной кампании</h2>
        <p class="text-center mt-4 text-sm leading-5 text-gray-2">
          Пожалуйста проверьте выбранные Вами найстроки и также те цели, которые планируете достичь по текущей РК.<br />
          Если выбранный экран недоступен (или полностью выкуплен), то наша система автоматически перераспределит
          частоту показа <br />рекламного ролика на другие выбранные Вами экраны для достижения поставленной цели в
          рамках бюджета.
        </p>
      </div>
    </div>
    <div class="main-container pt-8">
      <p class="text-2xl font-semibold leading-8">Карточка рекламной кампании</p>
      <div class="p-6 mt-4 bg-white">
        <div class="flex items-center gap-6">
          <p style="font-size: 30px" class="font-semibold leading-10">{{ form.name }}</p>
          <div class="flex items-center gap-4">
            <p class="text-2xl leading-8 font-semibold">{{ formattedStart + ' - ' + formattedEnd }}</p>
            <img src="@/assets/images/views/create-campaign/calendar.svg" />
          </div>
        </div>
        <div class="flex items-start mt-4 gap-6">
          <div class="flex-1">
            <p class="font-semibold text-gray mb-4">Бюджет и экраны</p>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Общий бюджет РК, руб.</div>
              <div class="col-span-2 text-gray text-sm leading-5">{{ form.budget }}</div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Бюджет, руб./день</div>
              <div class="col-span-2 text-gray text-sm leading-5">{{ form.cpm }}</div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Потенциальный охват аудитории</div>
              <div class="col-span-2 text-gray text-sm leading-5">{{ form.ots }}</div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Кол-во экранов</div>
              <div class="col-span-2 text-gray text-sm leading-5">{{ form.whitelist_player_ids.length }}</div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Город / Города</div>
              <div class="col-span-2 text-gray text-sm leading-5">Москва</div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Формат размещения</div>
              <div class="col-span-2 text-gray text-sm leading-5">
                <span v-if="form.indoor">Indoor</span
                ><span v-if="form.outdoor"><span v-if="form.outdoor && form.indoor">, </span>Outdoor</span>
              </div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Indoor:</div>
              <div class="col-span-2 text-gray text-sm leading-5">БЦ, HoReCa, Фитнес клубы</div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Outdoor:</div>
              <div class="col-span-2 text-gray text-sm leading-5">Вид рекламы 2, Вид рекламы 3, Вид рекламы 5</div>
            </div>
          </div>
          <div class="flex-1">
            <p class="font-semibold text-gray mb-4">Ролик</p>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Длительность ролика</div>
              <div class="col-span-2 text-gray text-sm leading-5">{{ form?.content_length }} сек</div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Форматы ролика:</div>
              <div class="col-span-2 text-gray text-sm leading-5 flex flex-wrap gap-1">
                <span
                  v-for="(size, index) in playerDimensions"
                  :key="size.title"
                  class="whitespace-nowrap"
                  :class="{ 'text-blue': size.isFileLoaded }"
                >
                  {{ `${size.title}${index !== playerDimensions?.length - 1 ? ', ' : ''}` }}
                </span>
              </div>
            </div>
            <div class="mt-3 grid grid-cols-3">
              <div class="text-sm leading-5 text-gray-3">Превью</div>
              <div
                v-if="isPreviewVisible"
                class="col-span-2 text-gray text-sm leading-5 step-three__preview relative bg-black"
              >
                <img :src="previewSource" class="absolute top-0 left-0 h-full w-full object-contain object-center" />
              </div>
              <div v-else class="col-span-2 text-gray text-sm leading-5 flex flex-wrap gap-1">Контент не загружен</div>
            </div>
          </div>
        </div>
        <div class="mt-16 border-t border-gray-7 -ml-6 -mr-6 pt-6 px-6 flex justify-end items-center gap-6">
          <template v-if="$route.path.includes('create-campaign')">
            <UiButton style="width: 155px" button-height="sm" @click="createCampaign({ draft: true })">
              <span v-if="!draftLoading">Сохранить черновик</span>
              <LoadingSpinner v-else />
            </UiButton>
            <UiButton style="width: 116px" button-height="sm" @click="createCampaign({ draft: false })">
              <span v-if="!campaignLoading">Запустить РК</span>
              <LoadingSpinner v-else />
            </UiButton>
          </template>
          <template v-else-if="form.draft">
            <UiButton style="width: 256px" button-height="sm" class="px-4" @click="updateCampaign({ draft: true })">
              <span v-if="!draftLoading">Сохранить изменения в черновике</span>
              <LoadingSpinner v-else />
            </UiButton>
            <UiButton style="width: 116px" button-height="sm" class="px-4" @click="updateCampaign({ draft: false })">
              <span v-if="!campaignLoading">Запустить РК</span>
              <LoadingSpinner v-else />
            </UiButton>
          </template>
          <template v-else>
            <UiButton style="width: 170px" button-height="sm" class="px-4" @click="updateCampaign({ draft: false })">
              <span v-if="!campaignLoading">Сохранить изменения</span>
              <LoadingSpinner v-else />
            </UiButton>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createCampaign, updateCampaign } from '@/api';
import UiButton from '@/components/ui/UiButton';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

export default {
  name: 'StepThree',
  components: { UiButton, LoadingSpinner },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },

    playerDimensions: {
      type: Array,
      default: () => [],
    },

    contentItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      campaignLoading: false,
      draftLoading: false,

      isDraftSaved: false,
    };
  },

  computed: {
    formattedStart() {
      if (!this.form.start) return '';
      return this.form.start.split('-')[2] + '.' + this.form.start.split('-')[1] + '.' + this.form.start.split('-')[0];
    },
    formattedEnd() {
      if (!this.form.end) return '';
      return this.form.end.split('-')[2] + '.' + this.form.end.split('-')[1] + '.' + this.form.end.split('-')[0];
    },

    isPreviewVisible() {
      return this.contentItem?.content_files?.length > 0;
    },

    previewSource() {
      return this.contentItem.content_files[0].preview;
    },
  },

  methods: {
    async createCampaign(payload) {
      if (this.draftLoading || this.campaignLoading) return;
      if (payload.draft) this.draftLoading = true;
      else this.campaignLoading = true;
      try {
        await createCampaign({ ...this.form, content_length: undefined, ...payload });
        this.$store.dispatch('getCampaigns');
        this.$router.push('/advertising-campaigns');
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Рекламная кампания успешно создана!',
        });
      } catch (err) {
        console.error(err);
        if (err.response.status !== 422) return;
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: err.response.data.message.ru,
          status: 'error',
        });
      } finally {
        this.campaignLoading = false;
        this.draftLoading = false;
      }
    },

    async updateCampaign(payload) {
      if (this.draftLoading || this.campaignLoading) return;
      if (payload.draft) this.draftLoading = true;
      else this.campaignLoading = true;
      try {
        await updateCampaign(this.form.id, {
          ...this.form,
          id: undefined,
          content_length: undefined,
          mechanism: undefined,
          ...payload,
        });
        this.$store.dispatch('getCampaigns');
        this.$router.push('/advertising-campaigns');
        this.$store.dispatch('setPopupDisplay', { display: true, text: 'Рекламная кампания успешно изменена!' });
      } catch (err) {
        console.error('[UpdateCampaign Error]');
        console.error(err);
        if (err.response.status !== 422) return;
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: err.response.data.message.ru,
          status: 'error',
        });
      } finally {
        this.campaignLoading = false;
        this.draftLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.step-three {
  &__image-wrapper {
    height: 168px;
  }
  &__preview {
    height: 215px;
  }
}
</style>
