<template>
  <div class="pb-10">
    <p class="font-semibold text-2xl leading-8 text-gray">Моя организация</p>
    <div class="my-organization__wrapper p-6 pt-4 bg-white mt-6">
      <p class="font-semibold">Реквизиты компании</p>
      <div class="h-px my-4 bg-gray-7"></div>
      <div v-if="!isAdvertiser" class="mt-4">
        <p class="text-sm leading-6 mb-0.5">Remote Organization ID</p>
        <UiInput
          placeholder="Remote Organization ID"
          disabled
          :value="organization ? String(organization.remote_organization_id) : ''"
          input-height="sm"
        />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">ИНН</p>
        <UiInput v-model="form.inn" placeholder="ИНН" input-height="sm" />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">Название</p>
        <UiInput v-model="form.name" placeholder="Название" input-height="sm" />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">Адрес</p>
        <UiInput v-model="form.address" placeholder="Адрес" input-height="sm" />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">БИК Банка</p>
        <UiInput v-model="form.bik" :max-length="9" placeholder="БИК Банка" input-height="sm" />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">Банк</p>
        <UiInput v-model="form.bank" placeholder="Банк" input-height="sm" />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">КПП</p>
        <UiInput v-model="form.kpp" placeholder="КПП" input-height="sm" />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">Счёт</p>
        <UiInput v-model="form.bank_account" placeholder="Счёт" input-height="sm" />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">Директор</p>
        <UiInput v-model="form.director" placeholder="Директор" input-height="sm" />
      </div>
      <div class="mt-4">
        <p class="text-sm leading-6 mb-0.5">Телефон</p>
        <UiInput v-model="form.phone" placeholder="Телефон" input-height="sm" />
      </div>
      <UiButton button-height="sm" class="mt-6 w-52 ml-auto" @click="changeOrganizationInfo">
        <span v-if="!loading">Сохранить изменения</span>
        <LoadingSpinner v-else />
      </UiButton>
    </div>
  </div>
</template>

<script>
import { changeOrganizationInfo } from '@/api';
import UiInput from '@/components/ui/UiInput.vue';
import UiButton from '@/components/ui/UiButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
  name: 'MyOrganization',

  components: { UiInput, UiButton, LoadingSpinner },

  data() {
    return {
      form: {
        inn: '',
        name: '',
        address: '',
        bik: '',
        bank: '',
        kpp: '',
        bank_account: '',
        director: '',
        phone: '',
      },

      loading: false,
    };
  },

  computed: {
    organization() {
      if (!this.$store.state.auth.userInfo) return null;
      return this.$store.state.auth.userInfo.organization;
    },

    isAdvertiser() {
      return this.$store.state.auth.organizationMode === 'advertiser';
    },
  },

  watch: {
    organization() {
      this.fillForm();
    },
  },

  mounted() {
    this.fillForm();
  },

  methods: {
    fillForm() {
      if (!this.organization) return;
      Object.keys(this.organization.profile).forEach((key) => {
        this.form[key] = this.organization.profile[key] ? this.organization.profile[key] : '';
      });
    },

    async changeOrganizationInfo() {
      if (!this.organization || this.loading) return;

      this.loading = true;
      try {
        await changeOrganizationInfo(this.organization.id, this.form);
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Данные успешно обновлены',
        });
      } catch {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Ошибка при изменении данных организации.',
          status: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.my-organization {
  &__wrapper {
    width: 516px;
  }
}
</style>
