<template>
  <div>
    <div
      class="delegate-modal fixed top-0 left-0 h-full w-full flex justify-center items-center z-4"
      @click="handleCloseClick"
    >
      <div class="delegate-modal__content bg-white rounded-4" @click.stop>
        <div class="px-6 py-4 flex items-center justify-between border-b border-gray-2">
          <p class="font-semibold">Делегировать менеджеру</p>
          <img
            width="18"
            height="18"
            alt="close"
            src="@/assets/images/views/campaign-card/close.svg"
            class="cursor-pointer"
            @click="handleCloseClick"
          />
        </div>
        <div class="p-6">
          <div
            class="relative h-10 flex items-center justify-between rounded-4 border border-gray-2 hover:border-blue-hover px-3 cursor-pointer"
            @click="isDropdownVisible = !isDropdownVisible"
            tabindex="0"
            @blur="isDropdownVisible = false"
          >
            <p class="text-gray-6">
              {{
                currentUser
                  ? currentUser.profile.first_name + ' ' + currentUser.profile.last_name
                  : 'Выберите менеджера'
              }}
            </p>
            <img width="18" height="18" alt="drop" src="@/assets/images/views/campaign-card/dropdown.svg" />
            <div
              v-show="isDropdownVisible"
              class="delegate-modal__dropdown overflow-auto absolute z-1 top-12 left-0 w-full rounded-4 bg-white py-1 px-3"
            >
              <div
                v-for="user in managers"
                :key="user.id"
                class="h-8 text-sm leading-5 text-gray-6 cursor-pointer flex items-center hover:text-blue"
                @click="currentUser = user"
              >
                {{ user.profile?.first_name + ' ' + user.profile?.last_name }}
              </div>
            </div>
          </div>
          <div class="mt-6">
            <div
              v-for="variant in accessVariants"
              :key="variant.key"
              class="flex items-center gap-4 w-max mt-3 cursor-pointer h-6"
              @click="currentAccess = variant.key"
            >
              <div
                class="w-4 h-4 rounded-full border flex items-center justify-center box-content hover:border-blue relative"
                :class="variant.key === currentAccess ? 'border-blue' : 'border-gray-2'"
              >
                <div v-show="variant.key === currentAccess" class="rounded-full bg-blue w-2.5 h-2.5"></div>
              </div>
              <p class="text-sm leading-5 text-gray hover:text-blue">{{ variant.title }}</p>
            </div>
          </div>
          <UiButton :disabled="!currentUser" class="rounded-4 mt-5" @click="delegateCampaign">
            <span v-if="!loading">Сохранить</span>
            <LoadingSpinner v-else />
          </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { delegateCampaign } from '@/api';

import UiButton from '@/components/ui/UiButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
  name: 'DelegateModal',

  components: { UiButton, LoadingSpinner },

  props: {
    adInfo: {
      type: Object,
      default: () => ({}),
    },

    managers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isDropdownVisible: false,

      currentUser: null,

      accessVariants: [
        {
          title: 'Полный доступ',
          key: 'full',
        },
        {
          title: 'Только чтение',
          key: 'readonly',
        },
      ],
      currentAccess: 'full',

      loading: false,
    };
  },

  methods: {
    handleCloseClick() {
      this.currentUser = null;
      this.currentAccess = 'full';
      this.$emit('close');
    },

    createRequestData() {
      if (this.currentAccess === 'full') return { managers: [...this.adInfo.managers, this.currentUser.id] };
      else return { managers_readonly: [...this.adInfo.managers_readonly, this.currentUser.id] };
    },

    async delegateCampaign() {
      if (
        this.adInfo.managers.includes(this.currentUser.id) ||
        this.adInfo.managers_readonly.includes(this.currentUser.id)
      ) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Выбранный менеджер уже имеет права доступа к данной кампании',
          status: 'warn',
        });
        return;
      }
      this.loading = true;
      const data = this.createRequestData();
      try {
        await delegateCampaign(this.adInfo.id, data);
        this.$emit('apply');
        this.currentUser = null;
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Менеджер успешно получил доступ к рекламной кампании.',
        });
        this.$store.dispatch('getCampaigns');
      } catch (err) {
        console.error('DelegateModal error');
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.delegate-modal {
  background: rgba(0, 0, 0, 0.65);

  &__content {
    width: 366px;
  }

  &__dropdown {
    box-shadow: 0px 2px 8px 0px #00000026;
    max-height: 168px;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
}
</style>
