<template>
  <div class="flex items-center gap-3">
    <div v-for="step in steps" :key="step.title" class="flex gap-3 items-center">
      <div class="flex items-center gap-2">
        <div
          class="w-6 h-6 rounded-full text-sm flex justify-center items-center"
          :class="{
            'bg-blue text-white': step.order === currentStep,
            'bg-transparent border border-blue': step.order < currentStep,
            'bg-transparent border border-gray-5 text-gray-5': step.order > currentStep,
          }"
        >
          <p v-if="step.order >= currentStep">{{ step.order }}</p>
          <img v-else src="@/assets/images/views/create-campaign/checked.svg" />
        </div>
        <div class="relative">
          <p
            class="text-sm absolute top-0 left-0"
            :class="{
              'text-gray-6': step.order < currentStep,
              'text-black font-semibold': step.order === currentStep,
              'text-gray-5': step.order > currentStep,
            }"
          >
            {{ step.title }}
          </p>
          <p class="opacity-0 font-semibold text-sm">{{ step.title }}</p>
        </div>
      </div>
      <div v-if="step.order !== 3" class="w-20 h-px bg-gray-5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavbarSteps',
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      steps: [
        {
          title: 'Настройка',
          order: 1,
        },
        {
          title: 'Контент',
          order: 2,
        },
        {
          title: 'Проверка и запуск',
          order: 3,
        },
      ],
    };
  },
};
</script>
