<template>
  <div>
    <div class="flex items-center gap-6">
      <div v-for="item in legendItems" :key="item.key" class="flex items-center gap-2">
        <div :style="`background: ${item.color}`" class="w-2 h-2 rounded-full"></div>
        <p class="text-sm leading-5 text-gray">{{ item.title }}</p>
      </div>
    </div>
    <div class="relative">
      <div class="campaign-chart__plug w-20 h-6 absolute left-0 bottom-0 bg-white z-3"></div>
      <div id="chart" style="height: 221px" class="w-full mt-2.5"></div>
    </div>
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_ru_RU from '@amcharts/amcharts4/lang/ru_RU';

export default {
  name: 'CampaignChart',

  props: {
    adAnalytics: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      legendItems: [
        {
          color: '#7EB6EA',
          title: 'Охват',
          key: 'coverage',
        },
        {
          color: '#262626',
          title: 'Показы',
          key: 'shows',
        },
        {
          color: '#FADB14',
          title: 'Бюджет по дням',
          key: 'budget',
        },
      ],

      chartData: [],

      chart: null,
    };
  },

  mounted() {
    this.initChart('chart', this.adAnalytics);
  },

  beforeDestroy() {
    if (this.chart) this.chart.dispose();
    this.chart = null;
  },

  methods: {
    initChart(id, data) {
      // data.forEach((item) => {
      //   const date = item.date.split('-');
      //   item.date = new Date(date[0], date[1] - 1, date[2], item.hour);
      // });

      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(id, am4charts.XYChart);
      this.chart = chart;

      chart.paddingLeft = 0;
      chart.plotContainer.background.strokeWidth = 1;
      chart.plotContainer.background.strokeOpacity = 1;
      chart.plotContainer.background.stroke = am4core.color('#D9D9D9');
      chart.background.fill = am4core.color('#fff');

      chart.numberFormatter.numberFormat = '#,###';

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.labels.template.fill = am4core.color('#595959');
      dateAxis.renderer.labels.template.fontSize = 10;
      dateAxis.renderer.labels.template.fontWeight = '700';
      dateAxis.renderer.grid.template.stroke = am4core.color('#595959');
      dateAxis.startLocation = 0.3;
      dateAxis.endLocation = 0.7;
      dateAxis.renderer.minGridDistance = 30;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minGridDistance = 50;
      valueAxis.renderer.labels.template.fill = am4core.color('#595959');
      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.fontWeight = '700';
      valueAxis.renderer.grid.template.stroke = am4core.color('#595959');
      valueAxis.strictMinMax = true;
      valueAxis.cursorTooltipEnabled = false;

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = 'views';
      series.dataFields.dateX = 'date';
      series.stroke = am4core.color('#7EB6EA');
      series.strokeWidth = 2;

      const series3 = chart.series.push(new am4charts.LineSeries());
      series3.dataFields.valueY = 'cost';
      series3.dataFields.dateX = 'date';
      series3.stroke = am4core.color('#FADB14');
      series3.strokeWidth = 4;

      const series2 = chart.series.push(new am4charts.LineSeries());
      series2.dataFields.valueY = 'count';
      series2.dataFields.dateX = 'date';
      series2.stroke = am4core.color('#262626');
      series2.strokeWidth = 2;

      const bullet = series.bullets.push(new am4core.Circle());
      bullet.radius = 5;
      bullet.fill = am4core.color('#7EB6EA');
      bullet.strokeWidth = 2;
      bullet.stroke = am4core.color('#FFF');

      const bullet2 = series2.bullets.push(new am4core.Rectangle());
      bullet2.width = 6;
      bullet2.height = 6;
      bullet2.dy = -3;
      bullet2.dx = -3;
      bullet2.fill = am4core.color('#262626');
      bullet2.strokeWidth = 1;
      bullet2.stroke = am4core.color('#FFF');

      const bullet3 = series3.bullets.push(new am4core.Circle());
      bullet3.radius = 5;
      bullet3.fill = am4core.color('#FADB14');
      bullet3.strokeWidth = 2;
      bullet3.stroke = am4core.color('#FFF');

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.stroke = am4core.color('#595959');
      chart.cursor.lineX.strokeDasharray = '10';
      chart.cursor.lineY.stroke = am4core.color('#595959');
      chart.cursor.lineY.strokeDasharray = '10';
      chart.cursor.behavior = 'none';

      dateAxis.tooltip.disabled = true;

      // data ===================================

      let maxValue = 0;

      data.forEach((item) => {
        const value = Math.max(item.views, item.cost, item.count);
        if (value > maxValue) maxValue = value;
      });

      valueAxis.min = -1000;
      valueAxis.max = maxValue * 1.1;

      const chartData = [...data.map((item) => ({ ...item, date: new Date(item.date).setHours(item.hour) }))];
      // const firstDate = new Date(chartData[0].date);
      // firstDate.setDate(firstDate.getDate() - 1);
      // const lastDate = new Date(chartData[chartData.length - 1].date);
      // lastDate.setDate(lastDate.getDate() + 1);
      // chartData.unshift({
      //   date: firstDate,
      //   views: chartData[0].views,
      //   count: chartData[0].count,
      //   cost: chartData[0].cost,
      // });
      // chartData.push({
      //   date: lastDate,
      //   views: chartData[chartData.length - 1].views,
      //   count: chartData[chartData.length - 1].count,
      //   cost: chartData[chartData.length - 1].cost,
      // });

      const seriesTooltip = new am4core.Tooltip();
      series.tooltip = seriesTooltip;
      seriesTooltip.background.pointerLength = 0;
      seriesTooltip.getFillFromObject = false;
      seriesTooltip.background.fill = am4core.color('#fff');
      seriesTooltip.background.fillOpacity = 0.8;
      seriesTooltip.background.strokeWidth = 0;
      seriesTooltip.background.cornerRadius = 4;
      // seriesTooltip.dy = 7;
      seriesTooltip.label.fontSize = 12;
      seriesTooltip.label.paddingTop = 12;
      seriesTooltip.label.paddingBottom = 12;
      seriesTooltip.label.paddingLeft = 12;
      seriesTooltip.label.paddingRight = 12;
      seriesTooltip.label.width = 120;
      seriesTooltip.label.fill = am4core.color('#595959');
      // seriesTooltip.background.filters.clear();
      seriesTooltip.background.stroke = am4core.color('#13C2C2');
      seriesTooltip.background.strokeWidth = 1;

      series.tooltipHTML = `<span class="capitalize font-bold">{dateX.formatDate("EEEE, MMM d, YYYY")}</span><br/>
       <div class="flex gap-2 items-center">
        <div style="background:#7EB6EA" class="w-2 h-2 rounded-full"></div>
        <p>Охват: {views}</p>
       </div>
       <div class="flex gap-2 items-center">
        <div style="background:#262626" class="w-2 h-2 rounded-full"></div>
        <p>Показы: {count}</p>
       </div>
       <div class="flex gap-2 items-center">
        <div style="background:#FADB14" class="w-2 h-2 rounded-full"></div>
        <p>Бюджет: {cost}</p>
       </div>
       `;
      chart.data = chartData;

      chart.language.locale = am4lang_ru_RU;
    },
  },
};
</script>
