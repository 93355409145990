<template>
  <div>
    <div
      id="deleteModal"
      class="delete-modal fixed top-0 left-0 h-full w-full flex justify-center items-center z-40"
      @click="closeDeleteModal"
    >
      <div class="delete-modal__content bg-white rounded-4" @click.stop>
        <div class="px-6 py-4 flex items-center justify-between border-b border-gray-2 gap-4">
          <p class="font-semibold">{{ text }}</p>
          <img
            width="18"
            height="18"
            alt="close"
            src="@/assets/images/views/campaign-card/close.svg"
            class="cursor-pointer"
            @click="closeDeleteModal"
          />
        </div>
        <slot />
        <div class="p-6 flex gap-4">
          <UiButton colorType="outline" class="flex-1" @click="closeDeleteModal">
            <slot name="decline" v-if="this.$slots.decline" />
            <span v-else>Отмена</span></UiButton
          >
          <UiButton class="flex-1" @click="emitApprove">
            <slot name="button" v-if="this.$slots.button" />
            <span v-else>Подтвердить</span>
          </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton.vue';

export default {
  name: 'DeleteModal',

  components: { UiButton },

  props: {
    text: {
      type: String,
      default: 'Подтвердить удаление',
    },
  },

  methods: {
    closeDeleteModal() {
      this.$emit('close');
    },

    emitApprove() {
      this.$emit('approve');
    },
  },
};
</script>

<style lang="scss">
.delete-modal {
  background: rgba(0, 0, 0, 0.65);

  &__content {
    width: 366px;
  }
}
</style>
