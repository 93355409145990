<template>
  <div
    style="padding: 0 8px"
    class="flex items-center justify-between hover:bg-gray-7 cursor-pointer"
    @click="handleFilterClick"
  >
    <div class="flex items-center gap-2">
      <p class="font-semibold">{{ title }}</p>
      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          v-show="(sort === currentSort && order === 'increase') || notSorted"
          d="M5.56574 3.65625H0.434487C0.280581 3.65625 0.194643 3.49375 0.289956 3.38281L2.85558 0.407813C2.92902 0.322657 3.07042 0.322657 3.14464 0.407813L5.71027 3.38281C5.80558 3.49375 5.71964 3.65625 5.56574 3.65625Z"
          fill="#BFBFBF"
        />
        <path
          v-show="(sort === currentSort && order === 'decrease') || notSorted"
          d="M5.56574 8.34375H0.434487C0.280581 8.34375 0.194643 8.50625 0.289956 8.61719L2.85558 11.5922C2.92902 11.6773 3.07042 11.6773 3.14464 11.5922L5.71027 8.61719C5.80558 8.50625 5.71964 8.34375 5.56574 8.34375Z"
          fill="#BFBFBF"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterIcon',

  props: {
    sort: {
      type: String,
      required: true,
    },

    currentSort: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      order: '',
    };
  },

  computed: {
    notSorted() {
      return this.currentSort !== this.sort;
    },
  },

  methods: {
    handleFilterClick() {
      if (this.sort !== this.currentSort) {
        this.order = 'decrease';
        this.$emit('filter-change', {
          sort: this.sort,
          order: this.order,
        });
      } else if (this.order === 'decrease') {
        this.order = 'increase';
        this.$emit('filter-change', {
          sort: this.sort,
          order: this.order,
        });
      } else if (this.order === 'increase') {
        this.order = '';
        this.$emit('filter-change', {
          sort: null,
          order: null,
        });
      }
    },
  },
};
</script>
