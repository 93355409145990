<template>
  <div class="min-h-screen confirm-password relative pb-5 flex flex-col">
    <img src="@/assets/images/views/sign-in/background.svg" class="w-full h-full object-cover absolute top-0 left-0" />
    <img width="128" src="@/assets/images/logotype.svg" class="mx-auto top-14 absolute left-0 right-0" />
    <div class="confirm-password__content relative z-1">
      <div class="confirm-password__form-wrapper pb-6 pt-4 bg-white rounded-16 mx-auto">
        <p class="font-semibold text-gray px-6">Подтверждение пароля</p>
        <div class="w-full h-px mt-4 bg-gray-2"></div>
        <div class="px-6 mt-4">
          <UiInput
            v-model="form.password"
            :colorType="passwordError ? 'error' : 'primary'"
            placeholder="Введите пароль"
            class="mt-6"
          />
          <p v-if="passwordError" class="mt-1 text-red-1 text-sm leading-5">Пароль должен быть не короче 6 символов</p>
          <UiButton class="mt-4" @click="updatePassword">
            <span v-if="!loading">Установить пароль</span>
            <LoadingSpinner v-else />
          </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePassword } from '@/api';

import UiInput from '@/components/ui/UiInput';
import UiButton from '@/components/ui/UiButton';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

export default {
  name: 'ConfirmPassword',

  components: { UiInput, UiButton, LoadingSpinner },

  data() {
    return {
      form: {
        password: '',
      },

      passwordError: false,

      loading: false,
    };
  },

  computed: {
    passwordToken() {
      return this.$store.state.auth.passwordToken;
    },
  },

  watch: {
    'form.password'() {
      this.passwordError = false;
    },
  },

  methods: {
    async updatePassword() {
      this.validate();
      if (this.passwordError) return;

      this.loading = true;
      try {
        await updatePassword({ ...this.form, token: this.passwordToken });
        this.$store.dispatch('setPopupDisplay', { display: true, text: 'Пароль успешно установлен' });
        this.$router.push('/sign-in');
      } catch {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Ошибка при установлении пароля',
          status: 'error',
        });
        console.error('[RecoverPassword error]');
      } finally {
        this.loading = false;
      }
    },

    validate() {
      if (this.form.password.length < 6) this.passwordError = true;
    },
  },
};
</script>

<style lang="scss">
.confirm-password {
  background: url('@/assets/images/views/sign-in/background.svg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &__form-wrapper {
    width: 366px;
    box-shadow: 0px 60px 80px 0px #00000029;
  }
  &__content {
    padding-top: 233px;
  }
}
</style>
