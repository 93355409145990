<template>
  <div class="recover-password min-h-screen relative pb-5 flex flex-col">
    <!-- <img src="@/assets/images/views/sign-in/background.svg" class="w-full h-full object-cover absolute top-0 left-0" /> -->
    <img width="128" src="@/assets/images/logotype.svg" class="mx-auto top-14 absolute left-0 right-0" />
    <div class="recover-password__content relative z-1">
      <div class="recover-password__form-wrapper pb-6 pt-4 bg-white rounded-16 mx-auto">
        <p class="font-semibold text-gray px-6">Восстановление пароля</p>
        <div class="w-full h-px mt-4 bg-gray-2"></div>
        <div class="px-6 mt-4">
          <UiInput
            :disabled="isLetterSent"
            v-model="form.email"
            :colorType="emailError ? 'error' : 'primary'"
            placeholder="Электронная почта"
            class="mt-6"
          />
          <p v-if="emailError" class="mt-1 text-red-1 text-sm leading-5">Введен некорректный Email</p>
          <UiButton :disabled="isLetterSent" class="mt-4" @click="recoverPassword">
            <span v-if="!loading">Сбросить текущий пароль</span> <LoadingSpinner v-else />
          </UiButton>
          <div
            v-if="recoverError"
            class="mt-4 rounded-4 border border-blue-3 bg-blue-2 p-4 text-gray-6 text-sm leading-5"
          >
            Электронная почта не найдена
          </div>
          <div
            v-if="isLetterSent"
            class="mt-4 rounded-4 border border-blue-3 bg-blue-2 p-4 text-gray-6 text-sm leading-5"
          >
            Письмо с восстановлением пароля отправлено на вашу почту
          </div>
        </div>
        <div class="w-full h-px mt-6 bg-gray-2"></div>
        <div class="px-6">
          <p class="mt-4 text-sm leading-6 text-center">
            Вспомнили пароль?
            <router-link to="/sign-in" class="text-blue hover:text-blue-hover active:text-blue-active cursor-pointer">
              Войти
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { recoverPassword } from '@/api';

import UiInput from '@/components/ui/UiInput';
import UiButton from '@/components/ui/UiButton';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

export default {
  name: 'RecoverPassword',

  components: { UiInput, UiButton, LoadingSpinner },

  data() {
    return {
      form: {
        email: '',
      },

      emailError: false,
      recoverError: false,
      isLetterSent: false,

      loading: false,
    };
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.emailError = false;
        this.recoverError = false;
      },
    },
  },

  methods: {
    async recoverPassword() {
      this.validate();
      if (this.emailError) return;

      this.loading = true;

      try {
        await recoverPassword(this.form);
        this.isLetterSent = true;
      } catch {
        console.error('[RecoverPassword error]');
        this.recoverError = true;
      } finally {
        this.loading = false;
      }
    },

    validate() {
      if (!this.form.email || !/^\S+@\S+\.\S+$/.test(this.form.email)) this.emailError = true;
    },
  },
};
</script>

<style lang="scss">
.recover-password {
  background: url('@/assets/images/views/sign-in/background.svg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  &__form-wrapper {
    width: 366px;
    box-shadow: 0px 60px 80px 0px #00000029;
  }
  &__content {
    padding-top: 233px;
  }
}
</style>
