<template>
  <div class="bg-white p-6 pb-12">
    <p style="font-size: 30px" class="leading-10 font-semibold text-gray">Контент</p>
    <div v-if="adInfo.content_item?.content_files?.length" class="mt-8 grid grid-cols-4 gap-6">
      <div v-for="video in adInfo.content_item.content_files" :key="video.width" class="content__card">
        <div class="content__image relative bg-black cursor-pointer">
          <video
            controls
            muted
            loop
            :src="video.path"
            :poster="video.preview"
            class="h-full w-full object-contain object-center"
          />
        </div>
        <div class="p-4 pt-5">
          <p class="font-semibold text-gray">
            {{ `${video.data.width} x ${video.data.height} PX` }}
          </p>
          <div class="grid grid-cols-3 mt-2">
            <p class="text-gray-3 col-span-2 text-sm leading-6">Кол-во экранов</p>
            <p class="text-gray text-sm leading-6">
              {{ 1313 }}
            </p>
          </div>
          <div class="grid grid-cols-3 mt-1">
            <p class="text-gray-3 col-span-2 text-sm leading-6">Длительность ролика</p>
            <p class="text-gray text-sm leading-6">{{ video.data.duration.toFixed(2) }} сек</p>
          </div>
        </div>
      </div>
    </div>
    <div
      style="height: 600px"
      v-else
      class="leading-10 text-2xl leading-8 font-medium text-gray-3 flex justify-center items-center"
    >
      Контент не загружен
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignContent',

  props: {
    adInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.content {
  &__card {
    box-shadow: 0px 1px 4px 0px #00000014;
  }

  &__image {
    height: 278px;
  }
}
</style>
