var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center items-center rounded-4",class:[
    _vm.heightClass,
    _vm.colorClass,
    {
      'gap-1.5': _vm.$slots.right,
      'cursor-pointer': !_vm.disabled,
    },
  ],on:{"click":_vm.handleClick}},[(!_vm.loading)?[_vm._t("right"),_vm._t("default"),_vm._t("left")]:_c('LoadingSpinner')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }