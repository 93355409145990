<template>
  <div class="moderation-modal fixed inset-0 z-1" @click="closeModal">
    <div class="flex items-center justify-center h-full">
      <div class="moderation-modal__content rounded-4 bg-white" @click.stop>
        <div class="px-6 py-4 flex items-center justify-between">
          <p class="text-gray font-semibold">Выбрано {{ selectedVideos.length }} из {{ videos.length }} роликов</p>
          <img
            width="24"
            height="24"
            src="@/assets/images/ui/ui-cross.svg"
            class="cursor-pointer"
            @click="closeModal"
          />
        </div>
        <div class="h-px bg-gray-2"></div>
        <div class="p-6">
          <UiDropdown v-model="currentReason" :variants-list="reasonList" />
          <UiInput v-model="description" placeholder="Введите подробное описание" text-area class="mt-4" />
          <div class="flex items-center gap-5 mt-6">
            <UiButton color-type="outline" button-height="md" class="flex-1" @click="closeModal"> Отмена </UiButton>
            <UiButton button-height="md" class="flex-1" @click="moderateContent">
              <span v-if="!loading">Сохранить</span>
              <LoadingSpinner v-else />
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { moderateContent } from '@/api';

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';

export default {
  name: 'ModerationModal',

  components: { UiDropdown, UiButton, UiInput, LoadingSpinner },

  props: {
    adInfo: {
      type: Object,
      default: () => ({}),
    },

    videos: {
      type: Array,
      default: () => [],
    },

    selectedVideos: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      reasonList: [
        {
          title: 'Не подходит содержание контента',
          value: 'content',
        },
        {
          title: 'Контент содержит запрещенные материалы',
          value: 'prohibited',
        },
        {
          title: 'Контент содержит оскорбление личности',
          value: 'offense',
        },
      ],

      currentReason: {
        title: 'Не подходит содержание контента',
        value: 'content',
      },

      description: '',

      loading: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    async moderateContent() {
      this.loading = true;
      const declined = {};
      this.selectedVideos.forEach((key) => {
        declined[key] = this.currentReason.title + '. ' + this.description;
      });
      try {
        await moderateContent(this.adInfo.id, { approved: [], declined });
        this.$emit('close');
        this.$emit('update-content');
      } catch {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Произошла ошибка при модерации контента',
          status: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.moderation-modal {
  background: rgba(0, 0, 0, 0.65);

  &__content {
    width: 366px;
  }
}
</style>
