<template>
  <div class="user-list pb-20">
    <p class="text-2xl leading-8 font-semibold text-gray">Пользователи</p>
    <div class="mt-4 bg-white">
      <div class="py-3 pr-4 pl-6 flex items-center justify-between">
        <UiInput v-model="searchValue" placeholder="Поиск по имени, email" input-height="sm" class="w-64">
          <template v-slot:left>
            <img src="@/assets/images/views/profile/search.svg" />
          </template>
        </UiInput>
        <UiButton color-type="outline" button-height="sm" class="px-4" @click="openAddUser">
          Добавить пользователя
        </UiButton>
      </div>
      <div class="flex h-12 items-center bg-gray-8 text-gray border-b border-gray-7" @mousedown.prevent>
        <FilterIcon
          sort="name"
          :current-sort="currentSort"
          title="Имя"
          class="user-list__name-col text-sm h-full px-4"
          @filter-change="handleFilterChange"
        />
        <FilterIcon
          sort="email"
          :current-sort="currentSort"
          title="Email"
          class="flex-1 text-sm h-full px-4"
          @filter-change="handleFilterChange"
        />
        <FilterIcon
          sort="role"
          :current-sort="currentSort"
          title="Роль"
          class="user-list__role-col text-sm h-full px-4"
          @filter-change="handleFilterChange"
        />
        <div
          class="user-list__status-col relative text-sm leading-6 flex items-center justify-between h-full px-4 active:bg-gray-9 cursor-pointer"
          :class="isStatusModalVisible ? 'bg-gray-9' : 'hover:bg-gray-7'"
          @click="handleStatusColClick"
        >
          <p class="font-semibold">Статус</p>
          <img width="12" height="12" src="@/assets/images/views/profile/filter.svg" />
          <div
            v-show="isStatusModalVisible"
            ref="statusModal"
            tabIndex="0"
            class="user-list__status-modal absolute top-14 right-0 bg-white rounded-4 outline-none z-3"
            @blur="applyStatuses"
            @click.stop
          >
            <div class="p-2">
              <div v-for="status in statuses" :key="status.key" class="flex h-8 gap-2 items-center pr-2">
                <UiCheckbox :value="status.value" @input="handleCheckboxInput($event, status)" />
                <p class="text-sm leading-6 text-gray-6">{{ status.title }}</p>
              </div>
              <div class="h-px bg-gray-7 my-2"></div>
              <div class="flex items-center justify-between">
                <p class="text-blue cursor-pointer text-sm leading-6" @click="applyStatuses">ОК</p>
                <p class="text-blue cursor-pointer text-sm leading-6" @click="resetStatuses">Сбросить</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 480px" class="relative">
        <transition name="user-plug">
          <div v-show="loading" style="background: rgba(80, 80, 80, 0.26); z-index: 2" class="absolute inset-0">
            <div class="h-full flex justify-center items-center">
              <LoadingSpinner />
            </div>
          </div>
        </transition>
        <div
          v-for="(user, index) in users"
          :key="index"
          class="flex h-12 border-b border-gray-7 items-center bg-white text-gray-6 text-sm leading-6"
        >
          <div class="user-list__name-col h-full px-4 flex items-center">
            {{ user.profile.first_name + ' ' + user.profile.last_name }}
          </div>
          <div class="flex-1 h-full px-4 flex items-center">{{ user.email }}</div>
          <div class="user-list__role-col h-full px-4 flex items-center">{{ user.manager ? 'Менеджер' : 'Админ' }}</div>
          <div class="user-list__status-col flex items-center justify-between h-full px-4">
            <p>
              <span v-if="user.state === 'active'" class="text-green-3">Активен</span>
              <span v-else-if="user.state === 'pending'" class="text-yellow">Ожидает активации</span>
              <span v-else class="text-red-1">Заблокирован</span>
            </p>
            <div
              class="relative outline-none"
              :class="`user-list__context-button_${index}`"
              tabindex="0"
              @mousedown.prevent
              @blur="user.isMenuOpen = false"
            >
              <UiButton
                :disabled="user.id === $store.state.auth.userInfo.id || user.state === 'deleted'"
                colorType="outline"
                button-height="xs"
                class="w-6"
                @click="handleContextButtonClick(user, index)"
              >
                <img src="@/assets/images/views/advertising-campaigns/dots.svg" />
              </UiButton>
              <UserContextMenu
                v-if="user.id !== $store.state.auth.userInfo.id && user.state !== 'deleted'"
                v-show="user.isMenuOpen"
                :user-info="user"
                class="absolute right-8"
                @manager="updateUserStatus(user, true)"
                @admin="updateUserStatus(user, false)"
                @invite="repeatInvite(user)"
                @block="blockUser(user)"
                @close="user.isMenuOpen = false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <UiPagination :pages-amount="pagesAmount" :current-page="currentPage" class="mt-6" @changePage="handleChangePage" />
    <AddUserModal v-show="isAddUserVisible" @close="closeAddUser" @added="handleAddUser" />
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getUsers, repeatInvite, blockUser, updateUserStatus } from '@/api';

import UiButton from '@/components/ui/UiButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import FilterIcon from '@/components/views/AdvertisingCampaigns/FilterIcon.vue';
import UserContextMenu from '@/components/views/Profile/UserContextMenu.vue';
import AddUserModal from '@/components/views/Profile/AddUserModal.vue';

export default {
  name: 'UserList',

  components: {
    UiButton,
    LoadingSpinner,
    UiInput,
    FilterIcon,
    UiCheckbox,
    UiPagination,
    UserContextMenu,
    AddUserModal,
  },

  data() {
    return {
      searchValue: '',
      prevSearchValue: '',
      searchTimeout: null,

      isStatusModalVisible: false,

      statuses: [
        {
          title: 'Активен',
          key: 'active',
          value: true,
        },
        {
          title: 'Заблокирован',
          key: 'deleted',
          value: true,
        },
        {
          title: 'Ожидает активации',
          key: 'pending',
          value: true,
        },
      ],

      currentSort: 'none',
      currentOrder: 'none',

      currentPage: 1,
      usersPerPage: 10,

      loading: false,

      users: [],
      usersAmount: 0,

      isAddUserVisible: false,
    };
  },

  computed: {
    pagesAmount() {
      if (this.usersAmount) return 1;
      return Math.ceil(this.usersAmount / this.usersPerPage);
    },
  },

  watch: {
    currentPage() {
      this.getUsers();
    },

    searchValue() {
      this.currentPage = 1;
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        if (this.prevSearchValue === this.searchValue) return;
        this.prevSearchValue = this.searchValue;
        this.getUsers();
      }, 650);
    },
  },

  async mounted() {
    this.getUsers();
  },

  methods: {
    handleStatusColClick() {
      this.isStatusModalVisible = true;
      this.$nextTick(() => {
        this.$refs.statusModal.focus();
      });
    },

    applyStatuses(event) {
      this.isStatusModalVisible = false;
      if (event.type === 'blur') this.getUsers();
    },

    resetStatuses() {
      this.statuses.forEach((status) => {
        status.value = true;
      });
      this.isStatusModalVisible = false;
    },

    handleCheckboxInput(event, status) {
      if (!event && this.statuses.filter((status) => status.value).length <= 1) return;
      status.value = event;
    },

    handleFilterChange(value) {
      this.currentSort = value.sort ? value.sort : 'none';
      this.currentOrder = value.order ? value.order : 'none';
    },

    handleChangePage(value) {
      this.currentPage = value;
    },

    handleContextButtonClick(user, index) {
      user.isMenuOpen = !user.isMenuOpen;
      this.$nextTick(() => {
        document.querySelector(`.user-list__context-button_${index}`).focus();
      });
    },

    closeAddUser() {
      const el = document.querySelector('#addUser');
      enableBodyScroll(el);
      this.isAddUserVisible = false;
    },

    openAddUser() {
      this.isAddUserVisible = true;
      const el = document.querySelector('#addUser');
      disableBodyScroll(el);
    },

    handleAddUser() {
      this.closeAddUser();
      this.getUsers();
    },

    async getUsers() {
      this.loading = true;
      try {
        const params = new URLSearchParams();

        params.append('limit', this.usersPerPage);
        params.append('offset', this.usersPerPage * (this.currentPage - 1));
        params.append('s', this.searchValue);

        this.statuses
          .filter((status) => status.value)
          .forEach((item) => {
            params.append('state', item.key);
          });

        const res = await getUsers(params);
        this.users = res.data.objects.map((item) => ({ ...item, isMenuOpen: false }));
        this.usersAmount = res.data.meta.total;
      } catch (err) {
        console.error('[GetUsers Error]');
        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    async repeatInvite(user) {
      this.loading = true;
      try {
        await repeatInvite(user.id);
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Повторное приглашение отправлено.',
        });
      } catch (err) {
        console.error(err);
        console.error('[RepeatInvite Error]');
      } finally {
        this.loading = false;
      }
    },

    async blockUser(user) {
      this.loading = true;
      try {
        await blockUser(user.id);
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Пользователь заблокирован.',
        });
        this.getUsers();
      } catch (err) {
        console.error(err);
        console.error('[BlockUser Error]');
      }
    },

    async updateUserStatus(user, isManager) {
      this.loading = true;
      try {
        await updateUserStatus(user.id, { manager: isManager });
        this.getUsers();
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: `Пользователю присвоен статус ${isManager ? 'менеджера' : 'админа'}.`,
        });
      } catch (err) {
        console.error(err);
        console.error('[AdminStatus Error]');
      }
    },
  },
};
</script>

<style lang="scss">
.user-list {
  &__name-col {
    width: 26%;
  }

  &__role-col {
    width: 15.5%;
  }

  &__status-col {
    width: 24%;
  }

  &__status-modal {
    box-shadow: 0px 2px 8px 0px #00000026;
  }
}

.user-plug {
  &-leave-active,
  &-enter-active {
    transition: all 0.5s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
