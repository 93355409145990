var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center gap-3"},_vm._l((_vm.steps),function(step){return _c('div',{key:step.title,staticClass:"flex gap-3 items-center"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('div',{staticClass:"w-6 h-6 rounded-full text-sm flex justify-center items-center",class:{
          'bg-blue text-white': step.order === _vm.currentStep,
          'bg-transparent border border-blue': step.order < _vm.currentStep,
          'bg-transparent border border-gray-5 text-gray-5': step.order > _vm.currentStep,
        }},[(step.order >= _vm.currentStep)?_c('p',[_vm._v(_vm._s(step.order))]):_c('img',{attrs:{"src":require("@/assets/images/views/create-campaign/checked.svg")}})]),_c('div',{staticClass:"relative"},[_c('p',{staticClass:"text-sm absolute top-0 left-0",class:{
            'text-gray-6': step.order < _vm.currentStep,
            'text-black font-semibold': step.order === _vm.currentStep,
            'text-gray-5': step.order > _vm.currentStep,
          }},[_vm._v(" "+_vm._s(step.title)+" ")]),_c('p',{staticClass:"opacity-0 font-semibold text-sm"},[_vm._v(_vm._s(step.title))])])]),(step.order !== 3)?_c('div',{staticClass:"w-20 h-px bg-gray-5"}):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }