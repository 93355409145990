<template>
  <div class="pb-16">
    <div class="h-16 bg-white flex justify-between items-center px-4">
      <p class="font-semibold text-gray">История операций</p>
      <UiButton button-height="sm" class="px-4" @click="openCreateBill"> Выставить мне счет </UiButton>
    </div>
    <div class="flex h-12 items-center bg-gray-8 text-gray" @mousedown.prevent>
      <div class="operation-history__date-col text-sm h-full px-4 text-sm leading-6 flex items-center font-semibold">
        Дата
      </div>
      <div class="flex-1 text-sm h-full px-4 text-sm leading-6 flex items-center font-semibold">Операция</div>
      <div class="operation-history__amount-col text-sm h-full px-4 text-sm leading-6 flex items-center font-semibold">
        Сумма, руб.
      </div>
      <div
        class="operation-history__status-col relative text-sm leading-6 flex items-center justify-between h-full px-4 active:bg-gray-9 cursor-pointer"
        :class="isStatusModalVisible ? 'bg-gray-9' : 'hover:bg-gray-7'"
        @click="handleStatusColClick"
      >
        <p class="font-semibold">Статус</p>
        <img width="12" height="12" src="@/assets/images/views/profile/filter.svg" />
        <div
          v-show="isStatusModalVisible"
          ref="statusModal"
          tabIndex="0"
          class="operation-history__status-modal absolute top-14 right-0 bg-white rounded-4 outline-none z-3"
          @blur="isStatusModalVisible = false"
          @click.stop
        >
          <div class="p-2">
            <div v-for="status in statuses" :key="status.key" class="flex h-8 gap-2 items-center pr-2">
              <UiCheckbox :value="status.value" @input="handleCheckboxInput($event, status)" />
              <p class="text-sm leading-6 text-gray-6">{{ status.title }}</p>
            </div>
            <div class="h-px bg-gray-7 my-2"></div>
            <div class="flex items-center justify-between">
              <p class="text-blue cursor-pointer text-sm leading-6" @click="applyStatuses">ОК</p>
              <p class="text-blue cursor-pointer text-sm leading-6" @click="resetStatuses">Сбросить</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="min-height: 480px" class="relative bg-white">
      <transition name="campaigns-plug">
        <div
          v-show="loading"
          style="background: rgba(80, 80, 80, 0.26); z-index: 5; top: 78px"
          class="absolute inset-0"
        >
          <div class="w-full h-full flex justify-center items-center">
            <LoadingSpinner />
          </div>
        </div>
      </transition>
      <div
        v-for="record in operationHistory"
        :key="record.invoice_path"
        class="flex py-3 border-t border-gray-7 items-center bg-white text-gray-6 text-sm leading-6"
      >
        <div class="operation-history__date-col h-full px-4 flex items-center">
          {{ formatDate(new Date(record.created_at)) }}
        </div>
        <div class="flex-1 h-full px-4 flex items-center">
          <a
            v-if="record.class === 'not_paid'"
            target="_blank"
            :href="record.invoice_path"
            class="text-blue"
          >
            Выставлен счёт на сумму {{ record.invoice_amount / 100 }} руб.
          </a>
          <span v-else-if="record.amount > 0">Пополнение баланса на сумму {{ record.amount / 100 }} руб.</span>
          <span v-else>Списание с баланса на сумму {{ record.amount }} руб.</span>
        </div>
        <div class="operation-history__amount-col h-full px-4 flex items-center">
          <span v-if="record.class === 'not_paid'" class="text-gray">{{ record.invoice_amount / 100 }}</span>
          <span v-else-if="record.amount > 0" class="text-green-3">+{{ record.amount / 100 }}</span>
          <span v-else class="text-red-1">{{ record.amount }}</span>
        </div>
        <div class="operation-history__status-col flex items-center justify-between h-full px-4">
          <span v-if="record.class === 'write_off'">Списание</span>
          <span v-else-if="record.class === 'paid'">Оплачено</span>
          <span v-else>Не оплачено</span>
        </div>
      </div>
    </div>
    <UiPagination :pages-amount="pagesAmount" :current-page="currentPage" class="mt-6" @changePage="handleChangePage" />
  </div>
</template>

<script>
const weekDays = {
  0: 'Вс',
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб',
};

import UiButton from '@/components/ui/UiButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiPagination from '@/components/ui/UiPagination.vue';

export default {
  name: 'OperationHistory',

  components: { UiButton, UiCheckbox, UiPagination, LoadingSpinner },

  props: {
    operationHistory: {
      type: Array,
      default: () => [],
    },

    metaData: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },

    currentPage: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      isStatusModalVisible: false,

      statuses: [
        {
          title: 'Оплачено',
          key: 'paid',
          value: true,
        },
        {
          title: 'Не оплачено',
          key: 'not_paid',
          value: true,
        },
      ],

      currentSort: 'none',
      currentOrder: 'none',

      recordsPerPage: 20,
    };
  },

  computed: {
    pagesAmount() {
      if (!this.metaData?.total) return 1;
      return Math.ceil(this.metaData.total / this.recordsPerPage);
    },
  },

  watch: {
    statuses: {
      deep: true,
      handler() {
        const statuses = this.statuses.filter((status) => status.value).map((item) => item.key);
        this.$emit('update-statuses', statuses);
      },
    },
  },

  methods: {
    handleStatusColClick() {
      this.isStatusModalVisible = true;
      this.$nextTick(() => {
        this.$refs.statusModal.focus();
      });
    },

    applyStatuses() {
      this.isStatusModalVisible = false;
    },

    resetStatuses() {
      this.statuses.forEach((status) => {
        status.value = true;
      });

      this.isStatusModalVisible = false;
    },

    handleCheckboxInput(event, status) {
      if (!event && this.statuses.filter((status) => status.value).length <= 1) return;
      status.value = event;
    },

    handleFilterChange(value) {
      this.currentSort = value.sort ? value.sort : 'none';
      this.currentOrder = value.order ? value.order : 'none';
    },

    handleChangePage(value) {
      this.$emit('change-page', value);
    },

    openCreateBill() {
      this.$emit('open-create-bill');
    },

    formatDate(date) {
      if (!date) return '';
      return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}.${
        date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      }.${date.getFullYear()} ${weekDays[date.getDay()]}`;
    },
  },
};
</script>

<style lang="scss">
.operation-history {
  &__date-col {
    width: 15%;
  }

  &__amount-col {
    width: 15.5%;
  }

  &__status-col {
    width: 22.8%;
  }

  &__status-modal {
    box-shadow: 0px 2px 8px 0px #00000026;
  }
}
</style>
