<template>
  <div class="fixed h-full w-full top-0 left-0 time-target flex justify-center items-center z-10" @click="cancelTarget">
    <div id="timeTarget" class="time-target__wrapper bg-white px-4 pt-6 pb-4" @click.stop>
      <div class="flex items-center justify-between">
        <p class="text-2xl leading-8 font-semibold text-gray">Временной таргет</p>
        <img src="@/assets/images/views/create-campaign/cross.svg" class="cursor-pointer" @click="cancelTarget" />
      </div>
      <div class="mt-4">
        <div class="flex items-center">
          <div
            class="time-target__day-wrapper cursor-pointer text-sm h-8 border-t border-l border-gray-7 flex justify-center items-center"
            :class="isAllChecked ? 'text-red-1' : 'text-blue'"
            @click="handleAllHoursClick"
            @mousedown.prevent
          >
            {{ isAllChecked ? 'Очистить' : 'Выбрать все' }}
          </div>
          <div
            v-for="hour in hours"
            :key="hour"
            class="h-8 w-8 border-t border-l border-gray-7 flex justify-center items-center text-gray text-sm cursor-pointer"
            :class="{ 'border-r': hour === '23' }"
            @click="handleHoursClick(hour)"
          >
            {{ hour < 10 ? '0' + hour : hour }}
          </div>
        </div>
        <div v-for="(day, index) in days" :key="day.title" class="flex items-center">
          <div
            class="time-target__day-wrapper cursor-pointer text-sm h-8 border-t border-l border-gray-7 text-gray flex items-center px-3"
            :class="{ 'border-b': index === 6 }"
            @click="handleDayClick(day)"
          >
            {{ day.title }}
          </div>
          <div
            v-for="hour in hours"
            :key="hour"
            class="h-8 w-8 border-t border-l border-gray-7 flex justify-center items-center text-gray text-sm"
            :class="{ 'border-b': index === 6, 'border-r': hour === '23' }"
          >
            <div
              class="w-5 h-5 rounded-3 cursor-pointer"
              :class="
                daysTime[day.key]?.includes(hour)
                  ? 'bg-blue'
                  : `${day.key === 'saturday' || day.key === 'sunday' ? 'bg-gray-2' : 'bg-gray-4'}`
              "
              @click="handleHourClick(day, hour)"
            ></div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end mt-4 gap-6">
        <UiButton colorType="outline" class="px-4" @click="cancelTarget">Отмена</UiButton>
        <UiButton class="px-4" @click="saveTarget">Сохранить</UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import _uniq from 'lodash/uniq';
import _cloneDeep from 'lodash/cloneDeep';
import UiButton from '@/components/ui/UiButton';
export default {
  name: 'TimeTarget',
  components: { UiButton },

  props: {
    editForm: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      prevDaysTime: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },

      daysTime: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },

      days: [
        { key: 1, title: 'Понедельник' },
        { key: 2, title: 'Вторник' },
        { key: 3, title: 'Среда' },
        { key: 4, title: 'Четверг' },
        { key: 5, title: 'Пятница' },
        { key: 6, title: 'Суббота' },
        { key: 7, title: 'Воскресенье' },
      ],

      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    };
  },

  computed: {
    isAllChecked() {
      let isAllChecked = true;
      Object.keys(this.daysTime).forEach((key) => {
        if (this.daysTime[key].length < 24) isAllChecked = false;
      });
      return isAllChecked;
    },
  },

  watch: {
    editForm: {
      deep: true,
      handler() {
        this.handleEditForm();
      },
    },
  },

  methods: {
    handleHourClick(day, hour) {
      if (!this.daysTime[day.key]?.includes(hour)) this.daysTime[day.key].push(hour);
      else this.unChooseHour(day, hour);
      this.formatDayArray();
    },

    handleHoursClick(hour) {
      let isAllDaysChecked = true;
      Object.keys(this.daysTime).map((key) => {
        if (!this.daysTime[key]?.includes(hour)) isAllDaysChecked = false;
      });
      if (!isAllDaysChecked) {
        Object.keys(this.daysTime).forEach((key) => {
          this.daysTime[key].push(hour);
          this.formatDayArray();
        });
      } else {
        this.days.forEach((day) => {
          this.unChooseHour(day, hour);
          this.formatDayArray();
        });
      }
    },

    handleDayClick(day) {
      if (this.daysTime[day.key].length < 24)
        this.hours.forEach((hour) => {
          this.daysTime[day.key].push(hour);
        });
      else this.daysTime[day.key].splice(0, 24);
      this.formatDayArray();
    },

    unChooseHour(day, hour) {
      this.daysTime[day.key].splice(this.daysTime[day.key].indexOf(hour), 1);
    },

    formatDayArray() {
      this.days.forEach((day) => {
        this.daysTime[day.key] = _uniq(this.daysTime[day.key]);
        this.daysTime[day.key] = this.daysTime[day.key].sort((a, b) => a - b);
      });
    },

    resetDaysArray() {
      this.days.forEach((day) => {
        this.daysTime[day.key].splice(0, 24);
      });
    },

    saveTarget() {
      this.$emit('close', this.daysTime);
    },

    cancelTarget() {
      this.$emit('close', this.prevDaysTime);
      this.daysTime = _cloneDeep(this.prevDaysTime);
    },

    savePrevState() {
      this.prevDaysTime = _cloneDeep(this.daysTime);
    },

    handleEditForm() {
      Object.keys(this.daysTime).forEach((key) => {
        this.daysTime[key] = this.editForm.weekdays_hours[key] ? [...this.editForm.weekdays_hours[key]] : [];
      });

      this.saveTarget();
    },

    handleAllHoursClick() {
      if (this.isAllChecked) {
        Object.keys(this.daysTime).forEach((key) => {
          this.daysTime[key] = [];
        });
      } else {
        Object.keys(this.daysTime).forEach((key) => {
          this.daysTime[key] = [...this.hours];
        });
      }
    },
  },
};
</script>

<style lang="scss">
.time-target {
  background: rgba(0, 0, 0, 0.65);

  &__day-wrapper {
    width: 120px;
  }
}
</style>
