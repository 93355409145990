<template>
  <div class="flex justify-end items-center gap-2">
    <div
      class="bg-white flex justify-center items-center w-8 h-8 rounded-4 border border-gray-5 cursor-pointer"
      :class="{ 'ui-pagination__button_disabled': currentPage === 1 }"
      @click="prevPage(currentPage)"
    >
      <img width="6.5" height="10" src="@/assets/images/ui/pagination/arrow-left.svg" />
    </div>
    <div
      v-for="page in pagesAmount"
      :key="page"
      class="bg-white flex text-sm justify-center items-center w-8 h-8 rounded-4 border cursor-pointer"
      :class="currentPage === page ? 'border-blue text-blue' : 'border-gray-5 text-gray-6'"
      @click="$emit('changePage', page)"
    >
      {{ page }}
    </div>
    <div
      class="bg-white flex justify-center items-center w-8 h-8 rounded-4 border border-gray-5 cursor-pointer"
      :class="{ 'ui-pagination__button_disabled': currentPage === pagesAmount }"
      @click="nextPage(currentPage)"
    >
      <img width="6.5" height="10" src="@/assets/images/ui/pagination/arrow-right.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiPagination',
  props: {
    pagesAmount: {
      type: Number,
      default: 0,
    },
    
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    prevPage(page) {
      if (page === 1) return;
      this.$emit('changePage', page - 1);
    },
    nextPage(page) {
      if (page === this.pagesAmount) return;
      this.$emit('changePage', page + 1);
    },
  },
};
</script>

<style lang="scss">
.ui-pagination {
  &__button {
    &_disabled img {
      filter: invert(32%) sepia(16%) saturate(0%) hue-rotate(142deg) brightness(92%) contrast(87%);
    }
  }
}
</style>
