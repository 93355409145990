<template>
  <div>
    <div
      class="context-menu w-max absolute right-14 flex rounded-4 flex-col gap-2.5 px-3 py-1 bg-white"
      :class="[index > 7 ? 'bottom-2' : 'top-2']"
    >
      <div v-for="item in menuItems" :key="item.key" class="h-8 w-full text-sm text-gray-6 leading-5 cursor-pointer">
        <p v-if="item.key === 'edit'" class="w-full h-full flex items-center hover:text-blue" @click="openEditPage">
          {{ item.title }}
        </p>
        <div
          v-else-if="item.key === 'delete'"
          class="w-full h-full flex items-center hover:text-blue"
          @click="openDeleteModal"
        >
          <p>{{ item.title }}</p>
        </div>
        <div
          v-else-if="item.key === 'show'"
          class="w-full h-full flex items-center hover:text-blue"
          @click="goToCampaignCard(campaign.id)"
        >
          <p>{{ item.title }}</p>
        </div>
        <div
          v-else-if="item.key === 'pause_operator'"
          class="w-full h-full flex items-center hover:text-blue"
          @click="updateOperatorState('paused')"
        >
          <p>{{ item.title }}</p>
        </div>
        <div
          v-else-if="item.key === 'stop_operator'"
          class="w-full h-full flex items-center hover:text-blue"
          @click="updateOperatorState('stopped')"
        >
          <p>{{ item.title }}</p>
        </div>
        <div
          v-else-if="item.key === 'run_operator'"
          class="w-full h-full flex items-center hover:text-blue"
          @click="updateOperatorState('running')"
        >
          <p>{{ item.title }}</p>
        </div>
        <div
          v-else-if="item.key === 'pause'"
          class="w-full h-full flex items-center hover:text-blue"
          @click="pauseCampaign"
        >
          <p>{{ item.title }}</p>
        </div>
        <div
          v-else-if="item.key === 'rerun'"
          class="w-full h-full flex items-center hover:text-blue"
          @click="rerunCampaign"
        >
          <p>{{ item.title }}</p>
        </div>
        <div
          v-else-if="item.key === 'stop'"
          class="w-full h-full flex items-center hover:text-blue"
          @click="stopCampaign"
        >
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <DeleteModal
      v-show="isDeleteModalVisible"
      text="Перенести кампанию в архив?"
      ref="deleteModal"
      @close="closeDeleteModal"
      @approve="archiveCampaign"
      class="z-9"
    >
      <template v-slot:button>
        <span v-if="!loading">Подтвердить</span>
        <LoadingSpinner v-else />
      </template>
    </DeleteModal>
    <!-- @approve="deleteAd" -->
  </div>
</template>

<script>
import { moderateContent, archiveCampaign, pauseCampaign, stopCampaign, rerunCampaign } from '@/api';
import ClickOutside from 'vue-click-outside';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import DeleteModal from '@/components/ui/DeleteModal.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
  name: 'ContextMenu',

  components: { DeleteModal, LoadingSpinner },

  directives: { ClickOutside },

  props: {
    campaign: {
      type: Object,
      default: () => ({}),
    },

    index: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      isDeleteModalVisible: false,
      loading: false,
    };
  },

  computed: {
    menuItems() {
      let items = [];

      items.push({
        key: 'show',
        title: 'Просмотр',
      });

      if (this.campaign.state === 'deleted') return items;

      if (this.isAdvertiser) {
        if (this.campaign.contextual_state !== 'declined' && this.campaign.state === 'pending' && this.haveEditRights) {
          items.push({
            key: 'edit',
            title: 'Редактировать',
          });
        }

        if (
          !this.campaign.paused &&
          this.campaign.contextual_state !== 'declined' &&
          this.campaignNotFinished &&
          this.haveEditRights
        ) {
          items.push({
            key: 'pause',
            title: 'Поставить на паузу',
          });
        }

        if (this.campaign.paused && this.haveEditRights) {
          items.push({
            key: 'rerun',
            title: 'Возобновить',
          });
        }

        if (this.campaignNotFinished && this.haveEditRights) {
          items.push({
            key: 'stop',
            title: 'Остановить',
          });
        }

        if (this.campaign.state !== 'deleted' && this.campaign.state === 'finished' && this.haveEditRights) {
          items.push({
            key: 'delete',
            title: 'Перенести в архив',
          });
        }
      }

      if (!this.isAdvertiser) {
        if (this.campaign.contextual_state === 'running' || this.campaign.contextual_state === 'partial_running')
          items.push({ key: 'pause_operator', title: 'Поставить на паузу' });
        else if (this.campaign.contextual_state === 'paused') items.push({ key: 'run_operator', title: 'Возобновить' });
      }

      if (!this.isAdvertiser && this.campaign.contextual_state !== 'stopped')
        items.push({ key: 'stop_operator', title: 'Остановить' });

      return items;
    },

    isAdvertiser() {
      return this.$store.state.auth.organizationMode === 'advertiser';
    },

    campaignNotFinished() {
      return this.campaign.state !== 'deleted' && this.campaign.state !== 'finished';
    },

    haveEditRights() {
      return (
        this.campaign.managers.includes(this.$store.state.auth.userInfo.id) || !this.$store.state.auth.userInfo.manager
      );
    },
  },

  methods: {
    async updateOperatorState(value) {
      this.$store.commit('setPlugDisplay', true);
      try {
        await moderateContent(this.campaign.id, { state: value });
        this.closeContextMenu();
        this.$emit('update-campaigns');
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.commit('setPlugDisplay', false);
      }
    },

    async pauseCampaign() {
      this.$store.commit('setPlugDisplay', true);
      try {
        await pauseCampaign(this.campaign.id);
        this.$emit('update-campaigns');
        this.closeContextMenu();
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.commit('setPlugDisplay', false);
      }
    },

    async stopCampaign() {
      this.$store.commit('setPlugDisplay', true);
      try {
        await stopCampaign(this.campaign.id);
        this.$emit('update-campaigns');
        this.closeContextMenu();
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.commit('setPlugDisplay', false);
      }
    },

    async rerunCampaign() {
      this.$store.commit('setPlugDisplay', true);
      try {
        await rerunCampaign(this.campaign.id);
        this.closeContextMenu();
        this.$emit('update-campaigns');
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.commit('setPlugDisplay', false);
      }
    },

    closeContextMenu() {
      if (this.isDeleteModalVisible) return;
      this.$emit('close-menu');
    },

    openEditPage() {
      this.$router.push(`/edit-campaign/${this.campaign.id}`);
    },

    openDeleteModal() {
      disableBodyScroll(this.$refs.deleteModal);
      this.isDeleteModalVisible = true;
    },

    goToCampaignCard(id) {
      this.$router.push(`/advertising-campaigns/${id}`);
    },

    closeDeleteModal() {
      enableBodyScroll(this.$refs.deleteModal);
      this.isDeleteModalVisible = false;
      this.closeContextMenu();
    },

    async archiveCampaign() {
      if (this.loading) return;
      this.loading = true;
      try {
        await archiveCampaign(this.campaign.id);
        this.$emit('update-campaigns');
      } catch (err) {
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Ошибка при добавлении кампании в архив',
          status: 'error',
        });
      } finally {
        this.closeDeleteModal();
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.context-menu {
  box-shadow: 0px 2px 8px 0px #00000026;
}
</style>
