var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-input flex justify-between items-center placeholder-gray-5 rounded-4",class:[
    {
      border: !_vm.noBorder,
      primary: _vm.colorType === 'primary',
      error: _vm.colorType === 'error',
      'pr-2': _vm.$slots.default,
      'pl-2': _vm.$slots.left,
      'font-semibold text-gray': _vm.bold,
    },
    _vm.colorClass,
    _vm.heightClass,
  ]},[_vm._t("left"),(!_vm.textArea)?_c('input',{staticClass:"flex-1 h-full bg-transparent outline-none px-2.5",class:{ 'text-right': _vm.right },staticStyle:{"max-width":"100%"},attrs:{"disabled":_vm.disabled,"id":_vm.inputId ? _vm.inputId : null,"maxlength":_vm.maxLength,"name":_vm.inputName,"type":_vm.inputType,"placeholder":_vm.placeholder},domProps:{"value":_vm.formattedValue},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.handleInput}}):_c('textarea',{staticClass:"flex-1 h-full bg-transparent outline-none px-2.5 py-2.5",class:{ 'text-right': _vm.right },staticStyle:{"max-width":"100%","resize":"none"},attrs:{"rows":"2","disabled":_vm.disabled,"id":_vm.inputId ? _vm.inputId : null,"maxlength":_vm.maxLength,"type":_vm.inputType,"placeholder":_vm.placeholder},domProps:{"value":_vm.formattedValue},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.handleInput}}),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }