import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import system from './system';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    system,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
