<template>
  <div class="overflow-hidden">
    <div
      class="half-circle-spinner"
      :class="{
        blue: color === 'blue',
        black: color === 'black',
      }"
    >
      <div
        class="circle circle-1"
        :class="{
          blue: color === 'blue',
          black: color === 'black',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',

  props: {
    color: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 13) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: white;
  border-left-color: white;
  animation: half-circle-spinner-animation 1s infinite linear;
}

.half-circle-spinner .circle.circle-1 {
  &.blue {
    border-top-color: #13c2c2;
    border-left-color: #13c2c2;
  }

  &.black {
    border-top-color: black;
    border-left-color: black;
  }
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
