<template>
  <div
    class="flex justify-center items-center rounded-4"
    :class="[
      heightClass,
      colorClass,
      {
        'gap-1.5': $slots.right,
        'cursor-pointer': !disabled,
      },
    ]"
    @click="handleClick"
  >
    <template v-if="!loading">
      <slot name="right" />
      <slot />
      <slot name="left" />
    </template>
    <LoadingSpinner v-else />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
  name: 'UiButton',

  components: {
    LoadingSpinner,
  },

  props: {
    buttonHeight: {
      type: String,
      default: 'md',
    },

    colorType: {
      type: String,
      default: 'primary',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    heightClass() {
      if (this.buttonHeight === 'none') return '';
      if (this.buttonHeight === 'sm') return 'h-8 text-sm leading-5';
      if (this.buttonHeight === 'xs') return 'h-6 text-xs leading-4';
      return 'h-10 text-base leading-6';
    },
    colorClass() {
      if (this.disabled) return 'text-gray-2 border border-gray-2 bg-gray-4 cursor-not-allowed';
      if (this.colorType === 'none') return '';
      if (this.colorType === 'yellow') return 'ui-button__yellow text-gray';
      if (this.colorType === 'danger')
        return 'ui-button__danger bg-gray-4 border border-gray-2 text-red-1 hover:text-white hover:bg-red-2 hover:border-transparent active:text-white active:border-transparent active:bg-red-3';
      if (this.colorType === 'outline')
        return 'ui-button__outline bg-white border border-gray-2 hover:border-blue-hover active:border-blue-active text-gray-6 hover:text-blue-hover active:text-blue-active';
      if (this.colorType === 'primary-text')
        return 'ui-button__primary-text bg-transparent text-blue border border-blue hover:border-blue-hover hover:text-blue-hover active:border-blue-active active:text-blue-active';
      return 'bg-blue hover:bg-blue-hover active:bg-blue-active text-white';
    },
  },

  methods: {
    handleClick() {
      if (this.$props.disabled) return;
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.ui-button {
  &__outline {
    img {
      filter: invert(32%) sepia(16%) saturate(0%) hue-rotate(142deg) brightness(92%) contrast(87%);
    }
    &:hover img {
      filter: invert(56%) sepia(80%) saturate(1712%) hue-rotate(183deg) brightness(100%) contrast(106%);
    }
    &:active img {
      filter: invert(30%) sepia(49%) saturate(2729%) hue-rotate(197deg) brightness(95%) contrast(102%);
    }
  }
  &__primary-text {
    img {
      filter: invert(59%) sepia(99%) saturate(1803%) hue-rotate(135deg) brightness(98%) contrast(85%);
    }
    &:hover img {
      filter: invert(64%) sepia(59%) saturate(3904%) hue-rotate(185deg) brightness(103%) contrast(101%);
    }
    &:active img {
      filter: invert(33%) sepia(63%) saturate(5055%) hue-rotate(200deg) brightness(92%) contrast(93%);
    }
  }
  &__danger {
    img {
      filter: invert(51%) sepia(99%) saturate(7441%) hue-rotate(346deg) brightness(110%) contrast(104%);
    }
    &:hover img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(114deg) brightness(108%) contrast(101%);
    }
    &:active img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(114deg) brightness(108%) contrast(101%);
    }
  }

  &__yellow {
    background: #fadb14;

    &:hover {
      background: lighten(#fadb14, 10%);
    }

    &:active {
      background: darken(#fadb14, 10%);
    }
  }
}
</style>
