<template>
  <div style="background: rgba(0, 0, 0, 0.65)" class="fixed inset-0" @click="closeModal">
    <div class="h-full flex items-center justify-center">
      <div id="changeEmail" class="change-email__content bg-white rounded-4" @click.stop>
        <div class="py-4 px-6 flex items-center justify-between">
          <p class="font-semibold text-gray">Изменение электронной почты</p>
          <img
            width="18"
            height="18"
            src="@/assets/images/views/profile/cross.svg"
            class="cursor-pointer"
            @click="closeModal"
          />
        </div>
        <div class="h-px bg-gray-7"></div>
        <div class="py-4 px-6">
          <p class="text-sm leading-6 text-gray">Введите пароль для входа в аккаунт</p>
          <UiInput
            v-model="password"
            :input-type="isPasswordInput ? 'password' : 'text'"
            input-height="sm"
            placeholder="Пароль"
            class="mt-0.5"
          >
            <img
              width="18"
              height="18"
              src="@/assets/images/views/profile/password.svg"
              class="cursor-pointer"
              @click="isPasswordInput = !isPasswordInput"
            />
          </UiInput>
          <p class="text-sm leading-6 text-gray mt-4">Введите новый адрес электронной почты</p>
          <UiInput v-model="newEmail" input-height="sm" placeholder="Электронная почта" class="mt-0.5" />
        </div>
        <div class="h-px bg-gray-7"></div>
        <div class="px-6 py-4">
          <UiButton style="min-width: 93px" button-height="sm" class="px-4 w-max ml-auto" @click="changeEmail">
            <span v-if="!loading">Изменить</span>
            <LoadingSpinner v-else />
          </UiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateProfile } from '@/api';

import UiInput from '@/components/ui/UiInput.vue';
import UiButton from '@/components/ui/UiButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
  name: 'ChangeEmail',

  components: { UiInput, UiButton, LoadingSpinner },

  data() {
    return {
      password: '',
      newEmail: '',

      isPasswordInput: true,

      loading: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    async changeEmail() {
      this.loading = true;
      try {
        await updateProfile({
          current_password: this.password,
          email: this.newEmail,
          profile: this.$store.state.auth.userInfo?.profile,
        });
        await this.$store.dispatch('getUserInfo');
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Электронная почта успешно обновлена',
        });
        this.loading = false;
        this.$emit('close');
        this.newEmail = '';
        this.password = '';
      } catch (err) {
        console.error('ChangeEmail Error');
        this.$store.dispatch('setPopupDisplay', {
          display: true,
          text: 'Введён некорректный email',
          status: 'error',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.change-email {
  &__content {
    width: 365px;
    box-shadow: 0px 2px 8px 0px #00000026;
  }
}
</style>
