<template>
  <div>
    <div class="mb-4 flex items-center justify-between">
      <p class="text-2xl leading-8 text-gray font-semibold">Биллинг</p>
      <p class="text-xl leading-7 text-gray font-semibold">
        <span class="text-gray-3">Баланс: </span>
        <span>{{ userBalance | delimiter }} руб.</span>
      </p>
    </div>
    <component
      :is="currentComponent"
      :operation-history="operationHistory"
      :meta-data="metaData"
      :current-page="currentPage"
      :loading="loading"
      @open-create-bill="openCreateBill"
      @close-create-bill="closeCreateBill"
      @update-records="getPaymentsList"
      @update-statuses="handleUpdateStatuses"
      @change-page="handleChangePage"
    />
  </div>
</template>

<script>
import { getPaymentsHistory } from '@/api';
import CreateBill from '@/components/views/Profile/billing-list/CreateBill.vue';
import OperationHistory from '@/components/views/Profile/billing-list/OperationHistory.vue';

export default {
  name: 'BillingList',

  components: { CreateBill, OperationHistory },

  filters: {
    delimiter(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },

  data() {
    return {
      isCreateBillVisible: false,

      metaData: {},

      operationHistory: [],

      loading: false,

      currentPage: 1,
      recordsPerPage: 20,

      statuses: ['paid', 'not_paid'],
    };
  },

  computed: {
    currentComponent() {
      if (this.isCreateBillVisible) return 'CreateBill';
      return 'OperationHistory';
    },

    userBalance() {
      return this.$store.state.auth.balance;
    },
  },

  watch: {
    currentPage() {
      this.getPaymentsList();
    },
  },

  mounted() {
    this.getPaymentsList();
  },

  methods: {
    openCreateBill() {
      this.isCreateBillVisible = true;
    },

    closeCreateBill() {
      this.isCreateBillVisible = false;
    },

    handleChangePage(value) {
      if (this.loading) return;
      this.currentPage = value;
    },

    handleUpdateStatuses(value) {
      this.statuses = value;
      this.getPaymentsList();
    },

    async getPaymentsList() {
      try {
        this.loading = true;
        const res = await getPaymentsHistory({
          offset: (this.currentPage - 1) * this.recordsPerPage,
          statuses: this.statuses.length === 2 ? [] : this.statuses,
        });
        this.metaData = res.data.meta;
        this.operationHistory = res.data.objects;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
